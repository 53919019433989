import {backUrl} from "../utils/backURL";

export const sendPushNotification = async (
  requesterId,
  token,
  title,
  body,
  allUsers,
  tangelo,
  identification
) => {
  const req = await fetch(`${backUrl}/notifications/sendPushNotification`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      title,
      notificationBody: body,
      allUsers,
      identification,
      tangelo
    })
  });
  const res = await req.json();
  return res;
};
