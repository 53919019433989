import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { token } from "morgan";
import {
  getRecurringPayment,
  updateRecurringPayment,
  createNewPayment,
  updatePayment,
} from "../ApiCalls/backoffice";
import { uploadImage, uploadFile } from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

function RecurringPaymentDetail(props) {
  const [recurringPayment, setRecurringPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({});
  const [newPaymentFormState, setNewPaymentFormState] = useState({});
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [updating, setUpdating] = useState(false);
  const [creatingNewPayment, setCreatingNewPayment] = useState(false);
  const [uploadingVoucher, setUploadingVoucher] = useState(false);
  const [voucherFile, setVoucherFile] = useState(null);
  const [payments, setPayments] = useState([]);
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const [editingPayment, setEditingPayment] = useState(false);
  const [paymentToUpdateFormState, setPaymentToUpdateFormState] = useState({});

  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (recurringPayment) {
      setFormState({
        amount: recurringPayment.amount,
        cardToPayBankName: recurringPayment.cardToPayBankName,
        cardToPayNumber: recurringPayment.cardToPayNumber,
        creditBankName: recurringPayment.creditBankName,
        creditReferenceCode: recurringPayment.creditReferenceCode,
        frequency: recurringPayment.frequency,
        icon: recurringPayment.icon,
        name: recurringPayment.name,
        otherInfo: recurringPayment.otherInfo,
        status: recurringPayment.status,
        tpagaBillReference: recurringPayment.tpagaBillReference,
        tpagaProvider: recurringPayment.tpagaProvider,
        transferBankAccountNumber: recurringPayment.transferBankAccountNumber,
        transferBankName: recurringPayment.transferBankName,
        transferBankAccountType: recurringPayment.transferBankAccountType,
        transferReceiverFullName: recurringPayment.transferReceiverFullName,
        transferReceiverIdentificationNumber:
          recurringPayment.transferReceiverIdentificationNumber,
        transferReceiverIdentificationType:
          recurringPayment.transferReceiverIdentificationType,
        type: recurringPayment.type,
        paymentDay: recurringPayment.paymentDay,
      });
      setNewPaymentFormState({
        userId: recurringPayment.userId,
        type: recurringPayment.type,
        amount: recurringPayment.amount,
        status: "APPROVED",
        recurringPaymentId: recurringPayment._id,
        tpagaBillReference: recurringPayment.tpagaBillReference,
        integratedInvestmentId: recurringPayment.integratedInvestmentId,
        paymentMethod: recurringPayment.paymentMethod || "",
        recurringPayment,
        name: recurringPayment.name,
      });
    }
  }, [recurringPayment]);

  async function getData() {
    const paymentRes = await getRecurringPayment(props.userId, props.token, id);

    if (paymentRes.success) {
      setRecurringPayment(paymentRes.recurringPayment);
      setPayments(paymentRes.payments);
    }
    setLoading(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    /* if (name === "tpagaProvider") {
      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState.tpagaProvider,
          style: { ...prevState.tpagaProvider.style, title: value },
        },
      }));
    }  */
    //if (name === "amount" || name === "status" || name === "name") {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //}
  };

  const handleCreatePaymentInputChange = (event) => {
    const { name, value } = event.target;

    setNewPaymentFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditPaymentInputChange = (event) => {
    const { name, value } = event.target;

    setPaymentToUpdateFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setVoucherFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    setUpdating(true);
    event.preventDefault();
    const updatedObject = {
      ...formState,
    };
    const updateRes = await updateRecurringPayment(
      props.userId,
      props.token,
      recurringPayment._id,
      updatedObject
    );
    if (updateRes.success) {
      setAlertMessage("Pago recurrente actualizado correctamente");
    } else {
      setAlertMessage("Error al actualizar el pago recurrente");
    }
    setUpdating(false);
  };

  const handleUploadImage = async () => {
    if (voucherFile.size > 20000000) {
      alert("El tamaño máximo de la imágen es de 20mb");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {});

    reader.readAsDataURL(voucherFile);

    const formData = new FormData();
    formData.append("image", voucherFile);

    const thumbnailJson = await uploadImage(formData);

    if (thumbnailJson.imageUrl) {
      return thumbnailJson.imageUrl;
    } else {
      alert("Something went wrong: " + thumbnailJson.errors);
    }
  };
  const handleUploadPdf = async () => {
    if (voucherFile.size > 20000000) {
      alert("El tamaño máximo del archivo es de 20mb");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {});

    reader.readAsDataURL(voucherFile);

    const formData = new FormData();
    formData.append("file", voucherFile);

    const thumbnailJson = await uploadFile(formData);

    if (thumbnailJson.fileUrl) {
      return thumbnailJson.fileUrl;
    } else {
      alert("Something went wrong: " + thumbnailJson.errors);
    }
  };

  const handleCreateNewPayment = async (event) => {
    setCreatingNewPayment(true);
    event.preventDefault();
    let voucherUrl = "";
    if (voucherFile) {
      if (voucherFile.type === "application/pdf") {
        voucherUrl = await handleUploadPdf();
      } else {
        voucherUrl = await handleUploadImage();
      }
    }
    let newPaymentObject = {};
    if (voucherUrl) {
      newPaymentObject = {
        ...newPaymentFormState,
        voucher: voucherUrl,
      };
    } else {
      newPaymentObject = {
        ...newPaymentFormState,
      };
    }

    const createRes = await createNewPayment(
      props.userId,
      props.token,
      newPaymentObject
    );
    if (createRes.success) {
      let paymentsCopy = payments;
      paymentsCopy.push(createRes.payment);
      setPayments(paymentsCopy);
      setAlertMessage("Pago creado correctamente");
    } else {
      setAlertMessage("Error al crear el pago recurrente");
    }
    setShowNewPaymentModal(false);
    setCreatingNewPayment(false);
    setVoucherFile(null);
    setNewPaymentFormState({
      userId: recurringPayment.userId,
      type: recurringPayment.type,
      amount: recurringPayment.amount,
      status: "APPROVED",
      recurringPaymentId: recurringPayment._id,
      tpagaBillReference: recurringPayment.tpagaBillReference,
      integratedInvestmentId: recurringPayment.integratedInvestmentId,
    });
  };

  const handleUpdatePayment = async (event) => {
    setEditingPayment(true);
    event.preventDefault();
    let voucherUrl = "";
    if (voucherFile) {
      if (voucherFile.type === "application/pdf") {
        voucherUrl = await handleUploadPdf();
      } else {
        voucherUrl = await handleUploadImage();
      }
    }
    let paymentToUpdateObject = {};
    if (voucherUrl) {
      paymentToUpdateObject = {
        ...paymentToUpdateFormState,
        voucher: voucherUrl,
      };
    } else {
      paymentToUpdateObject = {
        ...paymentToUpdateFormState,
      };
    }
    if (
      paymentToUpdateObject.status === "APPROVED" &&
      !paymentToUpdateObject.datePaid
    ) {
      paymentToUpdateObject.datePaid = new Date();
    }
    if (paymentToUpdateFormState.status === "UNPAID") {
      alert(
        `Se generará un saldo a favor de $${formatMoney(
          paymentToUpdateFormState.amount
        )} al usuario`
      );
    }
    const updateRes = await updatePayment(
      props.userId,
      props.token,
      paymentToEdit._id,
      paymentToUpdateObject
    );
    if (updateRes.success) {
      let paymentsCopy = [];
      payments.map((element) => {
        if (element._id === updateRes.payment._id) {
          paymentsCopy.push(updateRes.payment);
        } else {
          paymentsCopy.push(element);
        }
      });
      setPayments(paymentsCopy);
      setAlertMessage("Transacción actualizada correctamente");
    } else {
      setAlertMessage("Error al editar la transacción");
    }
    setShowEditPaymentModal(false);
    setEditingPayment(false);
    setVoucherFile(null);
    setPaymentToUpdateFormState({});
  };

  return (
    <div>
      <h1>{formState.name || formState.type}</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs={10} style={{ margin: "auto" }}>
            {" "}
            <Form onSubmit={handleSubmit} style={{ margin: "auto" }}>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="amount">Monto:</Label>
                    <Input
                      type="text"
                      name="amount"
                      id="amount"
                      value={formState.amount}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="name">Nombre:</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={formState.name}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="status">Estado:</Label>
                    <Input
                      id="status"
                      name="status"
                      type="select"
                      value={formState.status}
                      onChange={handleInputChange}
                    >
                      <option value={"PENDING"}>Pendiente</option>
                      <option value={"ACTIVE"}>Activo</option>
                      <option value={"WAITING"}>Esperando</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="type">Tipo:</Label>
                    <Input
                      type="text"
                      name="type"
                      id="type"
                      value={formState.type}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="type">Día de pago:</Label>
                    <Input
                      type="number"
                      name="paymentDay"
                      id="paymentDay"
                      value={formState.paymentDay}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {formState.type === "Tarjeta de crédito" && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="cardToPayBankName">Banco:</Label>
                      <Input
                        type="text"
                        name="cardToPayBankName"
                        id="cardToPayBankName"
                        value={formState.cardToPayBankName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="cardToPayNumber">Número de la tarjeta:</Label>
                      <Input
                        type="text"
                        name="cardToPayNumber"
                        id="cardToPayNumber"
                        value={formState.cardToPayNumber}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {formState.type === "Transferencia" && (
                <Row>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="transferBankName">Banco:</Label>
                      <Input
                        type="text"
                        name="transferBankName"
                        id="transferBankName"
                        value={formState.transferBankName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="transferBankAccountNumber">
                        Número de la cuenta:
                      </Label>
                      <Input
                        type="text"
                        name="transferBankAccountNumber"
                        id="transferBankAccountNumber"
                        value={formState.transferBankAccountNumber}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="transferBankAccountType">
                        Tipo de cuenta:
                      </Label>
                      <Input
                        type="text"
                        name="transferBankAccountType"
                        id="transferBankAccountType"
                        value={formState.transferBankAccountType}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="transferReceiverFullName">
                        Nombre del recipiente:
                      </Label>
                      <Input
                        type="text"
                        name="transferReceiverFullName"
                        id="transferReceiverFullName"
                        value={formState.transferReceiverFullName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="transferReceiverIdentificationType">
                        Tipo de identificación del recipiente:
                      </Label>
                      <Input
                        type="text"
                        name="transferReceiverIdentificationType"
                        id="transferReceiverIdentificationType"
                        value={formState.transferReceiverIdentificationType}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="transferReceiverIdentificationNumber">
                        Número de identificación del recipiente:
                      </Label>
                      <Input
                        type="text"
                        name="transferReceiverIdentificationNumber"
                        id="transferReceiverIdentificationNumber"
                        value={formState.transferReceiverIdentificationNumber}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {formState.type === "Servicio" && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="tpagaBillReference">Código de factura:</Label>
                      <Input
                        type="text"
                        name="tpagaBillReference"
                        id="tpagaBillReference"
                        value={formState.tpagaBillReference}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="tpagaProvider">Proveedor:</Label>
                      <Input
                        type="text"
                        name="tpagaProvider"
                        id="tpagaProvider"
                        value={formState.tpagaProvider.style.title}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {formState.type === "Crédito" && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="creditBankName">Banco:</Label>
                      <Input
                        type="text"
                        name="creditBankName"
                        id="creditBankName"
                        value={formState.creditBankName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="creditReferenceCode">
                        Código de referencia:
                      </Label>
                      <Input
                        type="text"
                        name="creditReferenceCode"
                        id="creditReferenceCode"
                        value={formState.creditReferenceCode}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {formState.type === "Otro" && (
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="otherInfo">Detalle:</Label>
                      <Input
                        type="text"
                        name="otherInfo"
                        id="otherInfo"
                        value={formState.otherInfo}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={6} style={{ margin: "auto" }}>
                  <Button color="primary" type="submit">
                    {updating ? <Spinner size={"sm"} /> : "Actualizar"}
                  </Button>
                </Col>
                <Col xs={6} style={{ margin: "auto" }}>
                  <Button
                    color="primary"
                    onClick={() => setShowNewPaymentModal(true)}
                  >
                    Generar Pago
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {alertMessage && (
        <Row>
          <Col xs={6} style={{ margin: "auto", marginTop: 50 }}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
      {payments && (
        <Row style={{ marginTop: 20 }}>
          <Col xs={10} style={{ margin: "auto" }}>
            <h2>Transacciones</h2>
          </Col>
          <Col xs={10} style={{ margin: "auto", marginTop: 20 }}>
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th className="f-22">#</th>
                      <th>Fecha</th>
                      <th>Monto</th>
                      <th>Estado</th>
                      <th>Comprobante</th>
                      <th>Referencia Externa</th>
                      <th>Referencia Mantis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {payment.datePaid
                              ? new Date(payment.datePaid).toLocaleDateString(
                                  "es"
                                )
                              : "-"}
                          </td>
                          <td>${formatMoney(payment.amount)}</td>
                          <td>{payment.status}</td>
                          <td>
                            {payment.voucher ? (
                              payment.voucher.includes("http") ? (
                                <a href={payment.voucher} target="_blank">
                                  Ver
                                </a>
                              ) : (
                                <a
                                  download="voucherMantis.pdf"
                                  href={`data:file/pdf;base64,${payment.voucher}`}
                                >
                                  Ver
                                </a>
                              )
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>{payment.externalReference || "-"}</td>
                          <td>{payment._id}</td>
                          <td
                            onClick={() => {
                              setPaymentToEdit(payment);
                              setPaymentToUpdateFormState({
                                amount: payment.amount,
                                status: payment.status,
                                externalReference: payment.externalReference,
                                datePaid: payment.datePaid,
                                generateVoucher: false,
                                userId: payment.userId,
                              });
                              setShowEditPaymentModal(true);
                            }}
                          >
                            <i
                              className="fas fa-edit"
                              style={{ fontSize: "1em", cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        isOpen={showNewPaymentModal}
        toggle={() => {
          if (!creatingNewPayment) {
            setShowNewPaymentModal(!showNewPaymentModal);
            setVoucherFile(null);
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            if (!creatingNewPayment) {
              setShowNewPaymentModal(!showNewPaymentModal);
              setVoucherFile(null);
            }
          }}
        >
          Generar pago
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleCreateNewPayment} style={{ margin: "auto" }}>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="amount">Monto:</Label>
                  <Input
                    type="text"
                    name="amount"
                    id="amount"
                    value={newPaymentFormState.amount}
                    onChange={handleCreatePaymentInputChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="status">Estado:</Label>
                  <Input
                    id="status"
                    name="status"
                    type="select"
                    value={newPaymentFormState.status}
                    onChange={handleCreatePaymentInputChange}
                  >
                    <option value={"PROGRAMMED"}>Programado</option>
                    <option value={"WAITING"}>Esperando</option>
                    <option value={"PENDING"}>Pendiente</option>
                    <option value={"APPROVED"}>Aprobado</option>
                    <option value={"DECLINED"}>Declinado</option>
                    <option value={"ERROR"}>Error</option>
                    <option value={"REVERSED"}>Reversado</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="exampleFile">Subir comprobante</Label>
                  <Input
                    id="exampleFile"
                    name="file"
                    type="file"
                    onChange={handleFileChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} style={{ margin: "auto" }}>
                <Button color="primary" type="submit">
                  {creatingNewPayment ? (
                    <Spinner size={"sm"} />
                  ) : (
                    "Generar pago"
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showEditPaymentModal}
        toggle={() => {
          if (!editingPayment) {
            setShowEditPaymentModal(!showEditPaymentModal);
            setPaymentToEdit(null);
            setVoucherFile(null);
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            if (!editingPayment) {
              setShowEditPaymentModal(!showEditPaymentModal);
              setPaymentToEdit(null);
              setVoucherFile(null);
            }
          }}
        >
          Editar pago
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleUpdatePayment} style={{ margin: "auto" }}>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="amount">Monto:</Label>
                  <Input
                    type="text"
                    name="amount"
                    id="amount"
                    value={paymentToUpdateFormState.amount}
                    onChange={handleEditPaymentInputChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="status">Estado:</Label>
                  <Input
                    id="status"
                    name="status"
                    type="select"
                    value={paymentToUpdateFormState.status}
                    onChange={handleEditPaymentInputChange}
                  >
                    <option value={"PROGRAMMED"}>Programado</option>
                    <option value={"WAITING"}>Esperando</option>
                    <option value={"PENDING"}>Pendiente</option>
                    <option value={"APPROVED"}>Aprobado</option>
                    <option value={"DECLINED"}>Declinado</option>
                    <option value={"ERROR"}>Error</option>
                    <option value={"REVERSED"}>Reversado</option>
                    <option value={"UNPAID"}>No Pago</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="externalReference">Referencia externa:</Label>
                  <Input
                    type="text"
                    name="externalReference"
                    id="externalReference"
                    value={paymentToUpdateFormState.externalReference}
                    onChange={handleEditPaymentInputChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="generateVoucher"
                      type="checkbox"
                      onChange={() =>
                        setPaymentToUpdateFormState((prevState) => ({
                          ...prevState,
                          generateVoucher: !prevState.generateVoucher,
                        }))
                      }
                    />{" "}
                    Generar comprobante automáticamente
                  </Label>
                </FormGroup>
              </Col>
              {!paymentToUpdateFormState.generateVoucher && (
                <Col xs={12}>
                  <FormGroup>
                    <Label for="exampleFile">Subir comprobante</Label>
                    <Input
                      id="exampleFile"
                      name="file"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12} style={{ margin: "auto" }}>
                <Button color="primary" type="submit">
                  {editingPayment ? <Spinner size={"sm"} /> : "Editar pago"}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default connect(select)(RecurringPaymentDetail);
