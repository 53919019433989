import {backUrl} from "../utils/backURL";

export const sendWaitlistMessage = async (
  requesterId,
  token,
  cellphone,
  name
) => {
  const req = await fetch(
    `${backUrl}/notifications/whatsapp/waitlistTemplate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        cellphone,
        name
      })
    }
  );
  const res = await req.json();
  return res;
};

export const sendNonParametersTemplateMessage = async (
  requesterId,
  token,
  cellphone,
  sendToEveryone,
  templateName,
  templateLanguage
) => {
  const req = await fetch(
    `${backUrl}/notifications/whatsapp/nonParametersTemplate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        cellphone,
        sendToEveryone,
        templateName,
        templateLanguage
      })
    }
  );
  const res = await req.json();
  return res;
};
