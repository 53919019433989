import {backUrl} from "../utils/backURL";

export const getEmployees = async (requesterId, token) => {
  const req = await fetch(`${backUrl}/mantisBusiness/getEmployees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      activeEmployees: false
    })
  });
  const res = await req.json();
  return res;
};

export const getActiveEmployees = async (requesterId, token) => {
  const req = await fetch(`${backUrl}/mantisBusiness/getEmployees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      activeEmployees: true
    })
  });
  const res = await req.json();
  return res;
};

export const createEmployee = async (requesterId, token, employeeData) => {
  const req = await fetch(`${backUrl}/mantisBusiness/createEmployee/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      employeeData
    })
  });
  const res = await req.json();
  return res;
};

export const getEmployeeFolders = async (userId, mantisBusinessId, token) => {
  const req = await fetch(`${backUrl}/mantisBusiness/getEmployeeFolders/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      userId,
      mantisBusinessId
    })
  });
  const res = await req.json();
  return res;
};

export const getEmployeeFolderById = async (
  folderId,
  mantisBusinessId,
  token
) => {
  const req = await fetch(`${backUrl}/mantisBusiness/getEmployeeFolderById/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      folderId,
      mantisBusinessId
    })
  });
  const res = await req.json();
  return res;
};

export const createEmployeeFolder = async (
  parentFolderId,
  folderData,
  mantisBusinessId,
  userId,
  token
) => {
  const req = await fetch(`${backUrl}/mantisBusiness/createEmployeeFolder/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      parentFolderId,
      folderData,
      mantisBusinessId,
      userId
    })
  });
  const res = await req.json();
  return res;
};

export const createEmployeeDocument = async (
  folderId,
  base64,
  name,
  type,
  mantisBusinessId,
  userId,
  token
) => {
  const req = await fetch(`${backUrl}/mantisBusiness/createEmployeeDocument/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      folderId,
      base64,
      name,
      type,
      mantisBusinessId,
      userId
    })
  });
  const res = await req.json();
  return res;
};

export const getMantisBusinessNotificationNumber = async (
  mantisBusinessId,
  token
) => {
  const req = await fetch(
    `${backUrl}/notifications/getMantisBusinessUnreadNotificationsAmount`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({mantisBusinessId})
    }
  );
  const res = await req.json();
  return res;
};

export const getMantisBusinessNotifications = async (
  mantisBusinessId,
  token
) => {
  const req = await fetch(
    `${backUrl}/notifications/getMantisBusinessNotifications`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({mantisBusinessId})
    }
  );
  const res = await req.json();
  return res;
};

export const sendCellphoneVerification = async (cellphone) => {
  const req = await fetch(
    `${backUrl}/verify/sendBusinessCellphoneVerification`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({cellphone})
    }
  );
  const res = await req.json();
  return res;
};

export const verifyCellphone = async (cellphone, code) => {
  const req = await fetch(`${backUrl}/verify/verifyMantisBusinessCellphone`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({cellphone, code})
  });
  const res = await req.json();
  return res;
};

export const getBusinessPermissions = async (
  requesterId,
  mantisBusinessId,
  token
) => {
  const req = await fetch(`${backUrl}/mantisBusiness/getBusinessPermissions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      mantisBusinessId
    })
  });
  const res = await req.json();
  return res;
};

export const createMantisBusinessUser = async (
  mantisBusinessId,
  mantisBusinessUserId,
  token,
  userData
) => {
  const req = await fetch(
    `${backUrl}/mantisBusiness/createMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisBusinessUserId,
        userData
      })
    }
  );
  const res = await req.json();
  return res;
};

export const getMantisBusinessUsers = async (mantisBusinessId, token) => {
  const req = await fetch(`${backUrl}/mantisBusiness/getMantisBusinessUsers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      mantisBusinessId
    })
  });
  const res = await req.json();
  return res;
};

export const deleteMantisBusinessUser = async (
  mantisBusinessUserToDeleteId,
  mantisBusinessUserId,
  token
) => {
  const req = await fetch(
    `${backUrl}/mantisBusiness/deleteMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        mantisBusinessUserToDeleteId,
        mantisBusinessUserId
      })
    }
  );
  const res = await req.json();
  return res;
};

export const updateMantisBusinessUser = async (
  mantisBusinessUserId,
  mantisBusinessUserToUpdateId,
  userData,
  token
) => {
  const req = await fetch(
    `${backUrl}/mantisBusiness/updateMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        mantisBusinessUserId,
        mantisBusinessUserToUpdateId,
        userData
      })
    }
  );
  const res = await req.json();
  return res;
};

export const sendRecoverPasswordEmail = async (email) => {
  const req = await fetch(`${backUrl}/passwordReset/business`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({email})
  });
  const res = await req.json();
  return res;
};

export const resetPassword = async (token, newPassword) => {
  const resetPasswordReq = await fetch(
    `${backUrl}/passwordReset/business/changePassword`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token,
        newPassword
      })
    }
  );
  const response = await resetPasswordReq.json();
  return response;
};

export const getLatestAssistantConversation = async (cellPhone, token) => {
  const req = await fetch(
    `${backUrl}/mantisBusiness/getLatestAssistantConversation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({cellPhone})
    }
  );
  const res = await req.json();
  return res;
};

export const postAssistantMessage = async (
  cellPhone,
  message,
  token,
  mantisBusinessUserId
) => {
  const req = await fetch(`${backUrl}/mantisBusiness/postAssistantMessage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({cellPhone, message, mantisBusinessUserId})
  });
  const res = await req.json();
  return res;
};

export const getVisionResponse = async function (
  files,
  prompt,
  token,
  mantisBusinessId,
  mantisBusinessUserId,
  mantisMissionId,
  bulk,
  missionName,
  missionDescription
) {
  const req = await fetch(`${backUrl}/mantisBusiness/getVisionResponse`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      files,
      prompt,
      mantisBusinessId,
      mantisBusinessUserId,
      mantisMissionId,
      bulk,
      missionName,
      missionDescription
    })
  });
  const res = await req.json();
  return res;
};

export const getMantisMissions = async (mantisBusinessId, token) => {
  const req = await fetch(
    `${backUrl}/mantisMissions/getMantisMissionsByBusinessId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({mantisBusinessId})
    }
  );
  const res = await req.json();
  return res;
};

export const exportMantisMissions = async (mantisMissionId, token) => {
  const req = await fetch(
    `${backUrl}/mantisMissions/exportMantisMissionsByBusinessId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        mantisMissionId
      })
    }
  );
  const res = await req.json();
  return res;
};

export const getMantisMissionResults = async (mantisMissionId, token) => {
  const req = await fetch(`${backUrl}/mantisMissions/getMantisMissionResults`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({mantisMissionId})
  });
  const res = await req.json();
  return res;
};

export const saveMantisMission = async (
  mantisBusinessId,
  mantisMission,
  mantisBusinessUserId,
  token
) => {
  const req = await fetch(`${backUrl}/mantisMissions/saveMantisMission`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      mantisBusinessId,
      mantisMission,
      mantisBusinessUserId
    })
  });
  const res = await req.json();
  return res;
};
