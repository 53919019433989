import React from "react";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import "./styles/termsConditions.scss";

export default function TermsAndConditions() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div
      style={{ marginTop: 30, width: isMobile ? "85%" : "50%", margin: "auto" }}
    >
      <img
        src={mantisLogo}
        alt="Mantis Logo"
        height={isMobile ? 40 : 70}
        style={{ marginTop: 20 }}
      />
      <br />
      <br />
      <h1 className="doc-title">
        TÉRMINOS Y CONDICIONES DEL SERVICIO MANTIS APP S.A.S.
      </h1>
      <p>
        Este acuerdo establece los términos y condiciones ("Términos") bajo los
        cuales Mantis App S.A.S. ("Mantis", "nosotros") proporciona servicios a
        usted ("Cliente", "usted"). Al aceptar utilizar los servicios de Mantis,
        usted acepta estar vinculado por estos Términos.
      </p>
      <h2 className="subtitle">NATURALEZA DE LOS SERVICIOS</h2>
      <p>
        Mantis proporciona servicios de asesoramiento personalizado
        ("Servicios") a través de la aplicación móvil de Mantis, mas no se trata
        de un servicio financiero. Los Servicios son realizados por Asesores
        vinculados a Mantis (“Asesores”), quienes presentarán sugerencias o
        recomendaciones de organización financiera para los propósitos
        manifestados por el Cliente, con base en la información suministrada por
        el Cliente, basados en su experiencia o conocimiento.
      </p>
      <ol type="a">
        <li>
          <p>
            La asesoría dada por los Asesores al Cliente, y que se presentará a
            través de un plan de acción (en adelante el “Plan de Acción”) no
            garantiza resultados, ni es una fórmula de éxito, y debe ser
            considerada como una mera sugerencia. El Cliente es responsable de
            todas las decisiones y acciones que tome basado en la asesoría, y
            decide asumir el riesgo en la ejecución del Plan de Acción.
          </p>
        </li>
        <li>
          <p>
            Mantis no presta servicios financieros ni directos ni indirectos.
            Mantis actúa como coordinador de la ejecución de las recomendaciones
            dadas por los mismos a través del Plan de Acción. Algunos de los
            productos o servicios presentados por Mantis podrán tener
            condiciones más favorables que las usuales de mercado en atención a
            los convenios que tenga suscrito Mantis con aliados. Sin embargo,
            los Servicios específicos y las transacciones financieras serán
            realizadas por terceros y están sujetos a los términos y condiciones
            de dichos terceros, aún si Mantis hubiera convenido con ellas.
            Cualquier reclamo o queja dirigida al tercero debe ser resuelta
            directamente con el tercero. Mantis no asume responsabilidad por los
            términos y condiciones de los terceros.
          </p>
        </li>
        <li>
          <p>
            Los Servicios se facturan por hora a la tarifa establecida por
            Mantis, los cuales son conocidos por el Cliente antes del inicio de
            los Servicios.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">ALCANCE DE LOS SERVICIOS</h2>
      <ol type="a">
        <li>
          <p>
            El Cliente se compromete a diligenciar de manera completa, detallada
            y oportuna la información financiera requerida por Mantis en su
            aplicación para atender la cita agendada para la sesión
            personalizada
          </p>
        </li>
        <li>
          <p>
            El Plan de Ejecución presentado por Mantis será enviado tras el
            agendamiento de la cita inicial para los Servicios y ello está
            cubierto con la tarifa pagada por el Cliente al momento de
            realizarse el agendamiento de la sesión personalizada. Cualquier
            nuevo agendamiento de la cita, debido a la imprecisión, falta de
            información, o cualquier otro evento que no le permita al asesor
            poder presentar en la cita inicial el Plan de Acción al Cliente,
            tendrá un sobrecosto, pues el Cliente entiende y acepta que es su
            obligación suministrar información completa, detallada, y
            oportunidad para poder llevar a cabo la cita inicial con el Asesor.
          </p>
        </li>
        <li>
          <p>
            De no pagarse la tarifa adicional por un nuevo agendamiento de cita
            con el Asesor, y si éste en consecuencia no puede presentar el Plan
            de Acción, no será reembolsable ni el Cliente entenderá que no hubo
            prestación del servicio.
          </p>
        </li>
        <li>
          <p>
            La cancelación de la cita inicial o de la nueva que se hubiera
            agendado con el Asesor causará el pago de una multa para que pueda
            operar su re-agendamiento, La inasistencia a la cita inicial o a la
            re-programada no generará ningún tipo de devolución de dinero que
            hubiera sido pagado por el Cliente y se deberá programar una nueva,
            la cual deberá ser pagada a las tarifas vigentes en Mantis.
          </p>
        </li>
        <li>
          <p>
            Se entenderá que no existe inasistencia a la cita inicial o
            reprogramada, si la misma es cancelada con al menos 2 horas de
            anticipación a la hora de la cita ya agendada a través de la
            aplicación, so pena de entenderse como inasistencia si la misma no
            se realiza con dicha anticipación.
          </p>
        </li>
        <li>
          <p>
            Cualquier cancelación o solicitud de programación o reprogramación
            de citas deberá realizarse a través de la aplicación de Mantis o por
            whatsapp al número de teléfono 3154308744. No serán tomados en
            cuenta correos electrónicos, llamadas telefónicas, o comunicaciones
            verbales o escritas que no se hagan a través de los medios indicados
            anteriormente.
          </p>
        </li>
        <li>
          <p>
            Los Servicios serán prestados de manera virtual, y no habrá
            obligación ni del Asesor ni de Mantis de agendar citas de manera
            presencial.
          </p>
        </li>
        <li>
          <p>
            El Cliente declara que cuenta con la tecnología suficiente para
            atender los encuentros con Mantis y/o con el Asesor de manera
            virtual.
          </p>
        </li>
        <li>
          <p>
            El tiempo de espera para el inicio de cualquier cita con el Asesor
            será de 10 minutos, tiempo en el cual, si el Cliente no ha
            comparecido dará lugar a que se entienda como inasistencia, y no
            habrá obligación de Mantis ni del Asesor a establecer una conexión
            en el tiempo restante del tiempo de asesoría. Si por el contrario el
            Cliente realiza la conexión dentro de los 10 minutos posteriores al
            inicio de la hora para la cita de la asesoría, el Asesor presentará
            el Plan de Acción al cliente en el tiempo restante que le quede a la
            cita agendada, sin que se entienda que por este hecho existe una
            asesoría incompleta o que deba haber reembolso de tarifas a favor
            del Cliente.
          </p>
        </li>
        <li>
          <p>
            Presentado el Plan de Acción por parte del Asesor al Cliente, será
            decisión del Cliente continuar con los Servicios, para que sea
            Mantis quien ponga en ejecución el Plan de Acción, entendiendo que
            el Cliente acepta lo anterior en el momento en que desea continuar
            con los Servicios. Para esta prestación de Servicios, el Cliente
            pagará las tarifas que para tal efecto disponga Mantis.
          </p>
        </li>
        <li>
          <p>
            Una vez presentado el Plan de Acción por el Asesor, y el Cliente
            continue con los Servicios, suscribirá, conferirá y dará acceso a
            Mantis aquellos documentos que éste requiera para ejecutar el Plan
            de Acción. La falta de suscripción o envío de alguno de ellos, se
            entenderá como una negativa a continuar con los Servicios de Mantis.
          </p>
        </li>
        <li>
          <p>
            El Cliente garantiza que mantendrá los recursos necesarios
            disponibles para Mantis para que éste pueda realizar los pagos ante
            entidades financieras o terceros prestadores de servicios,
            incluyendo la suma que por honorarios deba pagársele a Mantis por
            los Servicios que presta.
          </p>
        </li>
        <li>
          <p>
            Mantis realizará los pagos o giros a terceros o entidades
            financieras de manera oportuna, y en caso de la ocurrencia de algún
            error atribuible directamente al actuar de Mantis, sin que confluya
            un caso fortuito o fuerza mayor, que no permita dicho pago, hará que
            Mantis asuma las penalidades o sanciones que de manera directa por
            este hecho el Cliente deba pagar, lo cual no incluirá el
            reconocimiento de daños indirectos, consecuenciales o lucro cesante.
          </p>
        </li>
        <li>
          <p>
            En la aplicación de Mantis se dispondrá de un resumen de las
            acciones que Mantis realizará para el Cliente, cuyo tiempo de
            actualización podrá no ser en tiempo real. Por lo anterior, el
            Cliente acepta que la actualización de su información podrá no
            visualizarse en tiempo real, y que Mantis no es ni se hace
            responsable por el tiempo de actualización de los balances o de
            cualquier movimiento realizado por un tercero.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">CONFIDENCIALIDAD</h2>
      <ol type="a">
        <li>
          <p>
            Mantis se compromete a mantener la confidencialidad de toda la
            información proporcionada por el Cliente en el curso de utilizar los
            Servicios. Dicha información no será revelada a terceros, a menos
            que sea necesario para la prestación de los Servicios o esté
            requerido por la ley.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">PAGOS Y COMISIONES</h2>
      <ol type="a">
        <li>
          <p>
            El pago por la presentación del Plan de Acción se realizará en el
            primer pago realizado por el Cliente, y por los Servicios tras la
            ejecución del Plan de Acción serán pagados por el Cliente a Mantis
            contra cada operación que realice Mantis, y el Cliente autoriza a
            Mantis a que los descuente directamente de las sumas de dinero que
            el Cliente haya puesto a disposición para los pagos que realiza
            Mantis a terceros o entidades financieras. No se efectuarán
            reembolsos una vez que se haya realizado el pago.
          </p>
        </li>
        <li>
          <p>
            Mantis recibe una comisión de sus aliados financieros y Fintech por
            cada producto o utilización que resulte de su recomendación, y la
            misma será única y exclusivamente de Mantis y no se entenderá ni se
            imputará al valor que por los Servicios deba pagar el Cliente a
            Mantis.
          </p>
        </li>
        <li>
          <p>
            Las tarifas a pagar por el Cliente a Mantis por los Servicios que se
            deriven de la ejecución del Plan de Acción serán aquellas vigentes
            por Mantis al momento de realizar la gestión.
          </p>
        </li>
        <li>
          <p>
            Mantis proporciona un seguro de pago como una comodidad para ciertos
            usuarios aprobados previamente por Mantis ("Usuarios Aprobados").
            Bajo este seguro, Mantis puede cubrir el costo de un servicio o
            transacción, junto con cualquier multa asociada, en caso de que el
            Usuario Aprobado no pueda hacerlo, siempre y cuando el no pago sea
            responsabilidad de Mantis.
          </p>
          <p>
            Es importante notar que este seguro de pago no es una garantía de
            fondos indefinidos. Mantis se reserva el derecho a realizar el cobro
            de la suma prestada, junto con el monto correspondiente al mes
            anterior, en un ciclo de cobro establecido.
          </p>
          <p>
            Las condiciones del seguro de pago, incluyendo, pero no limitado a,
            la tasa de interés, las condiciones de cobro y préstamo, dependen
            del perfil financiero del Usuario Aprobado y están sujetas a cambio
            a discreción de Mantis. La disponibilidad de este seguro de pago
            está limitada a los Usuarios Aprobados.
          </p>
          <p>
            Por favor tenga en cuenta que este seguro de pago no es un servicio
            de préstamo tradicional y está sujeto a términos y condiciones
            específicos. Para obtener más información sobre el seguro de pago, y
            para ver si usted es elegible, por favor consulte la aplicación
            Mantis.
          </p>
          <p>
            El uso de este seguro de pago implica la aceptación de los términos
            y condiciones de Mantis y del seguro de pago. Cualquier abuso del
            seguro de pago puede resultar en la cancelación del seguro y la
            suspensión o terminación de los servicios de Mantis.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">CANCELACIÓN Y REEMBOLSO</h2>
      <ol type="a">
        <li>
          <p>
            Si el Cliente termina de manera anticipada la prestación de los
            Servicios, esto es antes que concluya la ejecución del Plan de
            Acción por Mantis, y si los pagos aún no se hubieran efectuado a los
            terceros o entidades financieras, el Cliente podrá cancelar los
            Servicios sin penalización. Sin embargo, una vez que el pago ha sido
            realizado al tercero o entidad financiera, no habrá reembolsos al
            Cliente, más los Servicios de Mantis no continuará para futuros
            pagos a realizar.
          </p>
        </li>
        <li>
          <p>
            En caso de interrupción o discontinuación de los Servicios por
            razones fuera del control de Mantis, tales como eventos de fuerza
            mayor, fallas tecnológicas, o cualquier otro evento inesperado,
            Mantis no será responsable y no se ofrecerán reembolsos.
          </p>
        </li>
        <li>
          <p>
            En reconocimiento a la confianza y lealtad de nuestros clientes,
            Mantis ha establecido un Programa de Lealtad ("El Programa"). En el
            marco de El Programa, los clientes pueden tener derecho a recibir
            cupones en caso de que se produzcan fallas en el servicio por parte
            de los terceros. Es importante aclarar que estos cupones no son
            reembolsables ni canjeables por dinero en efectivo.
          </p>
          <p>
            En caso de que un tercero incumpla con los términos y condiciones de
            su servicio, y este incumplimiento sea confirmado por Mantis a su
            sola discreción, se activará la entrega de cupones a los clientes
            afectados. Estos cupones podrán ser utilizados para la adquisición
            de productos o servicios en la plataforma Mantis, sujeto a los
            términos y condiciones del Programa.
          </p>
          <p>
            Los detalles específicos sobre la cantidad de cupones, las
            condiciones de su uso y otros aspectos pertinentes serán
            determinados por Mantis y comunicados a los clientes en el marco del
            Programa. Los términos y condiciones de los cupones y del Programa
            pueden ser modificados por Mantis en cualquier momento y a su entera
            discreción. Mantis notificará a los clientes de cualquier cambio en
            estos términos y condiciones con la debida antelación.
          </p>
          <p>
            Mantis no asume ninguna responsabilidad por los incumplimientos de
            los terceros, y la entrega de cupones en el marco del Programa no
            constituye una admisión de responsabilidad por parte de Mantis.
            Cualquier reclamo o queja dirigida al tercero debe ser resuelta
            directamente con el tercero.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">LIMITACIÓN DE RESPONSABILIDAD</h2>
      <ol type="a">
        <li>
          <p>
            Mantis no será responsable de cualquier pérdida o daño que pueda
            surgir de la interpretación o uso de la asesoría proporcionada. El
            Cliente reconoce que el uso de la asesoría es bajo su propio riesgo.
          </p>
        </li>
        <li>
          <p>
            Mantis no será responsable de ningún daño indirecto, incidental,
            especial, punitivo, o consecuencial, incluyendo, pero no limitado a,
            pérdida de beneficios o ingresos, que resulte del uso de los
            Servicios.
          </p>
        </li>
        <li>
          <p>
            Mantis no será responsable por cualquier pérdida o daño que surja de
            la interrupción, cancelación o cambio en los Servicios por motivos
            de fuerza mayor, incluyendo pero no limitado a, desastres naturales,
            guerra, huelga, disturbios, actos gubernamentales, interrupciones de
            internet, problemas técnicos, y otros eventos imprevistos.
          </p>
        </li>
        <li>
          <p>
            Las demoras y tiempos que tomen las entidades o terceros en la
            resolución o ejecución de las actuaciones o servicios que se le
            soliciten no podrán ser atribuidas a Mantis.{" "}
          </p>
        </li>
      </ol>
      <h2 className="subtitle">INDEMNIZACIÓN</h2>
      <ol type="a">
        <li>
          <p>
            El Cliente acepta indemnizar y mantener indemne a Mantis, sus
            directores, empleados y agentes, de y contra cualquier reclamo,
            demanda, pérdida, daño, costo o responsabilidad (incluyendo
            honorarios razonables de abogados) que surja de: (i) su uso y acceso
            a los Servicios; (ii) su violación de cualquier término de este
            Acuerdo; (iii) su violación de cualquier derecho de un tercero,
            incluyendo sin limitación cualquier derecho de autor, propiedad, o
            derecho de privacidad; o (iv) cualquier reclamo de que uno de sus
            actos u omisiones ha causado daño a un tercero.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">CAMBIOS EN LOS TÉRMINOS Y CONDICIONES</h2>
      <ol type="a">
        <li>
          <p>
            Mantis se reserva el derecho de modificar estos Términos en
            cualquier momento. Si los Términos se modifican, Mantis informará a
            los Clientes a través de la aplicación o por correo electrónico, y
            de no haber una cancelación de los Servicios dentro de los tres (3)
            días siguientes a su publicación, los mismos se entenderán aceptados
            por el Cliente.
          </p>
          <p>
            Al usar los Servicios de Mantis, usted confirma que ha leído,
            comprendido y aceptado estos Términos. Los Términos se regirán e
            interpretarán de acuerdo con las leyes de Colombia.
          </p>
          <p>
            Cualquier disputa, controversia o reclamación que surja de o en
            relación con estos Términos será resuelta mediante la jurisdicción
            competente de Colombia.
          </p>
        </li>
      </ol>
    </div>
  );
}
