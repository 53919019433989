import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { token } from "morgan";
import { getInvestments } from "../ApiCalls/backoffice";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

function Investments(props) {
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const investmentsRes = await getInvestments(props.userId, props.token);

    if (investmentsRes.success) {
      setInvestments(investmentsRes.investments);
    }
    setLoading(false);
  }

  return (
    <>
      <h1>Inversiones</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {investments.length > 0 && (
            <Row>
              <Col xs={10} style={{ margin: "auto" }}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Usuario</th>
                          <th>Plataforma</th>
                          <th>Balance actual</th>
                          <th>Ganancia actual</th>
                          <th>Moneda inversión</th>
                          <th>Monto ingresado</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {investments.map((investment, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/backoffice/investment/${investment._id}`
                                )
                              }
                            >
                              <td>{index + 1}</td>
                              <td>
                                {investment.user.firstName}{" "}
                                {investment.user.lastName}
                              </td>
                              <td>{investment.partner.name}</td>
                              <td>${formatMoney(investment.currentBalance)}</td>
                              <td>${formatMoney(investment.currentProfit)}</td>
                              <td>{investment.investmentCurrency}</td>
                              <td>
                                ${formatMoney(investment.currentInputBalance)}
                              </td>
                              <td>{investment.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {investments.length === 0 && <h2>No hay inversiones!</h2>}
        </>
      )}
    </>
  );
}

export default connect(select)(Investments);
