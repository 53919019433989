import React, {useState, useEffect} from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {token} from "morgan";
import {getBillingData} from "../ApiCalls/backoffice";
import {getMonthAbreviation, getMonthDiff} from "../utils/dateUtils";
import {uploadFile, uploadImage} from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function Billing(props) {
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [billingDataByUser, setBillingDataByUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionsByMonth, setActionsByMonth] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const billingDataRequest = await getBillingData(props.token, props.userId);
    if (billingDataRequest.success) {
      setBillingDataByUser(billingDataRequest.billingData.userActions);
      setActionsByMonth(billingDataRequest.billingData.actionsByMonth);
    } else {
      setAlertMessage("No fue posible obtener los datos de facturación");
    }
    setLoading(false);
  }

  return (
    <div style={{paddingTop: 20, paddingBottom: 20}}>
      <h1>Facturación</h1>
      <br />
      {alertMessage && (
        <Row>
          <Col xs={6} style={{margin: "auto", marginTop: 50}}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th className="f-22">#</th>
                        <th>Nombre</th>
                        <th>Monto en mandatos</th>
                        <th>Pagos Mantis</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingDataByUser.map((user, index) => {
                        let totalMandatesAmount = user.moneyOnMandates.reduce(
                          (acc, cur) => {
                            return acc + cur.total;
                          },
                          0
                        );
                        let totalMantisAmount = user.mantisPayments.reduce(
                          (acc, cur) => {
                            return acc + cur.total;
                          },
                          0
                        );
                        if (
                          totalMandatesAmount === 0 &&
                          totalMantisAmount === 0
                        ) {
                          return null;
                        }
                        return (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedUser(user);
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                            <td>${formatMoney(totalMandatesAmount)}</td>
                            <td>${formatMoney(totalMantisAmount)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Acciones por mes</CardTitle>
                </CardHeader>
                <CardBody>
                  {Object.keys(actionsByMonth).length > 0 && (
                    <>
                      {Object.keys(actionsByMonth)
                        .reverse()
                        .map((month, index) => {
                          let monthNumber = parseInt(month);
                          let monthName = getMonthAbreviation(monthNumber);
                          let users = actionsByMonth[month];
                          return (
                            <Row key={index}>
                              <Col xs={12}>
                                <Table>
                                  <thead>
                                    <tr>
                                      <th className="f-22">{monthName}</th>
                                      <th>Nombre</th>
                                      <th>Tipo ID</th>
                                      <th>ID</th>
                                      <th>Celular</th>
                                      <th>Correo</th>
                                      <th>No. Trx</th>
                                      <th>Total Mandatos</th>
                                      <th>Mantis fee</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {users.map((user, userIndex) => {
                                      let totalMandatesAmount =
                                        user.moneyOnMandates.reduce(
                                          (acc, cur) => {
                                            return acc + cur.amount;
                                          },
                                          0
                                        );
                                      let totalMantisAmount =
                                        user.mantisPayments.reduce(
                                          (acc, cur) => {
                                            return acc + cur.amount;
                                          },
                                          0
                                        );
                                      if (
                                        totalMandatesAmount === 0 &&
                                        totalMantisAmount === 0
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <tr key={userIndex}>
                                          <td>{userIndex}</td>
                                          <td>
                                            {user.firstName} {user.lastName}
                                          </td>
                                          <td>{user.identificationType}</td>
                                          <td>{user.identification}</td>
                                          <td>{user.cellPhone}</td>
                                          <td>{user.email}</td>
                                          <td>{user.payments.length}</td>
                                          <td>
                                            ${formatMoney(totalMandatesAmount)}
                                          </td>
                                          <td>
                                            ${formatMoney(totalMantisAmount)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          );
                        })}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {selectedUser && (
        <Modal
          isOpen={selectedUser !== null}
          toggle={() => {
            setSelectedUser(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSelectedUser(null);
            }}
          >
            {selectedUser.firstName + " " + selectedUser.lastName}
          </ModalHeader>
          <ModalBody>
            {selectedUser.moneyOnMandates.length > 0 && (
              <>
                <h5>Mandatos</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Cantidad</th>
                      <th>Monto Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUser.moneyOnMandates.map((mandate, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {mandate._id?.month
                              ? getMonthAbreviation(mandate._id.month) +
                                `-${mandate._id.year}`
                              : "-"}
                          </td>
                          <td>{mandate.count}</td>
                          <td>${formatMoney(mandate.total)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
            {selectedUser.mantisPayments.length > 0 && (
              <>
                <h5>Pagos Mantis</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Cantidad</th>
                      <th>Monto Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUser.mantisPayments.map((payment, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {payment._id?.month
                              ? getMonthAbreviation(payment._id.month) +
                                `-${payment._id.year}`
                              : "-"}
                          </td>
                          <td>{payment.count}</td>
                          <td>${formatMoney(payment.total)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}

export default connect(select)(Billing);
