import React, {useState, useEffect} from "react";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import {backUrl} from "../utils/backURL";
import {registerYointLink} from "../ApiCalls/yoint";

function YointResponse(props) {
  const location = useLocation();
  const values = queryString.parse(location.search);

  const [resultInsert, setResultInsert] = useState(values.resultInsert);
  const [usersId, setUsersId] = useState(values.usersId);
  const [banks, setBanks] = useState(values.banks);

  const userId = location.pathname.split("/")[4];

  useEffect(() => {
    registerLink();
  }, []);

  async function registerLink() {
    registerYointLink(usersId, banks, userId);
  }
}

export default YointResponse;
