import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import BankCard from "../Cards/BankCard";
import AccountCard from "../Cards/AccountCard";
import TransactionCard from "../Cards/TransactionCard";
import { backUrl } from "../utils/backURL";
import JSONPretty from "react-json-pretty";

function BelvoTestData(props) {
  const [loadingData, setLoadingData] = useState(true);
  const [banks, setBanks] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [balances, setBalances] = useState([]);
  const [riskInsights, setRiskInsights] = useState([]);

  useEffect(() => {
    getTestData();
  }, []);

  async function getTestData() {
    const dataProm = await fetch(`${backUrl}/belvoData/getTestData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dataResp = await dataProm.json();
    console.log(dataResp);
    if (dataResp.success) {
      setBanks(dataResp.banks.data.data);
      setAccounts(dataResp.accounts.data.accountsData);
      setTransactions(dataResp.transactions.data.transactionsData);
      setBalances(dataResp.balances.data.balancesData);
      setRiskInsights(dataResp.riskInsights.data.riskInsightsData);
    }
    setLoadingData(false);
  }

  return (
    <>
      <h1>Información Belvo</h1>
      {loadingData && <Spinner />}
      {banks.length > 0 && (
        <>
          <h2>Bancos:</h2>
          <Row>
            {banks.map((bank, index) => {
              return (
                <Col key={index}>
                  <BankCard bank={bank} />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      {accounts.length > 0 && (
        <>
          <h2>Cuentas:</h2>
          <Row>
            {accounts.map((account, index) => {
              return (
                <Col key={index}>
                  <AccountCard account={account} />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      {transactions.length > 0 && (
        <>
          <h2>Transacciones:</h2>
          <Row>
            {transactions.map((transaction, index) => {
              return (
                <Col key={index}>
                  <TransactionCard transaction={transaction} />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      {riskInsights && (
        <>
          <h2>Insights de riesgo:</h2>
          <JSONPretty id="json-pretty" data={riskInsights}></JSONPretty>
        </>
      )}
    </>
  );
}
export default BelvoTestData;
