import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { verifyEmail } from "../ApiCalls/email";
import { Spinner } from "react-bootstrap";

function EmailValidation(props) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("LOADING");
  const location = useLocation();
  const values = queryString.parse(location.search);
  const verificationId = values.verificationId;

  useEffect(() => {
    verifyMail();
  }, []);

  async function verifyMail() {
    const verifyRes = await verifyEmail(verificationId);
    if (verifyRes.success) {
      setStatus("SUCCESS");
    } else {
      setStatus("ERROR");
    }
    setLoading(false);
  }

  return (
    <div style={{ marginTop: 200 }}>
      {loading ? (
        <Spinner animation="border" />
      ) : status === "SUCCESS" ? (
        <h1>¡Correo verificado con éxito!</h1>
      ) : (
        <h1>{`No pudimos verificar tu correo electrónico :(`}</h1>
      )}
    </div>
  );
}

export default EmailValidation;
