import React, { useState } from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { connect } from "react-redux";
import { setInStorage, getFromStorage } from "../utils/storage";
import { Spinner } from "react-bootstrap";
import "./styles/backoffice.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../ApiCalls/authentication";
import { userLogout } from "../Redux/modules/auth";
// import { browserHistory } from 'react-router';

function mapDispatchToProps(dispatch) {
  return {
    userLogout: () => dispatch(userLogout()),
  };
}

const select = (state) => {
  return {
    user: state.auth.user,
  };
};

function SidebarMenuComponent(props) {
  const [signingOut, setSigningOut] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  /* constructor(props) {
    super(props);

    this.state = {
      signingOut: false
    };

    this.logout = this.logout.bind(this);
  }

  async logout() {

    this.setState({ signingOut: true });

    const localStorageToken = getFromStorage('nibi_admin_app');

    if (localStorageToken && localStorageToken.token) {

      const { token } = localStorageToken;
      const prom = await fetch(`${apiUrl}/signin/account/logout?token=${token}`);
      const json = await prom.json();

      if (json.success) this.props.userLogout();
    }

  }

  componentWillUnmount() {
    this.props.history.push('/');
  } */

  async function handleLogout() {
    setSigningOut(true);

    const localStorageToken = getFromStorage("mantis_app");

    if (localStorageToken && localStorageToken.token) {
      const { token } = localStorageToken;
      logout(token);
      setInStorage("mantis_app", null);
      props.userLogout();
      navigate("/login");
    }
  }

  return (
    <div className="sidebar-menu-component">
      <SideNav
        onSelect={(selected) => {
          console.log("SELECTED");
          console.log(selected);
          if (location.pathname !== selected) navigate(selected);
        }}
      >
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={location.pathname}>
          <NavItem eventKey="/">
            <NavIcon>
              <i className="fas fa-chart-line" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Dashboard</NavText>
            <NavItem eventKey="/backoffice/dashboard">
              <NavText>General</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/billing">
              <NavText>Facturación</NavText>
            </NavItem>
          </NavItem>

          <NavItem eventKey="/dashboardMentor">
            <NavIcon>
              <i className="fas fa-users" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Usuarios</NavText>
          </NavItem>

          <NavItem eventKey="/">
            <NavIcon>
              <i
                className="fas fa-money-bill-alt"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>Pagos</NavText>
            <NavItem eventKey="/backoffice/recurringPayments">
              <NavText>Pagos recurrentes</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/investments">
              <NavText>Inversiones</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/paymentLinks">
              <NavText>Links de pago</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/moneyOnMandates">
              <NavText>Pagos de mandato</NavText>
            </NavItem>
          </NavItem>

          <NavItem eventKey="/">
            <NavIcon>
              <i className="fas fa-cog" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Información Adicional</NavText>
            <NavItem eventKey="/backoffice/tpaga">
              <NavText>Tpaga</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/planCalculator">
              <NavText>Calculadora plan</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/eficaciaDemo">
              <NavText>Demo eficacia</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/startcoConversations">
              <NavText>Conversaciones Startco</NavText>
            </NavItem>
          </NavItem>
          <NavItem eventKey="/">
            <NavIcon>
              <i className="fas fa-bell" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Notificaciones</NavText>
            <NavItem eventKey="/backoffice/whatsapp">
              <NavText>Whatsapp</NavText>
            </NavItem>
            <NavItem eventKey="/backoffice/pushNotifications">
              <NavText>Push</NavText>
            </NavItem>
          </NavItem>
          <NavItem eventKey="/backoffice/mantisBusinesses">
            <NavIcon>
              <i className="fas fa-building" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Empresas</NavText>
          </NavItem>

          <NavItem
            onSelect={() => {
              handleLogout();
            }}
          >
            <NavIcon>
              {signingOut ? (
                <div style={{ marginTop: "5px" }}>
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <i
                  className="fas fa-sign-out-alt"
                  style={{ fontSize: "1.75em" }}
                />
              )}
            </NavIcon>
            <NavText>{signingOut ? "" : "Cerrar sesión"}</NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </div>
  );
}

export default connect(select, mapDispatchToProps)(SidebarMenuComponent);
