import React, {useState, useEffect} from "react";
import {getDashboardData} from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {token} from "morgan";
import {getIrisLinkRequests, setIrisLink} from "../ApiCalls/backoffice";
import {getMonthDiff} from "../utils/dateUtils";
import {uploadFile, uploadImage} from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function PaymentLinks(props) {
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [linkRequests, setLinkRequests] = useState([]);
  const [newPaymentLink, setNewPaymentLink] = useState("");
  const [showEnterLinkModal, setShowEnterLinkModal] = useState(false);
  const [updatingLink, setUpdatingLink] = useState(false);
  const [requestToUpdate, setRequestToUpdate] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const linkRequests = await getIrisLinkRequests(props.token, props.userId);
    if (linkRequests.success) {
      setLinkRequests(linkRequests.collectionSources);
    }
    setLoading(false);
  }

  async function updateLink(event) {
    event.preventDefault();
    setUpdatingLink(true);
    const updateLinkRes = await setIrisLink(
      props.token,
      props.userId,
      requestToUpdate._id,
      newPaymentLink,
      requestToUpdate.irisLinkAmount,
      requestToUpdate.user.firstName,
      requestToUpdate.user.cellPhone
    );
    if (updateLinkRes.success) {
      setAlertMessage("Enlace actualizado correctamente");
      setShowEnterLinkModal(false);
      setNewPaymentLink("");
      getData();
    }
    setUpdatingLink(false);
  }

  return (
    <div style={{paddingTop: 20, paddingBottom: 20}}>
      <h1>Solicitudes de enlaces</h1>
      <br />
      {alertMessage && (
        <Row>
          <Col xs={6} style={{margin: "auto", marginTop: 50}}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {linkRequests.length > 0 && (
            <Row>
              <Col xs={10} style={{margin: "auto"}}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Nombre</th>
                          <th>Nombre Cuenta</th>
                          <th>Monto</th>
                          <th>Ingresar link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {linkRequests.map((request, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {request.user.firstName} {request.user.lastName}
                              </td>
                              <td>{request.name}</td>
                              <td>${formatMoney(request.irisLinkAmount)}</td>
                              <td
                                onClick={() => {
                                  setRequestToUpdate(request);

                                  setShowEnterLinkModal(true);
                                }}
                              >
                                <i
                                  className="fas fa-money-bill"
                                  style={{fontSize: "1em", cursor: "pointer"}}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <hr />

          {linkRequests.length === 0 && <h2>No hay solicitudes de enlaces!</h2>}
          <Modal
            isOpen={showEnterLinkModal}
            toggle={() => {
              if (!updatingLink) {
                setShowEnterLinkModal(!showEnterLinkModal);
              }
            }}
          >
            <ModalHeader
              toggle={() => {
                if (!updatingLink) {
                  setShowEnterLinkModal(!showEnterLinkModal);
                }
              }}
            >
              Generar pago
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={updateLink} style={{margin: "auto"}}>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="amount">Enlace:</Label>
                      <Input
                        type="text"
                        name="amount"
                        id="amount"
                        value={newPaymentLink}
                        onChange={(e) => {
                          setNewPaymentLink(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} style={{margin: "auto"}}>
                    <Button color="primary" type="submit">
                      {updatingLink ? (
                        <Spinner size={"sm"} />
                      ) : (
                        "Asignar enlace"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
}

export default connect(select)(PaymentLinks);
