import React from "react";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import "./styles/dataTreatment.scss";

export default function DataTreatment() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div
      style={{marginTop: 30, width: isMobile ? "85%" : "50%", margin: "auto"}}
    >
      <img
        src={mantisLogo}
        alt="Mantis Logo"
        height={isMobile ? 40 : 70}
        style={{marginTop: 20}}
      />
      <br />
      <br />
      <h1 className="doc-title">
        POLÍTICA DE PRIVACIDAD Y DE PROTECCIÓN DE DATOS PERSONALES DE MANTIS APP
        S.A.S.
      </h1>
      <p>
        La sociedad MANTIS APP S.A.S., domiciliada en Bogotá D.C., Colombia e
        identificada con NIT 901712099 - 2 (en adelante, la “Sociedad”), dando
        cumplimiento a las normas contenidas en la Ley Estatutaria 1581 de 2012,
        el Decreto 1074 de 2015 y las demás normas concordantes, por las cuales
        se dictan disposiciones generales para la protección de datos
        personales, en su calidad de Responsable del Tratamiento de Datos
        Personales, se permite dar a conocer la presente Política de Privacidad
        y de Protección de Datos Personales (en adelante la “Política”) para
        regular la recolección, almacenamiento, tratamiento, administración,
        transferencia, transmisión, protección y supresión de aquella
        información que se reciba de los titulares de datos personales o de
        terceros a través de los diferentes canales de recolección de datos que
        ha dispuesto en el desarrollo de sus actividades.
      </p>
      <h2 className="subtitle">I. Definiciones:</h2>
      <p>
        Para efectos de la presente Política, las palabras que a continuación se
        definen tendrán el significado asignado en este capítulo, sea que se
        escriban o no en mayúsculas, o que se encuentren en plural o singular.
      </p>
      <ol type="i">
        <li>
          <p>
            <u>Autorización</u>: Consentimiento previo, expreso e informado del
            Titular para llevar a cabo el Tratamiento de Datos Personales. La
            autorización puede constar en cualquier mecanismo que permita
            garantizar su posterior consulta. Los Titulares de la información
            podrán otorgar su autorización por escrito o de forma oral. La
            Sociedad mantendrá registros o mecanismos necesarios para demostrar
            cuándo y cómo se obtuvo la autorización por parte de los titulares
            de datos personales para su tratamiento.
          </p>
        </li>
        <li>
          <p>
            <u>Base de Datos</u>: Conjunto organizado de Datos Personales que
            sea objeto de Tratamiento, los cuales pueden ser almacenados y/o
            procesados en servidores ubicados en centros de cómputo o papelería
            física, ya sean propios o contratados con terceros, localizados en
            el territorio nacional o en distintos países.
          </p>
        </li>
        <li>
          <p>
            <u>Dato Personal</u>: Cualquier información vinculada o que pueda
            asociarse a una o varias personas naturales determinadas o
            determinables.
          </p>
        </li>
        <li>
          <p>
            <u>Datos Sensibles</u>: Se entiende por datos sensibles aquellos que
            afectan la intimidad del Titular o cuyo uso indebido puede generar
            su discriminación, tales como aquellos que revelen el origen racial
            o étnico, la orientación política, las convicciones religiosas o
            filosóficas, la pertenencia a sindicatos, organizaciones sociales,
            de derechos humanos o que promueva intereses de cualquier partido
            político o que garanticen los derechos y garantías de partidos
            políticos de oposición así como los datos relativos a la salud, a la
            vida sexual y los datos biométricos, entre otros.
          </p>
        </li>
        <li>
          <p>
            <u>Encargado del Tratamiento</u>: Persona natural o jurídica,
            pública o privada, que por sí misma o en asocio con otros, realice
            el Tratamiento de Datos Personales por cuenta del Responsable del
            Tratamiento de Datos Personales.
          </p>
        </li>
        <li>
          <p>
            <u>Responsable del Tratamiento</u>: Persona natural o jurídica,
            pública o privada, que por sí misma o en asocio con otros, decida
            sobre la base de datos y/o el Tratamiento de los datos. Para efectos
            de la presente Política, el Responsable será la Sociedad.
          </p>
        </li>
        <li>
          <p>
            <u>Titular</u>: Persona natural cuyos datos personales sean objeto
            de Tratamiento.
          </p>
        </li>
        <li>
          <p>
            <u>Tratamiento</u>: Cualquier operación o conjunto de operaciones
            sobre Datos Personales, tales como la recolección, almacenamiento,
            uso, circulación o supresión.
          </p>
        </li>
        <li>
          <p>
            <u>Transferencia</u>: Consiste en que el Responsable y/o Encargado
            del Tratamiento de datos personales, ubicado en Colombia, envía la
            información o los datos personales a un receptor, que a su vez es
            Responsable del Tratamiento y se encuentra dentro o fuera del país.
          </p>
        </li>
        <li>
          <p>
            <u>Transmisión</u>: Tratamiento de datos personales que implica la
            comunicación de los mismos dentro o fuera del territorio de la
            República de Colombia cuando tenga por objeto la realización de un
            Tratamiento por el Encargado por cuenta del Responsable.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">
        II. Autorización para realizar el Tratamiento de los Datos Personales:
      </h2>
      <p>
        Habida cuenta que la Política se encuentra a disposición de todo aquel
        que desee consultarla, se deja establecido que el Tratamiento de Datos
        Personales realizados por la Sociedad contará con el consentimiento
        libre, previo, expreso e informado del Titular de dichos datos y estará
        previamente autorizado por éste cuando así lo indique por medio de un
        documento físico, electrónico o cualquier otro formato, o por cualquier
        otra conducta inequívoca.
      </p>
      <p>
        Si el Titular desea sus Datos Personales sean suprimidos de las Bases de
        Datos de la Sociedad, deberá manifestarlo en forma expresa a partir del
        momento en el que haya suministrado sus Datos Personales o tenga
        conocimiento de la presente Política, al correo electrónico
        jairo@mantisapp.co
      </p>
      <h2 className="subtitle">III Responsable del Tratamiento:</h2>
      <p>
        La persona jurídica responsable del tratamiento de datos personales y
        por tanto de la base de datos en la cual se encuentren ubicados los
        mismos, es MANTIS APP S A S, sociedad legalmente constituida,
        identificada con NIT: 901712099 - 2, con domicilio principal en la
        ciudad de Bogotá D.C, Colombia.
      </p>
      <p>
        Dirección: Cra16a #137-29
        <br />
        Teléfono: 3154308744
        <br />
        Correo electrónico: jairo@mantisapp.co
      </p>
      <h2 className="subtitle">IV. Tratamiento de los Datos Personales:</h2>
      <p>
        La Sociedad, en su calidad de Responsable del Tratamiento debidamente
        autorizado, y todo aquel Encargado que designe, podrá realizar el
        Tratamiento de los Datos Personales suministrados, lo que incluye la
        recolección, almacenamiento, tratamiento, uso, actualización,
        circulación, administración, cesión, transferencia, transmisión,
        protección y supresión de los mismos.
      </p>
      <p>Así mismo, se resalta que la Sociedad podrá, entre otras:</p>
      <ol type="a">
        <li>
          <p>
            Designar a uno o varios Encargados del Tratamiento de los Datos
            Personales;
          </p>
        </li>
        <li>
          <p>
            Transferir y/o transmitir los Datos Personales sujetos a Tratamiento
            a las compañías que hagan parte de su grupo empresarial, esto es, a
            compañías matrices, filiales o subsidiarias, así como a cualquier
            otro tercero, dentro o fuera del territorio nacional, bien sean
            personas jurídicas o naturales, nacionales o extranjeras, aun cuando
            en el país de ubicación del receptor no se cuenten con normas que
            establezcan un estándar de protección de datos similares a los
            vigentes en el territorio nacional;
          </p>
        </li>
        <li>
          <p>
            Transferir y/o transmitir los Datos Personales sujetos a Tratamiento
            a terceros aliados que puedan brindar mejores servicios al Titular,
            de diferente clase, como créditos, servicios de salud, entre otros;
          </p>
        </li>
        <li>
          <p>
            Proporcionar dichos Datos Personales a agentes, subcontratistas y
            demás terceros para la consecución de los fines relacionados en al
            acápite siguiente, y
          </p>
        </li>
        <li>
          <p>
            Revelar la información cuando así lo requieran las autoridades
            debidamente facultadas mediante orden administrativa o judicial.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">
        {" "}
        V. Finalidades del Tratamiento de los Datos Personales:
      </h2>
      <p>
        La Sociedad realizará el Tratamiento de los Datos Personales con los
        siguientes fines específicos:
      </p>
      <ol type="a">
        <li>
          <p>Realizar tareas básicas de gestión administrativa.</p>
        </li>
        <li>
          <p>
            Para los fines propios de los contratos que se hayan celebrado o a
            celebrar entre Mantis y sus usuarios y clientes, al igual que frente
            a los servicios que ofrece y presta Mantis a través de su página y/o
            sus aplicaciones y aquellas actividades derivadas de su objeto
            social.
          </p>
        </li>
        <li>
          <p>
            Consultar la información del titular de los datos -y de su
            representante legal cuando corresponda- en listas restrictivas para
            prevenir lavado de activos, financiación del terrorismo, entre otras
            finalidades de prevención de riesgos.
          </p>
        </li>
        <li>
          <p>
            Desarrollar los procesos que se requieran para la adecuada
            prestación de los productos y/o servicios contratados por los
            usuarios y/o clientes de Mantis.
          </p>
        </li>
        <li>
          <p>
            Adelantar los trámites y servicios que sean solicitados a Mantis.
          </p>
        </li>
        <li>
          <p>
            Actualizar Bases de Datos, incluyendo los casos en que se requiera
            transmitir o transferir a un tercero, la información para la
            validación, depuración, enriquecimiento y homogenización de datos,
            previo cumplimiento de las exigencias legales.
          </p>
        </li>
        <li>
          <p>
            Proveer servicios y productos requeridos y dar cumplimiento a las
            demás obligaciones contraídas con el Titular.
          </p>
        </li>
        <li>
          <p>Evaluar la calidad de los servicios de la Sociedad.</p>
        </li>
        <li>
          <p>
            Desarrollar actividades de comunicación y posicionamiento de marca.
          </p>
        </li>
        <li>
          <p>
            Informar sobre nuevos servicios que estén relacionados con los
            servicios contratados o adquiridos.
          </p>
        </li>
        <li>
          <p>
            Transferir o transmitir la información plasmada en las
            comunicaciones recibidas por el titular por parte de Empresas
            aliadas.
          </p>
        </li>
        <li>
          <p>
            Analizar y estudiar la información plasmada en las comunicaciones
            recibidas por el titular por parte de Empresas aliadas.
          </p>
        </li>
        <li>
          <p>
            Adelantar las actividades necesarias para gestionar las solicitudes,
            quejas y reclamos presentadas por los clientes, usuarios y/o
            terceros, y direccionarlas a las áreas responsables de emitir las
            respuestas correspondientes.
          </p>
        </li>
        <li>
          <p>
            Realizar la transferencia de información a terceros aliados con
            fines comerciales y en el marco de las demás finalidades contenidas
            en el presente documento.
          </p>
        </li>
        <li>
          <p>
            Responder requerimientos legales de entidades administrativas y
            judiciales.
          </p>
        </li>
        <li>
          <p>Soportar procesos de auditoría externa o interna.</p>
        </li>
        <li>
          <p>
            El control y prevención de fraudes, lavado de activos y la
            financiación del terrorismo.
          </p>
        </li>
        <li>
          <p>
            El desarrollo de actividades de investigación y de análisis
            estadístico.
          </p>
        </li>
        <li>
          <p>Realizar estudios estadísticos.</p>
        </li>
        <li>
          <p>
            Transferir y transmitir información para brindar mejores productos a
            los Titulares por parte de terceros.
          </p>
        </li>
        <li>
          <p>
            Consultar y acceder a la información del Titular (privados,
            semiprivados, sensibles o reservados) en distintas bases de datos o
            Entidades Públicas o Privadas, ya sean en Colombia, en el Extranjero
            o Internacional.
          </p>
        </li>
        <li>
          <p>
            Consultar la información del Titular en Centrales de Información
            Financiera, así como reportar información a estas bases de datos,
            cuando exista mérito para hacerlo con el lleno de requisitos legales
            para tal fin.
          </p>
        </li>
        <li>
          <p>
            En general, para realizar directamente el Tratamiento, según este
            término se define en la Ley 1581 de 2012 a través de un encargado
            del tratamiento, ubicado en Colombia o en cualquier otro país a
            quien se le suministrarán los datos del Titular a través de
            transmisiones internacionales o nacionales, según el caso.
          </p>
        </li>
      </ol>
      <p>
        Los titulares no estarán obligados en ningún evento a autorizar el
        tratamiento de datos sensibles. Sin perjuicio de lo anterior, en los
        casos en que, para hacer posible la prestación del servicio, se requiera
        que los titulares suministren algún dato personal sensible, se deberá
        consentir expresamente el tratamiento de esta información.
      </p>
      <p>
        Si algún dato personal es proporcionado, dicha información será
        utilizada solo para los propósitos aquí señalados y por tanto la
        Sociedad no procederá a vender, licenciar, transmitir, o divulgar la
        misma, salvo que (i) exista autorización expresa para hacerlo, (ii) sea
        necesario para permitir a los contratistas o agentes prestar los
        servicios encomendados; (iii) sea necesario con el fin de proveer
        nuestros servicios y/o productos; (iv) sea necesario divulgarla a las
        entidades que presten servicios de mercadeo en nombre de la Sociedad o a
        otras entidades con las cuales se tengan acuerdos de mercado conjunto;
        (v) la información tenga relación con una fusión, consolidación,
        adquisición, desinversión, u otro proceso de reestructuración de la
        sociedad; y (vi) que sea requerido o permitido por la ley.
      </p>
      <p>
        La Sociedad podrá subcontratar a terceros para el procesamiento de
        determinadas funciones o información. Cuando efectivamente se
        subcontrate con terceros el procesamiento de información personal o se
        proporcione información personal a terceros prestadores de servicios la
        Sociedad advierte a dichos terceros sobre la necesidad de proteger dicha
        información personal con medidas de seguridad apropiadas. Se prohíbe el
        uso de la información para fines propios y se solicita que no se
        divulgue la información personal a otros.
      </p>
      <p>
        La Sociedad podrá consultar, solicitar, suministrar, reportar, procesar
        y divulgar toda la información actual y futura que se refiera al
        comportamiento crediticio, financiero, comercial y de servicios, en
        cualquier tiempo, en Datacredito o en cualquier otra base de datos
        manejada por un operador. Toda información relevante para conocer su
        desempeño como deudor, su capacidad de pago, origen de fondos, la
        viabilidad para entablar o mantener una relación contractual o
        comercial.
      </p>
      <p>
        Así mismo, se resalta que la Sociedad podrá, entre otras, (i) designar a
        uno o varios Encargados del Tratamiento de los Datos Personales, (ii)
        transferir y/o trasmitir los Datos Personales sujetos a Tratamiento a la
        compañías que hagan parte de su grupo empresarial esto es, a compañías
        matrices, filiales o subsidiarias, así como a cualquier otro tercero,
        dentro o fuera del territorio nacional, bien sean personas jurídicas o
        naturales, nacionales o extranjeras, aun cuando en el país de ubicación
        del receptor no se cuenten con normas que establezcan un estándar de
        protección de datos similares a los vigentes en el territorio nacional,
        (iii) proporcionar dichos Datos Personales a agentes, subcontratistas y
        demás terceros para la consecución de los fines seleccionados en el
        acápite siguiente, y (iv) revelar la información cuando así lo requiera
        las autoridades públicas debidamente facultadas mediante orden
        administrativa o judicial.
      </p>
      <h2 className="subtitle">
        VI. Derechos de los Titulares de los Datos Personales:
      </h2>
      <p>
        Los derechos que le asisten a los Titulares de los Datos Personales,
        son:
      </p>
      <ol type="a">
        <li>
          <p>
            Conocer, actualizar y rectificar mis datos personales frente a los
            Responsables del Tratamiento o Encargados del Tratamiento. Este
            derecho se podrá ejercer, entre otros frente a datos parciales,
            inexactos, incompletos, fraccionados, que induzcan a error, o
            aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido
            autorizado;
          </p>
        </li>
        <li>
          <p>
            Solicitar prueba de la autorización otorgada al Responsable del
            Tratamiento salvo cuando expresamente se exceptúe como requisito
            para el Tratamiento, de conformidad con lo previsto en el artículo
            10 de la presente ley;
          </p>
        </li>
        <li>
          <p>
            Ser informado por el Responsable del Tratamiento o el Encargado del
            Tratamiento, previa solicitud, respecto del uso que le ha dado a mis
            datos personales;
          </p>
        </li>
        <li>
          <p>
            Presentar ante la Superintendencia de Industria y Comercio quejas
            por infracciones a lo dispuesto en la presente ley y las demás
            normas que la modifiquen, adicionen o complementen;
          </p>
        </li>
        <li>
          <p>
            Revocar la autorización y/o solicitar la supresión del dato cuando
            en el Tratamiento no se respeten los principios, derechos y
            garantías constitucionales y legales. La revocatoria y/o supresión
            procederá cuando la Superintendencia de Industria y Comercio haya
            determinado que en el Tratamiento el Responsable o Encargado han
            incurrido en conductas contrarias a esta ley y a la Constitución;
          </p>
        </li>
        <li>
          <p>
            Acceder en forma gratuita a sus datos personales que hayan sido
            objeto de Tratamiento.
          </p>
        </li>
      </ol>
      <p>
        A su vez el Titular se compromete a brindar información cierta, veraz,
        exacta, autentica y vigente y se hace responsable por el contenido de la
        misma y de los perjuicios que cause a la Sociedad, o a terceras
        personas.
      </p>
      <h2 className="subtitle">
        VII. Deberes de la Sociedad cuando actúa en calidad de Responsable:
      </h2>
      <ol type="a">
        <li>
          <p>
            Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio
            del derecho de habeas data
          </p>
        </li>
        <li>
          <p>
            Solicitar y conservar, en las condiciones previstas de la ley 1581,
            copia de la respectiva autorización otorgada por el Titular.
          </p>
        </li>
        <li>
          <p>
            Informar debidamente al Titular sobre la finalidad de la recolección
            y los derechos que le asisten por virtud de la autorización
            otorgada;
          </p>
        </li>
        <li>
          <p>
            Conservar la información bajo las condiciones de seguridad
            necesarias para impedir la pérdida, uso o acceso no autorizado o
            fraudulento.
          </p>
        </li>
        <li>
          <p>
            Garantizar que la información que se suministre a un Encargado del
            Tratamiento si se presentase sea veraz, completo, exacta,
            actualizada y comprensible.
          </p>
        </li>
        <li>
          <p>
            Actualizar la información, comunicado de forma oportuna al Encargado
            del Tratamiento, todas las novedades respecto de los datos que
            previamente le haya suministrado y adoptar las demás medidas
            necesarias para que la información suministrada a este se mantenga
            actualizada.
          </p>
        </li>
        <li>
          <p>
            Rectificar la información cuando sea incorrecta y comunicar lo
            pertinente al Encargado del Tratamiento.
          </p>
        </li>
        <li>
          <p>
            Suministrar al Encargado del Tratamiento, según el caso, únicamente
            datos cuyo Tratamiento este previamente autorizado de conformidad
            con lo previsto en la ley 1581.
          </p>
        </li>
        <li>
          <p>
            Exigir al Encargado del Tratamiento en todo momento, el respeto a
            las condiciones de seguridad y privacidad de la información del
            Titular.
          </p>
        </li>
        <li>
          <p>
            Tramitar las consultas y reclamos formulados en los términos
            señalados en la ley 1581.
          </p>
        </li>
        <li>
          <p>
            Levantar un documento de políticas y procedimientos para garantizar
            el adecuado cumplimiento de la ley 1581 y en especial, para la
            atención de consultas y reclamos.
          </p>
        </li>
        <li>
          <p>
            Informar al Encargado del Tratamiento cuando determinada información
            se encuentra en discusión por parte del Titular, una vez se haya
            presentado la reclamación y no haya finalizado el trámite
            respectivo.
          </p>
        </li>
        <li>
          <p>Informar a solicitud del Titular sobre el uso dado a sus datos.</p>
        </li>
        <li>
          <p>
            Informar a la autoridad de protección de datos cuando se presenten
            violaciones a los códigos de seguridad y existen riesgos en la
            administración de la información de los titulares.
          </p>
        </li>
        <li>
          <p>
            Cumplir las instrucciones y requerimientos que imparta la
            Superintendencia de Industria y Comercio.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">
        VIII. Deberes de la Sociedad cuando actúa en calidad de Encargado:
      </h2>
      <ol type="a">
        <li>
          <p>
            Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio
            del derecho de habeas data.
          </p>
        </li>
        <li>
          <p>
            Conservar la información bajo las condiciones de seguridad
            necesarias para impedir su adulteración, perdida, consulta, uso o
            acceso no autorizado o fraudulento.
          </p>
        </li>
        <li>
          <p>
            Realizar oportunamente la actualización, rectificación o supresión
            de los datos en los términos de la ley 1581.
          </p>
        </li>
        <li>
          <p>
            Actualizar la información reportada por los Responsables del
            Tratamiento dentro de los cinco (5) días hábiles contado a partir de
            su recibo.
          </p>
        </li>
        <li>
          <p>
            Tramitar las consultas y los reclamos formulados por los Titulares
            en los términos señalados en la ley 1581.
          </p>
        </li>
        <li>
          <p>
            Adoptar un manual interno de políticas y procedimientos para
            garantizar el adecuado cumplimiento de la ley 1581 y, en especial,
            para la atención de consultas y reclamos por parte de los Titulares.
          </p>
        </li>
        <li>
          <p>
            Registrar en ela base de datos la leyenda “reclamo en trámite” en la
            forma en que se regula la ley 1581
          </p>
        </li>
        <li>
          <p>
            Insertar en la base de datos la leyenda “información en discusión
            judicial” una vez notificado por parte de la autoridad competente
            sobre procesos judiciales relacionados con la calidad del dato
            personal.
          </p>
        </li>
        <li>
          <p>
            Abstenerse de circular información que este siendo controvertida por
            el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia
            de Industria y Comercio.
          </p>
        </li>
        <li>
          <p>
            Permitir el acceso a la información únicamente a las personas que
            pueden tener acceso a ella.
          </p>
        </li>
        <li>
          <p>
            Informar a la Superintendencia de Industria y Comercio cuando se
            presenten violaciones a los códigos de seguridad y existen riesgos
            en la administración de la información de los Titulares.
          </p>
        </li>
        <li>
          <p>
            Cumplir las instrucciones y requerimientos que imparta la
            Superintendencia de industria y Comercio
          </p>
        </li>
      </ol>
      <p>
        Parágrafo. En el evento en que concurran las calidades de Responsable
        del Tratamiento y Encargado del Tratamiento en la misma persona, le será
        exigible el cumplimiento de los deberes previstos para cada uno.
      </p>
      <p>Deberes respecto de la Superintendencia de Industria y Comercio:</p>
      <ol type="a">
        <li>
          <p>
            Informarle las eventuales violaciones a los códigos de seguridad y
            la existencia de riesgos en la administración de la información de
            los Titulares.
          </p>
        </li>
        <li>
          <p>
            Cumplir las instrucciones y requerimientos que imparta la
            Superintendencia de Industria y Comercio.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">
        IX. Medidas de seguridad para la Protección de los Datos Personales:
      </h2>
      <p>
        La Sociedad adoptará las técnicas de seguridad que sean necesarias para
        otorgar seguridad a los registros evitando su adulteración, pérdida,
        consulta, uso o acceso no autorizado o fraudulento. Dichas medidas
        responderán a los requerimientos mínimos hechos por la legislación
        vigente y periódicamente se evaluará su efectividad. Sin embargo, la
        Sociedad no responderá en caso de presentarse una violación a sus
        sistemas de seguridad cuando medie fuerza mayor o caso fortuito.
      </p>
      <p>
        La Sociedad no asume responsabilidad alguna por los daños y perjuicios
        de toda naturaleza que puedan derivarse de la presencia de virus o de la
        presencia de otros elementos lesivos en los servicios prestados por
        terceros que puedan producir alteraciones en el sistema informático,
        documentos electrónicos o archivos del usuario.
      </p>
      <h2 className="subtitle">X. Uso del Sitio Web:</h2>
      <p>
        La utilización del sitio web mantisapp.co (en adelante, “Sitio Web”)
        implica la aceptación de esta política de privacidad por parte de los
        Titulares que hagan uso de ella. Por lo que aquellos Titulares que no
        estén de acuerdo con los términos acá incluidos deberán abstenerse de
        utilizar el Sitio Web. El Titular usuario del Sitio Web reconoce y
        acepta que la Sociedad podrá utilizar un sistema de seguimiento mediante
        la utilización de cookies. Las cookies utilizadas por el Sitio Web son
        ficheros enviados a un navegador por medio de un servidor web para
        registrar las actividades del usuario en la página Web y permitirle una
        navegación más fluida y personalizada. En todo caso, el usuario tiene la
        posibilidad de configurar su navegador para impedir la entrada de éstas,
        bloquearlas o, en su caso, eliminarlas. Lo anterior será responsabilidad
        exclusiva del Titular.
      </p>
      <p>
        La Sociedad hace seguimiento de ciertos datos, como el tipo de navegador
        utilizado por los Titulares, las horas de inicio de sesión, el tipo de
        software y otros datos para uso interno de la Sociedad. El objetivo de
        ello es únicamente mejorar el Sitio Web y ofrecer ciertas posibilidades
        técnicas. En ningún caso se vinculan estos datos a una persona
        específica, y solamente son útiles para la Sociedad.
      </p>
      <p>
        De igual manera, los servidores del Sitio Web detectan de manera
        automática la dirección IP y el nombre de la red utilizados por el
        usuario. Toda esta información es registrada temporalmente en un fichero
        de actividad del servidor que permite el posterior procesamiento de los
        datos con el fin de obtener mediciones estadísticas que permitan, entre
        otras, conocer el número de impresiones de páginas y el número de
        visitas realizadas a la página Web, entre otras mediciones.
      </p>
      <h2 className="subtitle">
        XI. Área responsable del tratamiento de Datos Personales:
      </h2>
      <p>
        El área de servicio al cliente será el área encargada de atención de las
        peticiones, consultas y reclamos ante la cual el Titular de la
        información podrá ejercer sus derechos y por tanto a conocer,
        actualizar, modificar, rectificar, corregir o suprimir la información
        suministrada en cualquier momento y para tal efecto deberá enviar un
        correo electrónico con su solicitud a jairo@mantisapp.co comunicarse al
        teléfono +57 3154308744.
      </p>
      <h2 className="subtitle">
        XII. Procedimiento para que los Titulares de los Datos Personales puedan
        ejercer sus derechos:
      </h2>
      <p>
        El Titular de los datos podrá conocer, actualizar, rectificar y suprimir
        la información suministrada e igualmente podrá ejercer su derecho a
        revocar la autorización suministrada para su tratamiento enviando la
        respectiva solicitud al correo electrónico jairo@mantisapp.co, o a la
        línea telefónica 3154308744. Para tal efecto, el Titular deberá como
        mínimo indicar: nombres y apellidos, tipo de documento y número,
        teléfono, correo electrónico, descripción del asunto y los cambios
        específicos que quiere hacer, esto asegurará que procesemos su petición
        correctamente.
      </p>
      <p>
        La consulta será atendida en un término máximo de diez (10) días hábiles
        contados a partir de la fecha de recibo de esta. Cuando no fuere posible
        atender la consulta dentro de dicho término, se informará al interesado,
        expresando los motivos de la demora y señalando la fecha en que se
        atenderá su consulta, la cual en ningún caso superará los cinco (5) días
        hábiles siguientes al vencimiento del primer término.
      </p>
      <h2 className="subtitle">XIII. Datos Personales de Menores de Edad:</h2>
      <p>
        La Sociedad reconoce la especial importancia de garantizar la protección
        de los datos personales de menores de edad y, en consecuencia,
        implementará medidas adicionales para asegurar la protección adecuada de
        dicha información en caso de que la empresa procese tales datos.
      </p>
      <ol type="a">
        <li>
          <p>
            Consentimiento y autorización: Cuando se trate de datos personales
            de menores de edad, la Sociedad. solicitará el consentimiento y
            autorización de los padres o representantes legales del menor para
            el tratamiento de sus datos personales, de acuerdo con la
            legislación colombiana aplicable.
          </p>
        </li>
        <li>
          <p>
            Finalidad del tratamiento: la Sociedad tratará los datos personales
            de menores de edad únicamente para fines específicos y legítimos,
            que sean adecuados y pertinentes a la luz de la protección y el
            interés superior del menor.
          </p>
        </li>
        <li>
          <p>
            Transparencia y acceso a la información: la Sociedad garantizará que
            los padres o representantes legales del menor tengan acceso a la
            información sobre el tratamiento de los datos personales de menores
            de edad, así como la posibilidad de ejercer sus derechos en nombre
            del menor, de acuerdo con lo establecido en la legislación
            colombiana.
          </p>
        </li>
        <li>
          <p>
            Seguridad y confidencialidad: la Sociedad implementará medidas
            técnicas, administrativas y humanas adicionales para garantizar la
            seguridad y confidencialidad de los datos personales de menores de
            edad, con el fin de evitar el acceso no autorizado, la divulgación,
            la alteración o la eliminación de dicha información.
          </p>
        </li>
        <li>
          <p>
            Limitación del tratamiento: la Sociedad limitará el tratamiento de
            los datos personales de menores de edad al mínimo necesario para
            cumplir con los fines específicos y legítimos para los que fueron
            recopilados, y evitará el tratamiento innecesario o excesivo de
            dicha información.
          </p>
        </li>
      </ol>
      <p>
        Al implementar estas medidas adicionales, la Sociedad busca garantizar
        la protección adecuada de los datos personales de menores de edad y
        cumplir con sus obligaciones legales y reglamentarias en materia de
        protección de datos personales.
      </p>
      <h2 className="subtitle">XIV. Modificaciones a la Política:</h2>
      <p>
        La Sociedad podrá modificar o enmendar esta Política de forma
        discrecional. Cuando se realicen modificaciones o cambios a ésta, se
        actualizará la fecha de esta, y esa modificación o enmienda será
        efectiva a partir de la fecha de actualización. Se recomienda revisar
        periódicamente esta Política para estar informado acerca de las
        modificaciones que se puedan presentar.
      </p>
      <h2 className="subtitle">
        XV. Transferencias Internacionales De Datos Personales:
      </h2>
      <p>
        La Sociedad podrá llevar a cabo transferencias internacionales de datos
        personales cuando sea necesario para el cumplimiento de sus fines y
        objetivos comerciales, siempre garantizando la protección adecuada de
        los datos personales en el extranjero.
      </p>
      <p>
        En caso de realizar transferencias internacionales de datos personales,
        la Sociedad adoptará las siguientes medidas para garantizar la
        protección adecuada de los datos personales:
      </p>
      <ol type="a">
        <li>
          <p>
            Cumplimiento de la normativa aplicable: La Sociedad se asegurará de
            cumplir con las disposiciones legales y reglamentarias colombianas
            en materia de transferencias internacionales de datos personales,
            así como con las normativas aplicables en los países receptores de
            los datos personales.
          </p>
        </li>
        <li>
          <p>
            Evaluación del nivel de protección en el país receptor: Antes de
            realizar una transferencia internacional de datos personales, la
            Sociedad evaluará el nivel de protección de datos personales en el
            país receptor, considerando factores como la legislación aplicable,
            las prácticas de protección de datos y las garantías ofrecidas por
            las autoridades locales.
          </p>
        </li>
        <li>
          <p>
            Garantías contractuales: la Sociedad establecerá garantías
            contractuales adecuadas con los responsables y encargados del
            tratamiento de datos personales en el país receptor, con el fin de
            asegurar que estos cumplen con las normativas aplicables y ofrecen
            un nivel de protección adecuado a los datos personales.
          </p>
        </li>
        <li>
          <p>
            Mecanismos de transferencia autorizados: la Sociedad utilizará
            mecanismos de transferencia autorizados por la legislación
            colombiana y, en su caso, por las autoridades de protección de
            datos, como acuerdos de nivel de protección de datos, cláusulas
            contractuales tipo o normas corporativas vinculantes.
          </p>
        </li>
        <li>
          <p>
            Aceptación del titular para el tratamiento internacional de datos:
            Al proporcionar sus datos personales a la Sociedad, el titular
            manifiesta su comprensión y aceptación de que dichos datos puedan
            ser tratados internacionalmente en el marco de las actividades
            comerciales y operativas de la empresa. La presente política se
            entenderá como la manifestación de la aceptación del titular para el
            tratamiento internacional de sus datos personales.
          </p>
        </li>
        <li>
          <p>
            Transparencia y acceso a la información: la Sociedad garantizará que
            los titulares de los datos personales tengan acceso a la información
            sobre las transferencias internacionales de sus datos, incluyendo
            los países receptores, los responsables y encargados del tratamiento
            en dichos países y las garantías aplicables para la protección de
            sus datos personales.
          </p>
        </li>
      </ol>
      <p>
        Mediante la adopción de estas medidas, la Sociedad busca garantizar la
        protección adecuada de los datos personales en el contexto de las
        transferencias internacionales y cumplir con sus obligaciones legales y
        reglamentarias en materia de protección de datos personales”
      </p>
      <h2 className="subtitle">
        XVI. Principio De Responsabilidad Demostrada:
      </h2>
      <p>
        La Sociedad se compromete a adoptar y aplicar el principio de
        responsabilidad demostrada en el tratamiento de datos personales, lo que
        implica adoptar medidas y prácticas que demuestren el cumplimiento de la
        normatividad aplicable en materia de protección de datos personales.
      </p>
      <p>
        Para garantizar el cumplimiento de este principio, la Sociedad
        implementará las siguientes acciones:
      </p>
      <ol type="a">
        <li>
          <p>
            Implementación de medidas de seguridad: la Sociedad aplicará medidas
            técnicas, administrativas y organizativas adecuadas para garantizar
            la protección de los datos personales, considerando factores como el
            nivel de riesgo, el tamaño de la organización, el alcance y la
            finalidad del tratamiento.
          </p>
        </li>
        <li>
          <p>
            Capacitación del personal: la Sociedad proporcionará capacitación y
            concientización a sus empleados y colaboradores involucrados en el
            tratamiento de datos personales, con el fin de asegurar que
            comprendan y apliquen las políticas, procedimientos y normativas
            aplicables a la protección de datos personales.
          </p>
        </li>
        <li>
          <p>
            Evaluación y monitoreo: la Sociedad llevará a cabo evaluaciones
            periódicas y auditorías internas y/o externas para asegurar que se
            cumplan las políticas y normativas aplicables, así como para
            identificar y abordar oportunidades de mejora en sus procesos y
            prácticas de tratamiento de datos personales.
          </p>
        </li>
        <li>
          <p>
            Documentación y registro de procesos: la Sociedad documentará y
            mantendrá registros de sus políticas, procedimientos y prácticas de
            tratamiento de datos personales, incluyendo información sobre el
            tratamiento, las bases de datos, las medidas de seguridad
            implementadas y las auditorías realizadas.
          </p>
        </li>
        <li>
          <p>
            Designación de un responsable de protección de datos: la Sociedad
            designará a un responsable de protección de datos, encargado de
            supervisar, coordinar y garantizar la aplicación de las políticas y
            normativas aplicables en materia de protección de datos personales,
            así como de atender consultas, solicitudes y reclamos de los
            titulares de los datos personales.
          </p>
        </li>
        <li>
          <p>
            Cooperación con las autoridades de control: la Sociedad cooperará de
            manera proactiva con la Superintendencia de Industria y Comercio y
            otras autoridades de control en lo que respecta a la protección de
            datos personales, así como para cumplir con las órdenes emitidas por
            dichas autoridades.
          </p>
        </li>
      </ol>
      <p>
        Al adoptar el principio de responsabilidad demostrada, la Sociedad busca
        garantizar el cumplimiento de sus obligaciones en materia de protección
        de datos personales y generar confianza en sus clientes, proveedores,
        empleados y demás partes interesadas en relación con el tratamiento de
        sus datos personales.”
      </p>
      <h2 className="subtitle">XVII. Vigencia de la Política:</h2>
      <p>
        La presente Política de Tratamiento de datos Personales rige a partir
        del 26 de diciembre de 2023.
      </p>
      <p>
        Mantis App S.A.S. podrá modificar esta política de tratamiento de datos,
        sin previo aviso y en cualquier momento, por lo cual, el Titular de los
        datos deberá revisar la página web de manera constante, ya que dicho
        documento actualizado estará al alcance de los titulares siempre.
      </p>
      <br />
      <br />
      <p>
        Jairo Andrés Pitta
        <br />
        Representante Legal
      </p>
    </div>
  );
}
