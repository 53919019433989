import React, {useState} from "react";
import {Row, Col, FormGroup, Label, Input, Button} from "reactstrap";

import {connect} from "react-redux";
import {
  sendWaitlistMessage,
  sendNonParametersTemplateMessage
} from "../../ApiCalls/whatsapp";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function WhatsappNotifications(props) {
  const [cellphone, setCellphone] = useState("");
  const [waitlistName, setWaitlistName] = useState("");
  const [sendToEveryone, setSendToEveryone] = useState(false);
  const [nonParametersTemplateName, setNonParametersTemplateName] =
    useState("");
  const [nonParametersTemplateLanguage, setNonParametersTemplateLanguage] =
    useState("");

  const handleSendWaitlistMessage = async () => {
    const res = await sendWaitlistMessage(
      props.userId,
      props.token,
      cellphone,
      waitlistName
    );
    if (res.success) {
      alert("Mensaje enviado");
    } else {
      alert("Error enviando mensaje");
    }
  };

  const handleSendNonParametersTemplateMessage = async () => {
    const res = await sendNonParametersTemplateMessage(
      props.userId,
      props.token,
      cellphone,
      sendToEveryone,
      nonParametersTemplateName,
      nonParametersTemplateLanguage
    );
    console.log(res);
    if (res.success) {
      alert("Mensaje enviado");
    } else {
      alert("Error enviando mensaje");
    }
  };

  return (
    <div
      style={{
        width: "80%",
        margin: "auto"
      }}
    >
      <h1>Whatsapp</h1>
      <Row>
        <Col xs={4} style={{margin: "auto"}}>
          <FormGroup>
            <Label>Celular:</Label>
            <Input
              type="text"
              name="cellphone"
              id="cellphone"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
              placeholder="(Ej: 573005703843)"
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                onChange={(e) => setSendToEveryone(e.target.checked)}
              />{" "}
              Enviar a todos
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <h2>waitlist_mantis</h2>
          <FormGroup>
            <Label>Nombre:</Label>
            <Input
              type="text"
              name="cellphone"
              id="cellphone"
              value={waitlistName}
              onChange={(e) => setWaitlistName(e.target.value)}
              placeholder="Nombre"
            />
          </FormGroup>
          <Button
            onClick={() => {
              if (cellphone && waitlistName) {
                handleSendWaitlistMessage();
              }
            }}
          >
            Enviar
          </Button>
        </Col>
        <Col xs={4}>
          <h4>Template sin parámetros</h4>
          <FormGroup>
            <Label>Nombre del template:</Label>
            <Input
              type="text"
              name="nonParametersTemplateName"
              id="nonParametersTemplateName"
              value={nonParametersTemplateName}
              onChange={(e) => setNonParametersTemplateName(e.target.value)}
              placeholder="Nombre del template"
            />
          </FormGroup>
          <Label>Idioma del template:</Label>
          <Input
            type="text"
            name="nonParametersTemplateLanguage"
            id="nonParametersTemplateLanguage"
            value={nonParametersTemplateLanguage}
            onChange={(e) => setNonParametersTemplateLanguage(e.target.value)}
            placeholder="Idioma del template"
          />

          <Button
            onClick={() => {
              if (nonParametersTemplateName) {
                handleSendNonParametersTemplateMessage();
              }
            }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(WhatsappNotifications);
