import React, {useState, useEffect} from "react";
import {getDashboardData} from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {token} from "morgan";
import {
  getTpagaProviders,
  getTpagaBalance,
  payTpagaBill,
  queryTpagaBill
} from "../ApiCalls/backoffice";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function Tpaga(props) {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [billReference, setBillReference] = useState("");
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    getProviders();
  }, []);

  async function getData() {
    const balanceRes = await getTpagaBalance(props.userId, props.token);

    if (balanceRes.success) {
      setCurrentBalance(balanceRes.data.balance);
    }
    setLoading(false);
  }
  async function queryBill() {
    setProcessing(true);
    const queryRes = await queryTpagaBill(
      selectedProvider,
      billReference,
      props.token
    );
    console.log(queryRes);
    setProcessing(false);
  }
  async function payBill() {
    setProcessing(true);
    const payRes = await payTpagaBill(
      token,
      amount,
      selectedProvider,
      billReference,
      token,
      "origin",
      props.token
    );
    console.log(payRes);
    setProcessing(false);
  }

  async function getProviders() {
    const providersRes = await getTpagaProviders(props.token);
    if (providersRes.success) {
      setProviders(providersRes.providers);
    }
  }

  return (
    <>
      <h1>Tpaga</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col xs={6}>
              <h2>Balance actual: ${formatMoney(currentBalance)}</h2>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Proveedor:</Label>
                    <Input
                      id="status"
                      name="selectedProvider"
                      type="select"
                      value={selectedProvider}
                      onChange={(e) => setSelectedProvider(e.target.value)}
                    >
                      {providers.map((provider, index) => {
                        return (
                          <option key={index} value={provider.id}>
                            {provider.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Referencia:</Label>
                    <Input
                      type="text"
                      name="amount"
                      id="amount"
                      value={billReference}
                      onChange={(e) => setBillReference(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Monto:</Label>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Id pago:</Label>
                    <Input
                      type="text"
                      name="token"
                      id="token"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={2}>
                  <Button onClick={queryBill} disabled={processing}>
                    {processing ? <Spinner /> : "Consultar"}
                  </Button>
                </Col>
                <Col xs={2}>
                  <Button onClick={payBill} disabled={processing}>
                    {processing ? <Spinner /> : "Pagar"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default connect(select)(Tpaga);
