import React, {useState} from "react";
import {Row, Col, Form, Button} from "react-bootstrap";
import {logoutEverywhere} from "../ApiCalls/authentication";
import {useNavigate} from "react-router-dom";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import "./login.scss";
import {useParams} from "react-router-dom";
import {resetPassword} from "../ApiCalls/mantisBusiness";

function ForgotPasswordBusiness(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [loggingOutEverywhere, setLoggingOutEverywhere] = useState(false);
  const {token} = useParams();
  const navigate = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  async function submitLogin(event) {
    event.preventDefault();
    event.stopPropagation();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    const resetPasswordRes = await resetPassword(token, password);
    if (resetPasswordRes.success) {
      setPasswordConfirmed(true);
    } else {
      alert("Error al cambiar la contraseña");
      console.log(resetPasswordRes);
    }
  }

  async function handleLogoutEverywhere() {
    if (!loggingOutEverywhere) {
      setLoggingOutEverywhere(true);
      const logoutRes = await logoutEverywhere(token);
      console.log(logoutRes);
      if (logoutRes.success) {
        alert("Sesiones cerradas con éxito");
        navigate("/");
      } else {
        alert("Error al cerrar las sesiones");
      }
    }
  }

  return (
    <div style={{textAlign: "center"}}>
      <img src={mantisLogo} alt="Mantis logo" className="login-mantis-logo" />
      {!passwordConfirmed ? (
        <Form
          noValidate
          onSubmit={(e) => {
            submitLogin(e);
          }}
        >
          <Row
            className={isMobile ? "login-input-row-mobile" : "login-input-row"}
          >
            <Form.Group as={Col}>
              <Form.Label className="login-label">Nueva contraseña</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Nueva contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  left: "40%",
                  top: "-30px"
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            </Form.Group>
          </Row>
          <Row
            className={isMobile ? "login-input-row-mobile" : "login-input-row"}
          >
            <Form.Group as={Col}>
              <Form.Label className="login-label">
                Confirma tu nueva contraseña
              </Form.Label>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirma tu nueva contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <i
                className={
                  showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"
                }
                style={{
                  cursor: "pointer",
                  position: "relative",
                  left: "40%",
                  top: "-30px"
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </Form.Group>
          </Row>
          <Button type="submit" className="reset-button">
            Confirmar
          </Button>
        </Form>
      ) : (
        <>
          <h2>¡Tu contraseña ha sido cambiada con éxito!</h2>
          <p
            style={{
              marginTop: "20px",
              fontSize: "1.2em",
              textAlign: "center"
            }}
          >
            ¡Ya puedes cerrar esta pestaña!
          </p>
          {/* <Button
            onClick={() => handleLogoutEverywhere()}
            className="reset-button"
          >
            {loggingOutEverywhere
              ? "Cerrando sesión..."
              : "Cerrar sesión en todos los dispositivos"}
          </Button> */}
        </>
      )}
    </div>
  );
}

export default ForgotPasswordBusiness;
