import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";

function BelvoDashboard(props) {
  const [dashboardData, setDashBoardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const dashboardRes = await getDashboardData("642c913f85997be1731e328b");
    console.log(dashboardRes);
    if (dashboardRes.success) {
      setDashBoardData(dashboardRes.dashboardData);
    }
    setLoading(false);
  }

  return (
    <>
      <h1>Dashboard Financiero</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col
              xl="8 xl-100"
              className="chart_data_left box-col-12"
              style={{ margin: "auto" }}
            >
              <Card>
                <CardBody className="p-0">
                  <Row className="m-0 chart-main">
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(dashboardData.monthlyOutcome)}
                            </h4>
                            <span>{"Gastos totales"}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(
                                dashboardData.weeklyMeanOutcome
                              )}
                            </h4>
                            <span>{"Gastos semanales promedio"}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="12" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(
                                dashboardData.monthlyMeanOutcome
                              )}
                            </h4>
                            <span>{"Gastos mensuales promedio"}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="12" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(dashboardData.maxOutcome)}
                            </h4>
                            <span>{"Gasto máximo"}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              xl="8 xl-100"
              className="chart_data_left box-col-12"
              style={{ margin: "auto" }}
            >
              <Card>
                <CardBody className="p-0">
                  <Row className="m-0 chart-main">
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(dashboardData.monthlyIncome)}
                            </h4>
                            <span>{"Ingresos totales"} </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(dashboardData.weeklyMeanIncome)}
                            </h4>
                            <span>{"Ingresos semanales promedio"} </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="12" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>
                              {formatCOP.format(
                                dashboardData.monthlyMeanIncome
                              )}
                            </h4>
                            <span>{"Ingresos mensuales promedio"}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl="3" md="12" sm="6" className="p-0 box-col-6">
                      <div className="media align-items-center">
                        <div className="media-body">
                          <div className="right-chart-content">
                            <h4>{formatCOP.format(dashboardData.maxIncome)}</h4>
                            <span>{"Ingreso máximo"}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br />
          <Row>
            <Col sm="12" xl="6" style={{ margin: "auto" }}>
              <Card>
                <CardHeader>
                  <h5>Transacciones </h5>
                </CardHeader>
                <CardBody>
                  <div id="column-chart">
                    <Chart
                      series={[
                        {
                          name: "Ingreso",
                          data: Object.values(
                            dashboardData.incomingTransactionsByDate
                          ),
                        },
                        {
                          name: "Gasto",
                          data: Object.values(
                            dashboardData.outgoingTransactionsByDate
                          ),
                        },
                      ]}
                      options={{
                        chart: {
                          type: "bar",
                          height: 350,
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: "55%",
                            endingShape: "rounded",
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#6212d7", "#f10542", "#51bb25"],
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },
                        xaxis: {
                          categories: Object.keys(
                            dashboardData.outgoingTransactionsByDate
                          ),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        tooltip: {
                          y: {
                            formatter: function (val) {
                              return "$ " + val + " thousands";
                            },
                          },
                        },
                      }}
                      type="bar"
                      height={350}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6" style={{ margin: "auto" }}>
              <Card style={{ height: "450px" }}>
                <CardHeader
                  className="card-no-border"
                  style={{ height: "50px" }}
                >
                  <div className="media">
                    <div className="media-body">
                      <h5 className="f-w-500 font-roboto">Categorías</h5>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="apex-chart">
                  <div id="piechart">
                    <Chart
                      options={{
                        chart: {
                          width: 380,
                          type: "pie",
                          toolbar: {
                            show: true,
                          },
                        },
                        labels: Object.keys(
                          dashboardData.categoriesTransactions
                        ),
                        colors: [
                          "#6212d7",
                          "#f2c600",
                          "#302465",
                          "#e89300",
                          "#a161ff",
                        ],
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                      series={Object.values(
                        dashboardData.categoriesTransactions
                      )}
                      type="pie"
                      width={600}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          {dashboardData.transactions.length > 0 && (
            <Row>
              <Col xs={10} style={{ margin: "auto" }}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Fecha</th>
                          <th>Monto</th>
                          <th>Categoría</th>
                          <th>Tipo</th>
                          <th>Descripción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData.transactions.map(
                          (transaction, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{transaction.value_date}</td>
                                <td>
                                  ${formatMoney(transaction.amount)}{" "}
                                  {transaction.currency || "COP"}
                                </td>
                                <td>{transaction.category}</td>
                                <td>
                                  {transaction.type === "OUTFLOW"
                                    ? "Egreso"
                                    : "Ingreso"}
                                </td>
                                <td>{transaction.description}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}

export default BelvoDashboard;
