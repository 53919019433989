import { backUrl } from "../utils/backURL";

export const verifyEmail = async (verificationId) => {
    const signupReq = await fetch(`${backUrl}/email/verifyEmail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            verificationId
        })
    });
    const signupRes = await signupReq.json();
    return signupRes;
};
