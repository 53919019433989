import React, { useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import background from "./Assets/masterclassBackground.png";
import mobileBackground from "./Assets/masterclassMobileBackground.png";
import "./styles/dataTreatment.scss";
import { backUrl } from "../utils/backURL";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import appStoreButton from "../Home/assets/img/appStoreButton.png";
import playButton from "../Home/assets/img/playButton.png";

export default function PrecreatedUserDownloadMantis() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  //Get info from url params
  const urlParams = new URLSearchParams(window.location.search);
  const userName = urlParams
    .get("userName")
    .replace(/-/g, " ")
    .replace(/164/g, "ñ");
  const businessName = urlParams
    .get("businessName")
    .replace(/-/g, " ")
    .replace(/164/g, "ñ")
    .replace(/YY/g, "&");

  return (
    <div
      style={{
        marginTop: 30,
        width: isMobile ? "100%" : "50%",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <img
        src={mantisLogo}
        alt="Mantis"
        height={isMobile ? 50 : 100}
        style={{
          marginTop: 30,
        }}
      />
      <h2
        style={{
          marginTop: 20,
          fontWeight: "600",
        }}
      >
        ¡Hola {userName}!
      </h2>
      <p
        style={{
          marginTop: 20,
          fontSize: 18,
          fontWeight: "normal",
          width: "80%",
          textAlign: "center",
          margin: "auto",
        }}
      >
        ¡Soy Mantis, un asistente de IA! Voy a ayudarte a guardar y organizar
        los documentos de {businessName} y de otros lugares, inclyendo tu
        correo, si quieres!
      </p>
      <br />
      <div
        style={{
          backgroundColor: "#282828",
          borderRadius: 20,
          width: 40,
          height: 40,
          margin: "auto",
          color: "#F0F53C",
          fontSize: 18,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        1
      </div>
      <br />
      <p
        style={{
          fontSize: 18,
          fontWeight: "normal",
          width: "80%",
          textAlign: "center",
          margin: "auto",
        }}
      >
        Descarga la app
      </p>
      <div style={{ width: "100%", marginTop: "20px" }}>
        <img
          src={appStoreButton}
          alt="Mantis landing"
          style={{
            width: 150,
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(
              "https://apps.apple.com/app/mantis-asistente-virtual/id6449679347"
            );
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <img
          src={playButton}
          alt="Download Google play"
          style={{
            width: 150,
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=co.mantisapp.app"
            );
          }}
        />
      </div>
      <br />
      <div
        style={{
          backgroundColor: "#282828",
          borderRadius: 20,
          width: 40,
          height: 40,
          margin: "auto",
          color: "#F0F53C",
          fontSize: 18,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        2
      </div>
      <br />
      <p
        style={{
          fontSize: 18,
          fontWeight: "normal",
          width: "80%",
          textAlign: "center",
          margin: "auto",
        }}
      >
        Tu cuenta está pre-creada, solo ingresa aceptando los términos y
        condiciones, usa tu cédula y valida tu celular.
      </p>
      {/* <p
        style={{
          marginTop: 30,
          fontSize: 18,
          fontWeight: "normal",
          width: "80%",
          textAlign: "center",
          margin: "auto"
        }}
      >
        Soy un asistente de IA para ayudarte con el manejo de tus documentos,
        toda tu info está protegida y encriptada
      </p> */}
    </div>
  );
}
