import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMantisBusinesses } from "../ApiCalls/backoffice";
import { Spinner, Row, Col, Table, Card, CardBody } from "reactstrap";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

const MantisBusinesses = (props) => {
  const [mantisBusinesses, setMantisBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSysdatec = props.userId === "6663a156c03068303336ab1b";
  const sysdatecBusinessesIds = [
    "6663c56af8106c9a9c3a5a0c",
    "6663c42d8217602d15abe2fe",
    "6663c3008217602d15abe2e2",
  ];

  useEffect(() => {
    fetchMantisBusinesses();
  }, []);

  // Fetch Mantis businesses from API
  const fetchMantisBusinesses = async () => {
    try {
      const response = await getMantisBusinesses(props.token, props.userId);
      if (response.success) {
        setMantisBusinesses(response.mantisBusinesses);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Mantis businesses:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Empresas</h1>
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs={10} style={{ margin: "auto" }}>
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Fecha de creación</th>
                      <th>Email de contacto</th>
                      <th>Teléfono de contacto</th>
                      <th>Nombre de contacto</th>
                      <th>Documento</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mantisBusinesses.map((mantisBusiness) => {
                      if (
                        isSysdatec &&
                        !sysdatecBusinessesIds.includes(mantisBusiness._id)
                      ) {
                        return null;
                      }
                      return (
                        <tr key={mantisBusiness._id}>
                          <td>{mantisBusiness.name}</td>
                          <td>
                            {new Date(
                              mantisBusiness.dateCreated
                            ).toLocaleDateString("es")}
                          </td>
                          <td>{mantisBusiness.contactEmail}</td>
                          <td>{mantisBusiness.contactPhone}</td>
                          <td>
                            {mantisBusiness.contactFirstName}{" "}
                            {mantisBusiness.contactLastName}
                          </td>
                          <td>
                            {mantisBusiness.identificationType}{" "}
                            {mantisBusiness.identificationNumber}
                          </td>
                          <td
                            onClick={() =>
                              window.open(
                                `/backoffice/mantisBusinesses/${mantisBusiness._id}`,
                                "rel=noopener noreferrer"
                              )
                            }
                          >
                            <i
                              className="fas fa-eye"
                              style={{ fontSize: "1em", cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default connect(select)(MantisBusinesses);
