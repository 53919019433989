import React, {useState} from "react";
import {Spinner, Modal, Col, Row} from "react-bootstrap";
//import landingImage1 from "./assets/img/LandingPage1.png";
import landingImage1 from "./assets/img/V2LandingPage1.png";
import landingImage2 from "./assets/img/LandingPage2.png";
//import landingImageMobile1 from "./assets/img/LandingPageMobile1.png";
import landingImageMobile1 from "./assets/img/V2LandingPageMobile1.png";
import landingImageMobile2 from "./assets/img/V2LandingPageMobile2.png";
import mantisBlackLogo from "../Assets/img/mantisBlackLogo.png";
import appStoreButton from "./assets/img/appStoreButton.png";
import playButton from "./assets/img/playButton.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./homepage.scss";
import {backUrl} from "../utils/backURL";

function Homepage(props) {
  const [cellphone, setCellphone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleWaitlist = async () => {
    setLoading(true);
    await fetch(`${backUrl}/api/waitlistContacts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        cellphone
      })
    });

    setShowSuccessModal(true);

    setLoading(false);
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      <div
        style={{
          backgroundColor: isMobile ? "white" : "#282828",
          textAlign: "center"
        }}
      >
        <Row
          style={{
            backgroundColor: "white",
            height: 100,
            width: "100%",
            maxWidth: "1200px",
            margin: "auto",
            textAlign: "left"
          }}
        >
          <Col xs={6}>
            <img
              src={mantisBlackLogo}
              alt="Mantis landing"
              style={
                !isMobile
                  ? {
                      width: 200,
                      paddingTop: 20,
                      marginLeft: 50
                    }
                  : {
                      width: 150,
                      paddingTop: 20,
                      marginLeft: 20
                    }
              }
            />
          </Col>
          {!isMobile && (
            <Col xs={6}>
              <Row
                style={{
                  marginLeft: 50
                }}
              >
                <Col
                  xs={4}
                  style={{
                    textAlign: "center"
                  }}
                >
                  <img
                    src={appStoreButton}
                    alt="Mantis landing"
                    style={{
                      width: 150,
                      paddingTop: 20,
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/app/mantis-asistente-virtual/id6449679347"
                      );
                    }}
                  />
                </Col>
                <Col
                  xs={4}
                  style={{
                    textAlign: "center"
                  }}
                >
                  <img
                    src={playButton}
                    alt="Download Google play"
                    style={{
                      width: 150,
                      paddingTop: 20,
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=co.mantisapp.app"
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <img
          src={isMobile ? landingImageMobile1 : landingImage1}
          alt="Mantis landing"
          style={{
            width: "100%",
            maxWidth: "1200px",
            textAlign: "center"
          }}
        />
        {isMobile && (
          <>
            {/* <div
              style={{
                backgroundColor: "#282828",
                width: 200,
                height: 50,
                margin: "auto",
                color: "white",
                lineHeight: "50px",
                textAlign: "center",
                borderRadius: 10,
                marginTop: 20
              }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=co.mantisapp.app"
                );
              }}
            >
              Descarga la App
            </div> */}
            <Row>
              <Col
                xs={6}
                style={{
                  textAlign: "center"
                }}
              >
                <img
                  src={appStoreButton}
                  alt="Mantis landing"
                  style={{
                    width: 150,
                    paddingTop: 20,
                    marginLeft: 20
                  }}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/app/mantis-asistente-virtual/id6449679347"
                    );
                  }}
                />
              </Col>
              <Col
                xs={6}
                style={{
                  textAlign: "center"
                }}
              >
                <img
                  src={playButton}
                  alt="Download Google play"
                  style={{
                    width: 150,
                    paddingTop: 20,
                    cursor: "pointer",
                    marginRight: 20
                  }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=co.mantisapp.app"
                    );
                  }}
                />
              </Col>
            </Row>
            <img
              src={landingImageMobile2}
              alt="Mantis landing"
              style={{
                width: "100%",
                maxWidth: "1200px",
                textAlign: "center"
              }}
            />
          </>
        )}

        {/* <PhoneInput
          country="co"
          name="cellphone"
          style={{width: isMobile ? "80%" : "20%", margin: "auto"}}
          enableSearch={true}
          value={cellphone}
          onChange={(e) => {
            setCellphone(e);
          }}
        />
        <div
          style={{
            width: isMobile ? "50%" : "15%",
            height: "40px",
            margin: "auto",
            backgroundColor: "#023047",
            borderRadius: "30px",
            color: "white",
            paddingTop: 6,
            marginTop: 5,
            cursor: "pointer"
          }}
          onClick={() => {
            handleWaitlist();
          }}
        >
          {loading ? <Spinner size="sm" /> : "Lista de Espera"}
        </div> */}
        {/* <img
          src={isMobile ? landingImageMobile2 : landingImage2}
          alt="Mantis landing"
          style={{width: "100%", maxWidth: "1200px"}}
        /> */}
        <Row
          style={{
            backgroundColor: "white",
            height: 250,
            width: "100%",
            maxWidth: "1200px",
            margin: "auto",
            textAlign: "left"
          }}
        >
          {!isMobile && <Col xs={1}></Col>}
          {!isMobile && (
            <Col xs={5}>
              <h3
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: 600
                }}
              >
                Descarga la App
              </h3>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: 500
                }}
              >
                Puedes tener un asistente para tu día a día, olvídate del estrés
                de buscar documentos, hacer pagos y preocuparte por tus
                finanzas. ¡Mantis lo hace por ti!
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginTop: 20
                }}
                onClick={() => {
                  window.open("https://mantisapp.co/docs/dataTreatment");
                }}
              >
                Política de tratamiento de datos
              </p>
            </Col>
          )}

          {isMobile && (
            <Col
              xs={12}
              style={{
                textAlign: "center",
                marginTop: 20
              }}
            >
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => {
                  window.open("https://mantisapp.co/docs/dataTreatment");
                }}
              >
                Política de tratamiento de datos
              </p>
            </Col>
          )}
          <Col
            xs={!isMobile ? 2 : 12}
            style={{
              textAlign: "center"
            }}
          >
            <img
              src={appStoreButton}
              alt="Mantis landing"
              style={{
                width: 150,
                paddingTop: 20,
                cursor: "pointer"
              }}
              onClick={() => {
                window.open(
                  "https://apps.apple.com/app/mantis-asistente-virtual/id6449679347"
                );
              }}
            />
          </Col>
          <Col
            xs={!isMobile ? 2 : 12}
            style={{
              textAlign: "center"
            }}
          >
            <img
              src={playButton}
              alt="Download Google play"
              style={{
                width: 150,
                paddingTop: 20,
                cursor: "pointer"
              }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=co.mantisapp.app"
                );
              }}
            />
          </Col>
          <div
            style={{
              width: "90%",
              margin: "auto"
            }}
          >
            <hr />
          </div>
          <img
            src={mantisBlackLogo}
            alt="Mantis landing"
            style={{
              width: 200,
              height: 50,
              resizeMode: "contain",
              marginLeft: 20
            }}
          />
        </Row>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: 20,
            fontWeight: 600,
            color: "#023047"
          }}
        >
          Ya estás registrado para tener las más recientes actualizaciones de
          Mantis! Pronto podrás dejar de cargar con el peso de tus finanzas y
          lograr tus metas!
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Homepage;
