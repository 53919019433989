import {backUrl} from "../utils/backURL";
import Cookies from "js-cookie";

export const getUsers = async (requesterId, token) => {
  const req = await fetch(`${backUrl}/users/getUsers/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "X-CSRF-TOKEN": Cookies.get("csrfToken")
    },
    body: JSON.stringify({
      requesterId
    })
  });
  const res = await req.json();
  return res;
};

export const getUser = async (requesterId, token, userId) => {
  const req = await fetch(`${backUrl}/users/getById`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      userId
    })
  });
  const res = await req.json();
  return res;
};

export const getLastFinancialState = async (userId, token) => {
  const req = await fetch(
    `${backUrl}/financialStates/getUserLatestFinancialState/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        userId
      })
    }
  );
  const res = await req.json();
  return res;
};

export const getUserMantisBalance = async (userId, token) => {
  const req = await fetch(`${backUrl}/users/getMantisBalance/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      userId
    })
  });
  const res = await req.json();
  return res;
};
