import { backUrl } from "../utils/backURL";

export const uploadImage = async (formData) => {
  const uploadReq = await fetch(`${backUrl}/fileUpload/uploadImage`, {
    method: "POST",
    body: formData,
  });
  const uploadRes = await uploadReq.json();
  return uploadRes;
};

export const uploadFile = async (formData) => {
  const uploadReq = await fetch(`${backUrl}/fileUpload/uploadFile`, {
    method: "POST",
    body: formData,
  });
  const uploadRes = await uploadReq.json();
  return uploadRes;
};
