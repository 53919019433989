import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import "./styles/styles.scss";

class NavbarComponent extends Component {
  render() {
    return (
      <Navbar>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={require("../Assets/img/mantisWhiteLogo2.png")}
            className="d-inline-block align-top"
            height={45}
            style={{ paddingLeft: 20 }}
          />
        </Navbar.Brand>
      </Navbar>
    );
  }
}

export default NavbarComponent;
