import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { token } from "morgan";
import {
  getTpagaProviders,
  getTpagaBalance,
  payTpagaBill,
  queryTpagaBill,
  getStartcoConversations,
  switchStartcoConversationMode,
  sendStartcoManualMessage,
  getStartcoConversation,
} from "../ApiCalls/backoffice";
import { backUrl } from "../utils/backURL";
import { Buffer } from "buffer";
import { shortenText } from "../utils/formatText";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

const startingMessage = `¡Me encanta que estés en Startco! Definitivamente, es uno de los eventos más emocionantes aquí en Colombia`;

function StartcoConversations(props) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [switchingModes, setSwitchingModes] = useState(false);
  const [manualMessage, setManualMessage] = useState("");
  const [sendingManualMessage, setSendingManualMessage] = useState(false);
  const [updatingConversation, setUpdatingConversation] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const conversationsRes = await getStartcoConversations(
      props.token,
      props.userId
    );
    if (conversationsRes.success) {
      setConversations(conversationsRes.conversations);
    }
    setLoading(false);
  };

  const updateConversation = async () => {
    if (!updatingConversation) {
      setUpdatingConversation(true);
      const res = await getStartcoConversation(
        props.token,
        props.userId,
        selectedConversation._id
      );
      if (res.success) {
        setSelectedConversation(res.conversation);
      }
      setUpdatingConversation(false);
    }
  };

  const handleSwitchMode = async () => {
    if (!switchingModes) {
      setSwitchingModes(true);
      const res = await switchStartcoConversationMode(
        props.token,
        props.userId,
        selectedConversation._id
      );
      if (res.success) {
        setSelectedConversation(res.conversation);
      }
      setSwitchingModes(false);
    }
  };

  const handleSendManualMessage = async () => {
    setSendingManualMessage(true);
    let conversationCopy = { ...selectedConversation };
    conversationCopy.responses.push({
      message: manualMessage,
      sender: "Mantis",
      timestamp: new Date(),
    });
    setSelectedConversation(conversationCopy);
    const res = await sendStartcoManualMessage(
      props.token,
      props.userId,
      selectedConversation._id,
      manualMessage
    );
    if (res.success) {
      setManualMessage("");
      setSelectedConversation(res.conversation);
    } else {
      alert("Error al enviar mensaje");
    }
    setSendingManualMessage(false);
  };

  return (
    <>
      <Row>
        <Col xs={10} style={{ margin: "auto" }}>
          <h1>Conversaciones Startco</h1>
          <br />
          {loading ? (
            <Spinner />
          ) : (
            <>
              {!selectedConversation ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Teléfono</th>
                      <th>Fecha de creación</th>
                      <th>Último mensaje</th>
                      <th>Último mensaje por</th>
                      <th>Controlado por</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conversations.map((conversation, index) => (
                      <tr
                        key={index}
                        onClick={() => setSelectedConversation(conversation)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <td>{conversation.recipientCellphone}</td>
                        <td>
                          {new Date(
                            conversation.dateCreated
                          ).toLocaleDateString("es")}
                        </td>
                        <td>
                          {conversation.responses[
                            conversation.responses.length - 1
                          ].message.startsWith(startingMessage)
                            ? "-"
                            : shortenText(
                                conversation.responses[
                                  conversation.responses.length - 1
                                ].message || "-",
                                30
                              )}
                        </td>
                        <td>
                          {
                            conversation.responses[
                              conversation.responses.length - 1
                            ].sender
                          }
                        </td>
                        <td>{conversation.aiControlled ? "AI" : "Manual"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <>
                  <h2>
                    Conversación con {selectedConversation.recipientCellphone}
                  </h2>
                  <br />
                  <Row>
                    <Col xs={4}>
                      <h4
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedConversation(null)}
                      >
                        {"< Volver"}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={6}
                      style={{
                        margin: "auto",
                      }}
                    >
                      {selectedConversation.responses.map((response, index) => {
                        if (response.sender === "Mantis") {
                          return (
                            <div
                              key={index}
                              style={{ textAlign: "right", marginLeft: 20 }}
                            >
                              <p
                                style={{
                                  backgroundColor: "lightgray",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  display: "inline-block",
                                }}
                              >
                                {response.message}
                                <p
                                  style={{
                                    fontSize: "10px",
                                    margin: "0",
                                    textAlign: "right",
                                  }}
                                >
                                  {new Date(
                                    response.timestamp
                                  ).toLocaleDateString("es")}
                                  {", "}
                                  {new Date(
                                    response.timestamp
                                  ).toLocaleTimeString("es")}
                                </p>
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={index}
                              style={{ textAlign: "left", marginRight: 20 }}
                            >
                              <p
                                style={{
                                  backgroundColor: "lightblue",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  display: "inline-block",
                                }}
                              >
                                {response.message}
                                <p
                                  style={{
                                    fontSize: "10px",
                                    margin: "0",
                                  }}
                                >
                                  {new Date(
                                    response.timestamp
                                  ).toLocaleDateString("es")}
                                  {", "}
                                  {new Date(
                                    response.timestamp
                                  ).toLocaleTimeString("es")}
                                </p>
                              </p>
                            </div>
                          );
                        }
                      })}
                      <div
                        style={{
                          height: 150,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <div
                    style={{
                      position: "fixed",
                      bottom: 20,
                      right: 200,
                    }}
                  >
                    <Button
                      color="primary"
                      style={{ marginTop: 20 }}
                      onClick={handleSwitchMode}
                    >
                      {switchingModes ? (
                        <Spinner />
                      ) : (
                        <>
                          {" "}
                          {!Object.keys(selectedConversation).includes(
                            "aiControlled"
                          ) || selectedConversation.aiControlled === true
                            ? "Responder"
                            : "Volver a AI"}
                        </>
                      )}
                    </Button>
                  </div>
                  <div
                    style={{
                      position: "fixed",
                      bottom: 20,
                      left: 200,
                    }}
                  >
                    <Button
                      color="primary"
                      style={{ marginTop: 20 }}
                      onClick={updateConversation}
                    >
                      {updatingConversation ? <Spinner /> : "Actualizar"}
                    </Button>
                  </div>
                  {!selectedConversation.aiControlled && (
                    <Row
                      style={{
                        position: "fixed",
                        bottom: 20,
                        right: "40%",
                        width: "20%",
                      }}
                    >
                      <Col xs={12}>
                        <Input
                          type="text"
                          name="manualMessage"
                          id="manualMessage"
                          value={manualMessage}
                          onChange={(e) => setManualMessage(e.target.value)}
                        />
                        <Button
                          color="primary"
                          style={{ marginTop: 20 }}
                          onClick={handleSendManualMessage}
                        >
                          {sendingManualMessage ? <Spinner /> : "Enviar"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default connect(select)(StartcoConversations);
