import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { token } from "morgan";
import { getMantisParams, updateMantisParams } from "../ApiCalls/backoffice";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

function PlanCalculator(props) {
  const [params, setParams] = useState({});
  const [collectionQuantity, setCollectionQuantity] = useState(0);
  const [fintechQuantity, setFintechQuantity] = useState(0);
  const [serviceQuantity, setServiceQuantity] = useState(0);
  const [transferQuantity, setTransferQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [savingParams, setSavingParams] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const paramsRes = await getMantisParams(props.token);

    if (paramsRes.success) {
      setParams(paramsRes.params);
    }
    setLoading(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "collectionCost") {
      setParams((prevState) => ({
        ...prevState,
        collectionPrice: `${
          parseFloat(value) +
            parseFloat(value) *
              parseFloat(params.collectionRevenuePercentage) || 0
        }`,
      }));
    }
    if (name === "fintechAutomationCost") {
      setParams((prevState) => ({
        ...prevState,
        fintechPrice: `${
          parseFloat(value) +
            parseFloat(value) * parseFloat(params.fintechRevenuePercentage) || 0
        }`,
      }));
    }
    if (name === "serviceAutomationCost") {
      setParams((prevState) => ({
        ...prevState,
        servicePrice: `${
          parseFloat(value) +
            parseFloat(value) * parseFloat(params.serviceRevenuePercentage) || 0
        }`,
      }));
    }
    if (name === "transferAutomationCost") {
      setParams((prevState) => ({
        ...prevState,
        transferPrice: `${
          parseFloat(value) +
            parseFloat(value) * parseFloat(params.transferRevenuePercentage) ||
          0
        }`,
      }));
    }
    if (name === "collectionRevenuePercentage") {
      setParams((prevState) => ({
        ...prevState,
        collectionPrice: `${
          parseFloat(params.collectionCost) +
            parseFloat(params.collectionCost) * parseFloat(value) || 0
        }`,
      }));
    }
    if (name === "fintechRevenuePercentage") {
      setParams((prevState) => ({
        ...prevState,
        fintechPrice: `${
          parseFloat(params.fintechAutomationCost) +
            parseFloat(params.fintechAutomationCost) * parseFloat(value) || 0
        }`,
      }));
    }
    if (name === "serviceRevenuePercentage") {
      setParams((prevState) => ({
        ...prevState,
        servicePrice: `${
          parseFloat(params.serviceAutomationCost) +
            parseFloat(params.serviceAutomationCost) * parseFloat(value) || 0
        }`,
      }));
    }
    if (name === "transferRevenuePercentage") {
      setParams((prevState) => ({
        ...prevState,
        transferPrice: `${
          parseFloat(params.transferAutomationCost) +
            parseFloat(params.transferAutomationCost) * parseFloat(value) || 0
        }`,
      }));
    }
  };

  async function handleSaveParams() {
    setSavingParams(true);
    await updateMantisParams(props.token, props.userId, params);
    setSavingParams(false);
  }

  return (
    <>
      <h1>Calculadora Mantis</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col xs={10} style={{ margin: "auto" }}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Tipo de dispersión</th>
                        <th>Tipo</th>
                        <th>Costo</th>
                        <th>Ganancia</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Recaudo</td>
                        <td>Único</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="collectionCost"
                              id="collectionCost"
                              value={params.collectionCost}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="collectionRevenuePercentage"
                              id="collectionRevenuePercentage"
                              value={params.collectionRevenuePercentage}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>${formatMoney(params.collectionPrice)}</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="collectionQuantity"
                              id="collectionQuantity"
                              value={collectionQuantity}
                              onChange={(e) =>
                                setCollectionQuantity(e.target.value)
                              }
                            />
                          </FormGroup>
                        </td>
                        <td>
                          $
                          {formatMoney(
                            parseInt(collectionQuantity) *
                              parseFloat(params.collectionPrice) || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Fintech</td>
                        <td>Mensual</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="fintechAutomationCost"
                              id="fintechAutomationCost"
                              value={params.fintechAutomationCost}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="fintechRevenuePercentage"
                              id="fintechRevenuePercentage"
                              value={params.fintechRevenuePercentage}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>${formatMoney(params.fintechPrice)}</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="fintechQuantity"
                              id="fintechQuantity"
                              value={fintechQuantity}
                              onChange={(e) =>
                                setFintechQuantity(e.target.value)
                              }
                            />
                          </FormGroup>
                        </td>
                        <td>
                          $
                          {formatMoney(
                            parseInt(fintechQuantity) *
                              parseFloat(params.fintechPrice) || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Servicio</td>
                        <td>Mensual</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="serviceAutomationCost"
                              id="serviceAutomationCost"
                              value={params.serviceAutomationCost}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="serviceRevenuePercentage"
                              id="serviceRevenuePercentage"
                              value={params.serviceRevenuePercentage}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>${formatMoney(params.servicePrice)}</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="serviceQuantity"
                              id="serviceQuantity"
                              value={serviceQuantity}
                              onChange={(e) =>
                                setServiceQuantity(e.target.value)
                              }
                            />
                          </FormGroup>
                        </td>
                        <td>
                          $
                          {formatMoney(
                            parseInt(serviceQuantity) *
                              parseFloat(params.servicePrice) || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Transferencias</td>
                        <td>Mensual</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="transferAutomationCost"
                              id="transferAutomationCost"
                              value={params.transferAutomationCost}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="transferRevenuePercentage"
                              id="transferRevenuePercentage"
                              value={params.transferRevenuePercentage}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </td>
                        <td>${formatMoney(params.transferPrice)}</td>
                        <td>
                          <FormGroup>
                            <Input
                              type="text"
                              name="transferQuantity"
                              id="transferQuantity"
                              value={transferQuantity}
                              onChange={(e) =>
                                setTransferQuantity(e.target.value)
                              }
                            />
                          </FormGroup>
                        </td>
                        <td>
                          $
                          {formatMoney(
                            parseInt(transferQuantity) *
                              parseFloat(params.transferPrice) || 0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col xs={4} style={{ marginTop: 20 }}>
              <h4>
                Total: $
                {formatMoney(
                  parseInt(fintechQuantity) * parseFloat(params.fintechPrice) +
                    parseInt(collectionQuantity) *
                      parseFloat(params.collectionPrice) +
                    parseInt(serviceQuantity) *
                      parseFloat(params.servicePrice) +
                    parseInt(transferQuantity) *
                      parseFloat(params.transferPrice) || 0
                )}
              </h4>
            </Col>
            <Col xs={4} style={{ marginTop: 20 }}>
              <Button onClick={handleSaveParams} disabled={savingParams}>
                {savingParams ? <Spinner size={"sm"} /> : "Guardar parámetros"}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default connect(select)(PlanCalculator);
