import React, {useState, useEffect} from "react";
import {getDashboardData} from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  InputGroup
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {token} from "morgan";
import {
  getMoneyOnMandateComponents,
  approveMoneyOnMandateComponent,
  declineMoneyOnMandateComponent,
  getUserRecurringPayments,
  getUserBalance,
  getMantisFee,
  createMoneyOnMandate
} from "../ApiCalls/backoffice";
import {getMonthDiff} from "../utils/dateUtils";
import {uploadFile, uploadImage} from "../ApiCalls/uploadFiles";
import UserSearch from "../Extras/Search/UserSearch";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function MoneyOnMandates(props) {
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [pendingMoneyOnMandates, setPendingMoneyOnMandates] = useState([]);
  const [approvedMoneyOnMandates, setApprovedMoneyOnMandates] = useState([]);
  const [paymentToApprove, setPaymentToApprove] = useState({});
  const [paymentToDecline, setPaymentToDecline] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const [updatingPayment, setUpdatingPayment] = useState(false);
  const [decliningPayment, setDecliningPayment] = useState(false);

  //Create money on mandate
  const [showCreateMoneyOnMandateModal, setShowCreateMoneyOnMandateModal] =
    useState(false);
  const [createMoneyOnMandateStep, setCreateMoneyOnMandateStep] = useState(1);
  const [creatingMoneyOnMandate, setCreatingMoneyOnMandate] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [fetchingRecurringPayments, setFetchingRecurringPayments] =
    useState(false);
  const [userRecurringPayments, setUserRecurringPayments] = useState([]);
  const [selectedRecurringPayments, setSelectedRecurringPayments] = useState(
    []
  );
  const [fetchingUserBalance, setFetchingUserBalance] = useState(false);
  const [userBalance, setUserBalance] = useState(null);
  const [fetchingMantisFee, setFetchingMantisFee] = useState(false);
  const [mantisFee, setMantisFee] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  function closeCreateMoneyOnMandateModal() {
    if (
      !creatingMoneyOnMandate &&
      !fetchingRecurringPayments &&
      !fetchingUserBalance &&
      !fetchingMantisFee
    ) {
      setShowCreateMoneyOnMandateModal(false);
      setCreateMoneyOnMandateStep(1);
      setUserSearchTerm("");
      setSelectedUser(null);
      setUserRecurringPayments([]);
      setSelectedRecurringPayments([]);
      setUserBalance(null);
      setMantisFee(0);
      setPaidAmount(0);
    }
  }

  async function fetchUserRecurringPayments() {
    setFetchingRecurringPayments(true);
    const userRecurringPaymentsRequest = await getUserRecurringPayments(
      props.token,
      props.userId,
      selectedUser._id
    );
    if (userRecurringPaymentsRequest.success) {
      setUserRecurringPayments(userRecurringPaymentsRequest.recurringPayments);
    }
    setFetchingRecurringPayments(false);
  }

  async function fetchUserBalance() {
    setFetchingUserBalance(true);
    const userBalanceRequest = await getUserBalance(
      props.token,
      props.userId,
      selectedUser._id
    );
    if (userBalanceRequest.success) {
      setUserBalance(userBalanceRequest.mantisBalance);
    }
    setFetchingUserBalance(false);
  }

  async function fetchMantisFee() {
    setFetchingMantisFee(true);
    const mantisFeeRequest = await getMantisFee(
      props.token,
      props.userId,
      selectedRecurringPayments,
      selectedUser._id
    );
    if (mantisFeeRequest.success) {
      setMantisFee(mantisFeeRequest.mantisFee);
    }
    setFetchingMantisFee(false);
  }

  async function handleCreateMoneyOnMandate() {
    setCreatingMoneyOnMandate(true);
    const createMoneyOnMandateRequest = await createMoneyOnMandate(
      props.token,
      props.userId,
      selectedUser._id,
      paidAmount,
      selectedRecurringPayments.map((payment) => payment._id),
      selectedRecurringPayments.reduce(
        (acc, payment) => acc + payment.amount,
        0
      ),
      mantisFee
    );
    if (createMoneyOnMandateRequest.success) {
      setAlertMessage("Pago de mandato creado correctamente");
    }
    closeCreateMoneyOnMandateModal();
  }

  async function getData() {
    const moneyOnMandateRequest = await getMoneyOnMandateComponents(
      props.token,
      props.userId
    );
    if (moneyOnMandateRequest.success) {
      setPendingMoneyOnMandates(moneyOnMandateRequest.pendingComponents);
      setApprovedMoneyOnMandates(moneyOnMandateRequest.approvedComponents);
    }
    setLoading(false);
  }

  async function handleApprovePayment(paymentId) {
    setUpdatingPayment(true);
    const approvePaymentRequest = await approveMoneyOnMandateComponent(
      props.token,
      props.userId,
      paymentId
    );
    if (approvePaymentRequest.success) {
      setAlertMessage("Pago aprobado correctamente");
      //Filter out the approved payment
      const pendingPaymentsCopy = [...pendingMoneyOnMandates];
      const approvedPaymentsCopy = [...approvedMoneyOnMandates];
      const approvedPayment = pendingPaymentsCopy.filter((payment) => {
        return payment._id === paymentId;
      })[0];
      pendingPaymentsCopy.splice(
        pendingPaymentsCopy.indexOf(approvedPayment),
        1
      );
      approvedPaymentsCopy.push(approvedPayment);
      setPendingMoneyOnMandates(pendingPaymentsCopy);
      setApprovedMoneyOnMandates(approvedPaymentsCopy);
      setShowConfirmModal(false);
    } else {
      setAlertMessage("Hubo un error al aprobar el pago");
    }
    setUpdatingPayment(false);
  }

  async function handleDeclinePayment(paymentId) {
    setDecliningPayment(true);
    const declinePaymentRequest = await declineMoneyOnMandateComponent(
      props.token,
      props.userId,
      paymentId,
      declineReason
    );
    if (declinePaymentRequest.success) {
      setAlertMessage("Pago declinado correctamente");
      //Filter out the declined payment
      const pendingPaymentsCopy = [...pendingMoneyOnMandates];
      const declinedPayment = pendingPaymentsCopy.filter((payment) => {
        return payment._id === paymentId;
      })[0];
      pendingPaymentsCopy.splice(
        pendingPaymentsCopy.indexOf(declinedPayment),
        1
      );
      setPendingMoneyOnMandates(pendingPaymentsCopy);
      setShowDeclineModal(false);
      setDeclineReason("");
    } else {
      setAlertMessage("Hubo un error al declinar el pago");
    }
    setDecliningPayment(false);
  }

  return (
    <div style={{paddingTop: 20, paddingBottom: 20}}>
      <h1>Pagos de mandato</h1>
      <br />
      <Row>
        <Col xs={6}>
          <Button
            color="primary"
            onClick={() => {
              setShowCreateMoneyOnMandateModal(true);
            }}
          >
            Crear pago de mandato
          </Button>
        </Col>
      </Row>
      {alertMessage && (
        <Row>
          <Col xs={6} style={{margin: "auto", marginTop: 50}}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {pendingMoneyOnMandates.length > 0 && (
            <Row>
              <h2>Pagos pendientes</h2>
              <Col xs={10} style={{margin: "auto"}}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Nombre</th>
                          <th>Fecha de creación</th>
                          <th>Monto</th>
                          <th>Aprobar</th>
                          <th>Declinar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingMoneyOnMandates.map((payment, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {payment.user.firstName} {payment.user.lastName}
                              </td>
                              <td>
                                {new Date(
                                  payment.dateCreated
                                ).toLocaleDateString("es")}
                              </td>
                              <td>${formatMoney(payment.amount)}</td>
                              <td
                                onClick={() => {
                                  setPaymentToApprove(payment);
                                  setShowConfirmModal(true);
                                }}
                              >
                                <i
                                  className="fas fa-check-circle"
                                  style={{fontSize: "1em", cursor: "pointer"}}
                                />
                              </td>
                              <td
                                onClick={() => {
                                  setPaymentToDecline(payment);
                                  setShowDeclineModal(true);
                                  setDeclineReason("");
                                }}
                              >
                                <i
                                  className="fas fa-times-circle"
                                  style={{fontSize: "1em", cursor: "pointer"}}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <hr />
          {approvedMoneyOnMandates.length > 0 && (
            <Row>
              <h2>Pagos aprobados</h2>
              <Col xs={10} style={{margin: "auto"}}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Nombre</th>
                          <th>Fecha de creación</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvedMoneyOnMandates.map((payment, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {payment.user.firstName} {payment.user.lastName}
                              </td>
                              <td>
                                {new Date(
                                  payment.dateCreated
                                ).toLocaleDateString("es")}
                              </td>
                              <td>${formatMoney(payment.amount)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {showConfirmModal && (
            <Modal
              isOpen={showConfirmModal}
              toggle={() => {
                if (showConfirmModal && !updatingPayment) {
                  setShowConfirmModal(false);
                }
              }}
            >
              <ModalHeader
                toggle={() => {
                  if (showConfirmModal && !updatingPayment) {
                    setShowConfirmModal(false);
                  }
                }}
              >
                Estás seguro que quieres aprobar el pago de $
                {formatMoney(paymentToApprove.amount)} a{" "}
                {paymentToApprove.user.firstName}{" "}
                {paymentToApprove.user.lastName}?
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={12} style={{margin: "auto"}}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleApprovePayment(paymentToApprove._id);
                      }}
                    >
                      {updatingPayment ? <Spinner size={"sm"} /> : "Aprobar"}
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          )}
          {showDeclineModal && (
            <Modal
              isOpen={showDeclineModal}
              toggle={() => {
                if (showDeclineModal && !decliningPayment) {
                  setShowDeclineModal(false);
                }
              }}
            >
              <ModalHeader
                toggle={() => {
                  if (showDeclineModal && !decliningPayment) {
                    setShowDeclineModal(false);
                  }
                }}
              >
                Estás seguro que quieres declinar el pago de $
                {formatMoney(paymentToDecline.amount)} a{" "}
                {paymentToDecline.user.firstName}{" "}
                {paymentToDecline.user.lastName}?
              </ModalHeader>
              <ModalBody>
                <Row>
                  <FormGroup>
                    <Label for="declineReason">Razón de la declinación:</Label>
                    <Input
                      type="text"
                      name="declineReason"
                      id="declineReason"
                      value={declineReason}
                      onChange={(e) => {
                        setDeclineReason(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <Col xs={12} style={{margin: "auto"}}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleDeclinePayment(paymentToDecline._id);
                      }}
                    >
                      {decliningPayment ? <Spinner size={"sm"} /> : "Declinar"}
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          )}
          {showCreateMoneyOnMandateModal && (
            <Modal
              isOpen={showCreateMoneyOnMandateModal}
              toggle={() => {
                closeCreateMoneyOnMandateModal();
              }}
            >
              <ModalHeader
                toggle={() => {
                  closeCreateMoneyOnMandateModal();
                }}
              >
                {createMoneyOnMandateStep === 1 && <h4>Buscar usuario</h4>}
                {createMoneyOnMandateStep === 2 && (
                  <h4>Selecciona los pagos</h4>
                )}
                {createMoneyOnMandateStep === 3 && <h4>Monto pagado</h4>}
                {createMoneyOnMandateStep === 4 && <h4>Resumen</h4>}
              </ModalHeader>
              <ModalBody>
                {createMoneyOnMandateStep === 1 && (
                  <UserSearch
                    selectUser={(user) => {
                      setSelectedUser(user);
                    }}
                  />
                )}
                {createMoneyOnMandateStep === 2 && (
                  <>
                    {fetchingRecurringPayments ? (
                      <Spinner />
                    ) : (
                      <>
                        <FormGroup>
                          {userRecurringPayments.map(
                            (recurringPayment, index) => {
                              return (
                                <FormGroup check key={index}>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={selectedRecurringPayments
                                        .map((payment) => {
                                          return payment._id;
                                        })
                                        .includes(recurringPayment._id)}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedRecurringPayments([
                                            ...selectedRecurringPayments,
                                            recurringPayment
                                          ]);
                                        } else {
                                          setSelectedRecurringPayments(
                                            selectedRecurringPayments.filter(
                                              (payment) =>
                                                payment._id !==
                                                recurringPayment._id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                    {recurringPayment.icon}{" "}
                                    {recurringPayment.name} - $
                                    {formatMoney(recurringPayment.amount)}
                                  </Label>
                                </FormGroup>
                              );
                            }
                          )}
                        </FormGroup>
                      </>
                    )}
                  </>
                )}
                {createMoneyOnMandateStep === 3 && (
                  <>
                    {fetchingMantisFee || fetchingUserBalance ? (
                      <Spinner />
                    ) : (
                      <>
                        <FormGroup>
                          <Input
                            type="number"
                            name="paidAmount"
                            id="paidAmount"
                            value={paidAmount}
                            onChange={(e) => {
                              setPaidAmount(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </>
                    )}
                  </>
                )}
                {createMoneyOnMandateStep === 4 && (
                  <>
                    <Row>
                      <Col xs={12}>
                        <h5>Usuario:</h5>
                        <p>
                          {selectedUser.firstName} {selectedUser.lastName} (
                          {selectedUser.email})
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>Pagos seleccionados:</h5>
                        {selectedRecurringPayments.map((payment, index) => {
                          return (
                            <p key={index}>
                              {payment.icon} {payment.name} - $
                              {formatMoney(payment.amount)}
                            </p>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>Monto pagado:</h5>
                        <p>${formatMoney(paidAmount)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>Saldo del usuario:</h5>
                        <p>
                          {userBalance.sign === "POSITIVE" ? "" : "-"}$
                          {formatMoney(userBalance.balance)}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>Comisión de Mantis:</h5>
                        <p>${formatMoney(mantisFee)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>Saldo final:</h5>
                        <p>
                          $
                          {userBalance.sign === "POSITIVE"
                            ? formatMoney(
                                paidAmount -
                                  (selectedRecurringPayments.reduce(
                                    (acc, payment) => acc + payment.amount,
                                    0
                                  ) +
                                    mantisFee -
                                    userBalance.balance)
                              )
                            : formatMoney(
                                paidAmount -
                                  (selectedRecurringPayments.reduce(
                                    (acc, payment) => acc + payment.amount,
                                    0
                                  ) +
                                    mantisFee +
                                    userBalance.balance)
                              )}
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                {createMoneyOnMandateStep > 1 && (
                  <Button
                    color="secondary"
                    onClick={() => {
                      setCreateMoneyOnMandateStep(createMoneyOnMandateStep - 1);
                    }}
                  >
                    Atrás
                  </Button>
                )}
                {createMoneyOnMandateStep === 1 && selectedUser && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setCreateMoneyOnMandateStep(2);
                      fetchUserRecurringPayments();
                    }}
                  >
                    Siguiente
                  </Button>
                )}
                {createMoneyOnMandateStep === 2 && (
                  <Button
                    color="primary"
                    onClick={() => {
                      if (selectedRecurringPayments.length > 0) {
                        setCreateMoneyOnMandateStep(3);
                        fetchMantisFee();
                        fetchUserBalance();
                      }
                    }}
                  >
                    Siguiente
                  </Button>
                )}
                {createMoneyOnMandateStep === 3 && (
                  <Button
                    color="primary"
                    onClick={() => {
                      if (paidAmount >= 0) {
                        setCreateMoneyOnMandateStep(4);
                      }
                    }}
                  >
                    Siguiente
                  </Button>
                )}

                {createMoneyOnMandateStep === 4 && (
                  <Button
                    color="primary"
                    onClick={() => {
                      handleCreateMoneyOnMandate();
                    }}
                  >
                    {creatingMoneyOnMandate ? <Spinner size={"sm"} /> : "Crear"}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}

export default connect(select)(MoneyOnMandates);
