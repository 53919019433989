import React, {useState, useEffect} from "react";
import {getDashboardData} from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {token} from "morgan";
import {
  getTpagaProviders,
  getTpagaBalance,
  payTpagaBill,
  queryTpagaBill
} from "../ApiCalls/backoffice";
import {backUrl} from "../utils/backURL";
import {Buffer} from "buffer";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function EficaciaDemo(props) {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [area, setArea] = useState("");
  const [loadingFirstQuery, setLoadingFirstQuery] = useState(false);
  const [threadId, setThreadId] = useState("");
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [threadStarted, setThreadStarted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {}, []);

  async function handleFirstQuery() {
    setLoadingFirstQuery(true);
    setWaitingForResponse(true);
    let messagesCopy = [...messages];
    messagesCopy.push({
      type: "text",
      from: "user",
      message: currentMessage
    });
    setMessages(messagesCopy);
    const prom = await fetch(`${backUrl}/assistant/eficaciaDemo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        area,
        query: currentMessage
      })
    });
    const res = await prom.json();
    console.log(res);
    if (res.success) {
      setThreadId(res.threadId);
      let returnedMessages = res.messages;
      returnedMessages.forEach((message) => {
        if (message.type === "text") {
          messagesCopy.push({
            type: "text",
            from: "bot",
            message: message.text
          });
        } else if (message.type === "image_file") {
          messagesCopy.push({
            type: "image",
            from: "bot",
            message: "",
            source: message.file
          });
        }
      });
      setMessages(messagesCopy);
      setThreadStarted(true);
    }
    setCurrentMessage("");
    setLoadingFirstQuery(false);
    setWaitingForResponse(false);
  }

  async function handleQuery() {
    setLoadingFirstQuery(true);
    setCurrentMessage("");
    setWaitingForResponse(true);
    let messagesCopy = [...messages];
    messagesCopy.push({
      type: "text",
      from: "user",
      message: currentMessage
    });
    setMessages(messagesCopy);
    const prom = await fetch(`${backUrl}/assistant/eficacia`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        threadId,
        query: currentMessage
      })
    });
    const res = await prom.json();
    console.log(res);
    if (res.success) {
      let returnedMessages = res.messages;
      returnedMessages.forEach((message) => {
        if (message.type === "text") {
          messagesCopy.push({
            type: "text",
            from: "bot",
            message: message.text
          });
        } else if (message.type === "image_file") {
          messagesCopy.push({
            type: "image",
            from: "bot",
            message: "",
            source: message.file
          });
        }
      });
      setMessages(messagesCopy);
      setThreadStarted(true);
    }
    setLoadingFirstQuery(false);
    setWaitingForResponse(false);
  }

  return (
    <>
      <h1>Demo</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <br />
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              {!threadStarted && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label>Area:</Label>
                      <Input
                        type="text"
                        name="area"
                        id="area"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label>Consulta:</Label>
                      <Input
                        type="text"
                        name="currentMessage"
                        id="currentMessage"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <Button
                      disabled={loadingFirstQuery}
                      style={{
                        color: "#282828"
                      }}
                      onClick={() => {
                        handleFirstQuery();
                      }}
                    >
                      {loadingFirstQuery ? (
                        <Spinner color="white" />
                      ) : (
                        <h7
                          style={{
                            color: "white"
                          }}
                        >
                          {"Consultar"}
                        </h7>
                      )}
                    </Button>
                  </Col>
                </Row>
              )}
              <Row style={{marginTop: 20}}>
                {messages.map((message, index) => {
                  if (message.from === "bot") {
                    return (
                      <Col
                        xs={9}
                        style={{
                          marginRight: "auto"
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            backgroundColor: "#f7f7f7",
                            padding: "10px",
                            borderRadius: "10px",
                            marginBottom: "10px"
                          }}
                        >
                          {message.type === "text" && (
                            <p style={{margin: "0px"}}>
                              {message.message.replace(/【.*】/g, "")}
                            </p>
                          )}
                          {message.type === "image" && (
                            <img
                              src={"data:image/jpeg;base64," + message.source}
                              style={{width: "100%"}}
                            />
                          )}
                        </div>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      xs={9}
                      style={{
                        marginLeft: "auto"
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          backgroundColor: "#f7f7f7",
                          padding: "10px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          textAlign: "right"
                        }}
                      >
                        <p style={{margin: "0px"}}>{message.message}</p>
                      </div>
                    </Col>
                  );
                })}
                {waitingForResponse && (
                  <Col
                    xs={9}
                    style={{
                      marginRight: "auto"
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#f7f7f7",
                        padding: "10px",
                        borderRadius: "10px",
                        marginBottom: "10px",
                        textAlign: "right"
                      }}
                    >
                      <p style={{margin: "0px"}}>. . .</p>
                    </div>
                  </Col>
                )}
              </Row>
              {threadStarted && (
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="currentMessage"
                        id="currentMessage"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        on
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <Button
                      style={{
                        color: "#282828"
                      }}
                      onClick={() => {
                        setThreadStarted(false);
                        setThreadId("");
                        setMessages([]);
                        setArea("");
                      }}
                    >
                      <h7
                        style={{
                          color: "white"
                        }}
                      >
                        {"Nueva consulta"}
                      </h7>
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      disabled={loadingFirstQuery}
                      style={{
                        color: "#282828"
                      }}
                      onClick={() => {
                        handleQuery();
                      }}
                    >
                      {loadingFirstQuery ? (
                        <Spinner />
                      ) : (
                        <h7
                          style={{
                            color: "white"
                          }}
                        >
                          {"Consultar"}
                        </h7>
                      )}
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default connect(select)(EficaciaDemo);
