function shortenText(text, length) {
  let returnText = text;
  if (text.length > length) {
    returnText = text.substring(0, length);
    returnText += "...";
  }
  return returnText;
}

module.exports = {shortenText};
