import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { token } from "morgan";
import { createNewPayment, getMonthlyPayments } from "../ApiCalls/backoffice";
import { getMonthDiff } from "../utils/dateUtils";
import { uploadFile, uploadImage } from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

function MonthlyPayments(props) {
  const [pendingPayments, setPendingPayments] = useState([]);
  const [payments, setPayments] = useState([]);
  const [executedPayments, setExecutedPayments] = useState([]);
  const [newPaymentFormState, setNewPaymentFormState] = useState({});
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
  const [creatingNewPayment, setCreatingNewPayment] = useState(false);
  const [voucherFile, setVoucherFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRecurringPayment, setSelectedRecurringPayment] =
    useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [searchName, setSearchName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedRecurringPayment) {
      setNewPaymentFormState({
        userId: selectedRecurringPayment.userId,
        type: selectedRecurringPayment.type,
        amount: selectedRecurringPayment.amount,
        status: "APPROVED",
        recurringPaymentId: selectedRecurringPayment._id,
        voucher: "",
        tpagaBillReference: selectedRecurringPayment.tpagaBillReference,
        integratedInvestmentId: selectedRecurringPayment.integratedInvestmentId,
      });
    } else {
      setNewPaymentFormState({});
    }
  }, [selectedRecurringPayment]);

  useEffect(() => {
    if (searchName) {
      const filteredPayments = payments.filter((payment) => {
        return (
          payment.user.firstName
            .toLowerCase()
            .includes(searchName.toLowerCase()) ||
          payment.user.lastName.toLowerCase().includes(searchName.toLowerCase())
        );
      });
      let paid = [];
      let pending = [];
      filteredPayments.map((payment) => {
        if (payment.status === "WAITING") {
          pending.push(payment);
        } else {
          paid.push(payment);
        }
      });
      setExecutedPayments(paid);
      setPendingPayments(pending);
    } else {
      let paid = [];
      let pending = [];
      payments.map((payment) => {
        if (payment.status === "WAITING") {
          pending.push(payment);
        } else {
          paid.push(payment);
        }
      });
      setExecutedPayments(paid);
      setPendingPayments(pending);
    }
  }, [searchName]);

  async function getData() {
    const paymentsRes = await getMonthlyPayments(props.userId, props.token);

    if (paymentsRes.success) {
      let paid = [];
      let pending = [];
      paymentsRes.payments.map((payment) => {
        if (payment.status === "WAITING") {
          pending.push(payment);
        } else {
          paid.push(payment);
        }
      });
      setPayments(paymentsRes.payments);
      setExecutedPayments(paid);
      setPendingPayments(pending);
    }
    setLoading(false);
  }
  const handleUploadPdf = async () => {
    if (voucherFile.size > 20000000) {
      alert("El tamaño máximo del archivo es de 20mb");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {});

    reader.readAsDataURL(voucherFile);

    const formData = new FormData();
    formData.append("file", voucherFile);

    const thumbnailJson = await uploadFile(formData);

    if (thumbnailJson.fileUrl) {
      return thumbnailJson.fileUrl;
    } else {
      alert("Something went wrong: " + thumbnailJson.errors);
    }
  };

  const handleUploadImage = async () => {
    if (voucherFile.size > 20000000) {
      alert("El tamaño máximo de la imágen es de 20mb");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {});

    reader.readAsDataURL(voucherFile);

    const formData = new FormData();
    formData.append("image", voucherFile);

    const thumbnailJson = await uploadImage(formData);

    if (thumbnailJson.imageUrl) {
      return thumbnailJson.imageUrl;
    } else {
      alert("Something went wrong: " + thumbnailJson.errors);
    }
  };

  const handleCreatePaymentInputChange = (event) => {
    const { name, value } = event.target;

    setNewPaymentFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setVoucherFile(event.target.files[0]);
  };

  const handleCreateNewPayment = async (event) => {
    setCreatingNewPayment(true);
    event.preventDefault();
    let voucherUrl = "";
    if (voucherFile) {
      if (voucherFile.type === "application/pdf") {
        voucherUrl = await handleUploadPdf();
      } else {
        voucherUrl = await handleUploadImage();
      }
    }
    let newPaymentObject = {};
    if (voucherUrl) {
      newPaymentObject = {
        ...newPaymentFormState,
        voucher: voucherUrl,
      };
    } else {
      newPaymentObject = {
        ...newPaymentFormState,
      };
    }

    const createRes = await createNewPayment(
      props.userId,
      props.token,
      newPaymentObject
    );
    if (createRes.success) {
      setAlertMessage("Pago registrado creado con éxito");
      getData();
    } else {
      setAlertMessage("Error al crear el pago recurrente");
    }
    setSelectedRecurringPayment(null);
    setShowNewPaymentModal(false);
    setCreatingNewPayment(false);
    setVoucherFile(null);
    setNewPaymentFormState({});
  };

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <h1>Pagos recurrentes</h1>
      <br />
      {alertMessage && (
        <Row>
          <Col xs={6} style={{ margin: "auto", marginTop: 50 }}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col xs={4} style={{ margin: "auto" }}>
              <FormGroup>
                <Input
                  type="text"
                  name="searchName"
                  id="searchName"
                  placeholder="Buscar por nombre"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          {pendingPayments.length > 0 && (
            <Row>
              <h2>Pagos por ejecutar</h2>
              <Col xs={10} style={{ margin: "auto" }}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Usuario</th>
                          <th>Pago</th>
                          <th>Día de pago</th>
                          <th>Último pago</th>
                          <th>Monto</th>
                          <th>Estado</th>
                          <th>Estado último pago</th>
                          <th>Tipo</th>
                          <th>Último comprobante</th>
                          <th>Registrar pago</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingPayments.map((payment, index) => {
                          let datePaid = null;
                          if (payment.datePaid) {
                            datePaid = new Date(payment.datePaid);
                          }
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {payment.user.firstName} {payment.user.lastName}
                              </td>
                              <td>
                                {payment.icon ? payment.icon + " " : ""}
                                {payment.name || payment.type}
                              </td>
                              <td>{payment.paymentDay || "-"}</td>

                              <td>
                                {datePaid
                                  ? datePaid.toLocaleDateString("es")
                                  : "-"}
                              </td>
                              <td>${formatMoney(payment.amount)}</td>
                              <td>{payment.status}</td>
                              <td>
                                {payment.payments.length > 0
                                  ? payment.payments[0].status
                                  : "-"}
                              </td>
                              <td>{payment.type}</td>
                              <td>
                                {payment.payments.length > 0 ? (
                                  payment.payments[0].voucher ? (
                                    payment.payments[0].voucher.includes(
                                      "http"
                                    ) ? (
                                      <a
                                        href={payment.payments[0].voucher}
                                        target="_blank"
                                      >
                                        Ver
                                      </a>
                                    ) : (
                                      <a
                                        download="voucherMantis.pdf"
                                        href={`data:file/pdf;base64,${payment.payments[0].voucher}`}
                                      >
                                        Ver
                                      </a>
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td
                                onClick={() => {
                                  setSelectedRecurringPayment(payment);
                                  setShowNewPaymentModal(true);
                                }}
                              >
                                <i
                                  className="fas fa-money-bill"
                                  style={{ fontSize: "1em", cursor: "pointer" }}
                                />
                              </td>
                              <td
                                onClick={() =>
                                  window.open(
                                    `/backoffice/recurringPayment/${payment._id}`,
                                    "rel=noopener noreferrer"
                                  )
                                }
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ fontSize: "1em", cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <hr />
          {executedPayments.length > 0 && (
            <Row style={{ marginTop: 20 }}>
              <h2>Pagos ejecutados</h2>
              <Col xs={10} style={{ margin: "auto" }}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Usuario</th>
                          <th>Pago</th>
                          <th>Día de pago</th>
                          <th>Último pago</th>
                          <th>Monto</th>
                          <th>Estado</th>
                          <th>Estado último pago</th>
                          <th>Tipo</th>
                          <th>Último comprobante</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {executedPayments.map((payment, index) => {
                          let datePaid = null;
                          if (payment.datePaid) {
                            datePaid = new Date(payment.datePaid);
                          }
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {payment.user.firstName} {payment.user.lastName}
                              </td>
                              <td>
                                {payment.icon ? payment.icon + " " : ""}
                                {payment.name || payment.type}
                              </td>
                              <td>{payment.paymentDay || "-"}</td>
                              <td>
                                {datePaid
                                  ? datePaid.toLocaleDateString("es")
                                  : "-"}
                              </td>
                              <td>${formatMoney(payment.amount)}</td>
                              <td>{payment.status}</td>
                              <td>
                                {payment.payments.length > 0
                                  ? payment.payments[0].status
                                  : "-"}
                              </td>
                              <td>{payment.type}</td>
                              <td>
                                {payment.payments.length > 0 ? (
                                  payment.payments[0].voucher ? (
                                    payment.payments[0].voucher.includes(
                                      "http"
                                    ) ? (
                                      <a
                                        href={payment.payments[0].voucher}
                                        target="_blank"
                                      >
                                        Ver
                                      </a>
                                    ) : (
                                      <a
                                        download="voucherMantis.pdf"
                                        href={`data:file/pdf;base64,${payment.payments[0].voucher}`}
                                      >
                                        Ver
                                      </a>
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td
                                onClick={() =>
                                  window.open(
                                    `/backoffice/recurringPayment/${payment._id}`,
                                    "rel=noopener noreferrer"
                                  )
                                }
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ fontSize: "1em", cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {pendingPayments.length + executedPayments.length === 0 && (
            <h2>No hay pagos recurrentes!</h2>
          )}
          <Modal
            isOpen={showNewPaymentModal}
            toggle={() => {
              if (!creatingNewPayment) {
                setShowNewPaymentModal(!showNewPaymentModal);
              }
            }}
          >
            <ModalHeader
              toggle={() => {
                if (!creatingNewPayment) {
                  setShowNewPaymentModal(!showNewPaymentModal);
                }
              }}
            >
              Generar pago
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={handleCreateNewPayment}
                style={{ margin: "auto" }}
              >
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="amount">Monto:</Label>
                      <Input
                        type="text"
                        name="amount"
                        id="amount"
                        value={newPaymentFormState.amount}
                        onChange={handleCreatePaymentInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="status">Estado:</Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        value={newPaymentFormState.status}
                        onChange={handleCreatePaymentInputChange}
                      >
                        <option value={"APPROVED"}>Aprobado</option>
                        <option value={"PENDING"}>Pendiente</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="exampleFile">Subir comprobante</Label>
                      <Input
                        id="exampleFile"
                        name="file"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} style={{ margin: "auto" }}>
                    <Button color="primary" type="submit">
                      {creatingNewPayment ? (
                        <Spinner size={"sm"} />
                      ) : (
                        "Generar pago"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
}

export default connect(select)(MonthlyPayments);
