import React, {useState} from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import mantisLogo from "../Assets/img/mantisLogo.png";
import background from "./Assets/masterclassBackground.png";
import mobileBackground from "./Assets/masterclassMobileBackground.png";
import "./styles/dataTreatment.scss";
import {backUrl} from "../utils/backURL";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function DownloadMasterClass() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [name, setName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [showEnterDataModal, setShowEnterDataModal] = useState(true);
  const [uploadingInfo, setUploadingInfo] = useState(false);

  const handleSubmitInfo = async (e) => {
    e.preventDefault();
    if (name !== "" && cellphone !== "") {
      setUploadingInfo(true);
      await fetch(`${backUrl}/waitlistContacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({name, cellphone})
      });
      setUploadingInfo(false);
      setShowEnterDataModal(false);
      window.location.href = "https://linktr.ee/mantisapp";
    }
  };

  return (
    <div
      style={{
        height: 1000,
        backgroundColor: "#202020"
      }}
    >
      <div
        style={{
          width: "100%",
          marginBottom: 30
        }}
      >
        {isMobile ? (
          <img
            src={mobileBackground}
            alt="Mantis Logo"
            //height={isMobile ? 400 : 700}
            width={"100%"}
            //style={{marginTop: 20}}
          />
        ) : (
          <img
            src={background}
            alt="Mantis Logo"
            //height={isMobile ? 400 : 700}
            width={"100%"}
            //style={{marginTop: 20}}
          />
        )}

        <Modal isOpen={showEnterDataModal} centered>
          <ModalHeader style={{textAlign: "center"}}>
            Déjanos tus datos para compartirte la información de la Masterclass
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmitInfo} style={{margin: "auto"}}>
              <Row>
                <Col xs={9} style={{margin: "auto"}}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Nombre completo"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <PhoneInput
                    country="co"
                    name="cellphone"
                    style={{width: "90%", margin: "auto", marginBottom: 10}}
                    enableSearch={true}
                    value={cellphone}
                    onChange={(e) => {
                      setCellphone(e);
                    }}
                  />
                </Col>
                <Col xs={12} style={{textAlign: "center"}}>
                  <Button color="primary" type="submit">
                    {uploadingInfo ? <Spinner size={"sm"} /> : "Enviar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}
