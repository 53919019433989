import {Stack} from "@mui/material";
import {Button} from "@mui/material";
import {Link} from "@mui/material";
import {Box} from "@mui/material";
import {Typography} from "@mui/material";
import MantisLogo from "./Assets/mantisWhiteLogo.png";
import BackgroundImage from "./Assets/backgroundImage.png";
import GoogleStore from "./Assets/googleButton.png";
import AppleStore from "./Assets/appleButton.png";
import MenuIcon from "@mui/icons-material/Menu";
import BackgroundImage2 from "./Assets/backgroundImage2.png";
import GoogleFooterLogo from "./Assets/googleLogo.png";
import FacebookFooterLogo from "./Assets/facebookLogo.png";
import TwitterFooterLogo from "./Assets/twitterLogo.png";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Card, CardContent, Rating} from "@mui/material";
import YellowCard from "./Assets/yellowHeart.png";
import MantisTeam from "./Assets/mantisTeam.png";
import {IconButton, Drawer} from "@mui/material";
import {FaYoutube, FaInstagram, FaLinkedin} from "react-icons/fa";
import {useState} from "react";
import {Row, Col, Container} from "reactstrap";
import {businessUrl} from "../utils/backURL";

const theme = createTheme({
  typography: {
    fontFamily: "Garet, sans-serif"
  }
});

export default function AboutUs() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Stack
        direction={{xs: "column", sm: "row"}}
        justifyContent="space-evenly"
        alignItems="center"
        p={3}
        sx={{backgroundColor: "#000000"}}
      >
        <a href="/">
          <img src={MantisLogo} alt="Mantis Logo" style={{width: "25vh"}} />
        </a>
        <IconButton
          sx={{display: {xs: "block", sm: "none"}, color: "white"}}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          direction="row"
          spacing={3}
          sx={{display: {xs: "none", sm: "flex"}}}
        >
          <Link
            href="#experience"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Experiencia Mantis
          </Link>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{display: {xs: "none", sm: "flex"}}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F0F56C",
              textTransform: "capitalize",
              color: "black",
              "&:hover": {backgroundColor: "#F0F56C"}
            }}
          >
            <a
              style={{textDecoration: "none", color: "black"}}
              target="_blank"
              href="https://forms.gle/eVQAeyYeFUkjoquf7"
            >
              Solicitar una demostración
            </a>
          </Button>
        </Stack>
      </Stack>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{".MuiDrawer-paper": {backgroundColor: "#000000"}}}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{padding: "20px", backgroundColor: "#000000", color: "white"}}
        >
          <Link
            href="#aboutUs"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Sobre Mantis
          </Link>
          <Link
            href="#experience"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Experiencia Mantis
          </Link>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{padding: "20px", backgroundColor: "#000000", color: "white"}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F0F56C",
              textTransform: "capitalize",
              color: "black",
              "&:hover": {backgroundColor: "#F0F56C"}
            }}
          >
            <a
              style={{textDecoration: "none", color: "black"}}
              target="_blank"
              href="https://forms.gle/eVQAeyYeFUkjoquf7"
            >
              Solicitar una demostración
            </a>
          </Button>
        </Stack>
      </Drawer>

      <Box
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: {xs: "1100px", sm: "100%"},
          width: "100%",
          display: "flex",
          alignItems: {xs: "start", sm: "center"},
          justifyContent: "space-between",
          padding: {xs: "0 7.5%", sm: "0 10%"}
        }}
        id="aboutUs"
      >
        <Stack direction="column" spacing={3} sx={{color: "#FFFFFF"}}>
          <ThemeProvider theme={theme}>
            <Typography
              letterSpacing={1}
              fontSize={50}
              variant="body2"
              sx={{color: "white", textAlign: "left"}}
            >
              Sobre Mantis
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography
              variant="body1"
              sx={{color: "#FFFFFF", textAlign: "left"}}
            >
              En Mantis queremos que las personas vivan una vida emocionante,
              creemos que no hay nada que mate más la emoción que las tareas
              administrativas que tenemos que hacer de adultos, lidiar con
              documentos, pagos y burocracia! Somos un equipo de personas
              apasionadas por la vida, hacemos mucho deporte, corremos ultra
              maratones, nos encantan los videojuegos, los festivales de música,
              hacemos parapente, escalamos y mucho más. No creemos en las
              limitaciones y siempre estamos empujando lo que es posible. Nos
              emociona mucho poder estar trabajando en la primera generación de
              empresas usando IA y creemos que el poder de la tecnología nos va
              ayudar a que millones de personas puedan tener una vida muy
              emocionante y que las tareas administrativas puedan hacerse de
              forma automática.
            </Typography>
          </ThemeProvider>
          {isMobile && (
            <img src={MantisTeam} style={{width: "100%", maxHeight: "75%"}} />
          )}
        </Stack>
        {!isMobile && (
          <img src={MantisTeam} style={{maxWidth: "50%", maxHeight: "75%"}} />
        )}
      </Box>

      {isMobile ? (
        <Stack
          id="experience"
          direction="column"
          sx={{backgroundColor: "black"}}
          padding={7.5}
        >
          <Stack spacing={2} direction="column" sx={{backgroundColor: "black"}}>
            <img src={YellowCard} style={{width: "12.5vw"}} />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                component="h1"
                sx={{fontWeight: "bold", color: "white", textAlign: "left"}}
              >
                Experiencia Mantis:
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="body1"
                component="h1"
                sx={{color: "white", textAlign: "left"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </ThemeProvider>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black"
            }}
          >
            <Box sx={{width: "100%", height: 850, overflow: "hidden"}}>
              <Stack mt={5} direction="column" justifyContent="space-between">
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Conecté mi correo y ahora toda mi información está en un
                      solo lugar automáticamente, además no he vuelto a pensar
                      en pagos porque todo lo hace Mantis por mí"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Felipe Charris
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Antes me tomaba hasta tres horas realizando informes para
                      empresas de salud. Con la tecnología Mantis solo debo
                      subir la información y el informe se genera en segundos."
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Maria Del Mar
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Nuestros procesos de validación de PDFs era muy manual lo
                      que nos consumia muchas horas de trabajo, ahora todo se
                      analiza de forma automatica con Mantis lo que resulta de
                      gran ayuda!"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Juan de Dios
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Stack>
      ) : (
        <Stack
          id="experience"
          direction="column"
          sx={{backgroundColor: "black"}}
          padding={10}
        >
          <Stack spacing={2} direction="column" sx={{backgroundColor: "black"}}>
            <img src={YellowCard} style={{width: "5vw"}} />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                component="h1"
                sx={{fontWeight: "bold", color: "white", textAlign: "left"}}
              >
                Experiencia Mantis:
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", textAlign: "left"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </ThemeProvider>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "black"
            }}
          >
            <Box
              sx={{width: "100%", height: 280, justifyContent: "space-around"}}
            >
              <Stack mt={5} direction="row" spacing={1}>
                <Card sx={{height: 200, borderRadius: "1vw"}}>
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      "Subir información a Mantis y alimentarla con fuente de
                      datos aprovecha al máximo insights que antes no teniamos
                      sobre el radar. La información ahora sí es un activo
                      totalmente aprovechable."
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Felipe Charris
                    </Typography>
                  </CardContent>
                </Card>
                <Card sx={{height: 200, borderRadius: "1vw"}}>
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Antes me tomaba hasta tres horas realizando informes para
                      empresas de salud. Con la tecnología Mantis solo debo
                      subir la información y el informe se genera en segundos.”
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Maria del Mar
                    </Typography>
                  </CardContent>
                </Card>
                <Card sx={{height: 200, borderRadius: "1vw"}}>
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      "Nuestros procesos de validación de PDFs era muy manual lo
                      que nos consumia muchas horas de trabajo, ahora todo se
                      analiza de forma automatica con Mantis lo que resulta de
                      gran ayuda!"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Juan De Dios
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Stack>
      )}

      {isMobile ? (
        <Box
          sx={{
            backgroundImage: `url(${BackgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10% 10%",
            backgroundColor: "black"
          }}
        >
          <Stack
            justifyContent="space-around"
            alignItems="center"
            padding={5}
            direction="column"
            spacing={2}
            sx={{
              maxWidth: "100%",
              height: "75%",
              color: "#FFFFFF",
              backgroundColor: "#F0F56C",
              borderRadius: "10px"
            }}
          >
            <Stack direction="column" maxWidth="75%">
              <ThemeProvider theme={theme}>
                <Typography
                  fontSize={20}
                  variant="body1"
                  sx={{color: "black", textAlign: "center"}}
                >
                  Solicita un demo en el siguiente link y empieza a tener el
                  control de toda la información de tu empresa
                </Typography>
              </ThemeProvider>
            </Stack>
            <Stack direction="column" spacing={2}>
              {/* <a href="https://apps.apple.com/co/app/mantis-asistente-virtual/id6449679347?l=en-GB">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={AppleStore}
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=co.mantisapp.app">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={GoogleStore}
                  alt=""
                />
              </a> */}
              <a
                href="https://calendly.com/cristian-obac/30min"
                target="_blank"
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "150px",
                    height: "50px",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  <i
                    className="fas fa-calendar"
                    style={{color: "white", fontSize: 26}}
                  />
                </div>
              </a>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${BackgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10%",
            backgroundColor: "black"
          }}
        >
          <Stack
            justifyContent="space-around"
            alignItems="center"
            padding={3}
            direction="row"
            spacing={2}
            sx={{
              maxWidth: "85%",
              color: "#FFFFFF",
              backgroundColor: "#F0F56C",
              borderRadius: "10px"
            }}
          >
            <Stack direction="column" maxWidth="40%">
              <ThemeProvider theme={theme}>
                <Typography
                  fontSize={30}
                  variant="body1"
                  sx={{color: "black", textAlign: "left"}}
                >
                  Solicita un demo en el siguiente link y empieza a tener el
                  control de toda la información de tu empresa
                </Typography>
              </ThemeProvider>
              {/*  <ThemeProvider theme={theme}>
                <Typography variant="body1" sx={{ color: "black" }}>
                  Obtén una prueba gratuita de 3 meses
                </Typography>
              </ThemeProvider> */}
            </Stack>
            <Stack direction="column" spacing={3}>
              {/* <a href="https://apps.apple.com/co/app/mantis-asistente-virtual/id6449679347?l=en-GB">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={AppleStore}
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=co.mantisapp.app">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={GoogleStore}
                  alt=""
                />
              </a> */}
              <a
                href="https://calendly.com/cristian-obac/30min"
                target="_blank"
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "150px",
                    height: "50px",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  <i
                    className="fas fa-calendar"
                    style={{color: "white", fontSize: 26}}
                  />
                </div>
              </a>
            </Stack>
          </Stack>
        </Box>
      )}

      {isMobile ? (
        <Stack
          justifyContent="center"
          spacing={5}
          padding={3}
          direction="column"
          sx={{backgroundColor: "black", minWidth: "100%"}}
          alignItems="center"
        >
          <Row>
            <Col xs={12}>
              <img src={MantisLogo} style={{width: "25vh"}} />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </Col>
          </Row>

          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Mantis
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open("https://www.mantisapp.co/aboutUs", "_blank");
                }}
              >
                Sobre nosotros
              </Typography>

              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(businessUrl, "_blank");
                }}
              >
                Plataforma
              </Typography>
            </Stack>

            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Información
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                <a href="#faq" style={{textDecoration: "none", color: "white"}}>
                  Preguntas frecuentes
                </a>
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/dataTreatment",
                    "_blank"
                  );
                }}
              >
                Politica de tratamiento de datos
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/terms&Conditions",
                    "_blank"
                  );
                }}
              >
                Términos y condiciones
              </Typography>
            </Stack>

            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Contacto
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Mantis APP S.A.S
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Bogotá, Colombia
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                hola@mantisapp.co
              </Typography>
            </Stack>
          </Stack>
          <Row>
            <Col xs={12}>
              <Typography variant="body2" sx={{color: "white"}}>
                &copy; Mantis 2024, All Rights Reserved
              </Typography>
            </Col>
          </Row>
        </Stack>
      ) : (
        <Stack
          spacing={3}
          padding={3}
          direction="column"
          sx={{backgroundColor: "black"}}
          alignItems="center"
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1} maxWidth="30%">
              <img src={MantisLogo} style={{width: "25vh"}} />
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "left"}}
              >
                Automatiza tareas, paga sin esfuerzo y mejora tu salud
                financeira.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Mantis
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open("https://www.mantisapp.co/aboutUs", "_blank");
                }}
              >
                Sobre nosotros
              </Typography>

              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(businessUrl, "_blank");
                }}
              >
                Plataforma
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Información
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                <a href="#faq" style={{textDecoration: "none", color: "white"}}>
                  Preguntas frecuentes
                </a>
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/dataTreatment",
                    "_blank"
                  );
                }}
              >
                Politica de tratamiento de datos
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/terms&Conditions",
                    "_blank"
                  );
                }}
              >
                Términos y condiciones
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Contacto
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Mantis APP S.A.S
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Bogotá, Colombia
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                hola@mantisapp.co
              </Typography>
            </Stack>
          </Stack>
          <div
            style={{
              borderTop: "1px solid #FFF",
              width: "75%",
              height: "0",
              marginTop: "15px"
            }}
          />
          <Stack direction="row" spacing={65} justifyContent="space-between">
            <Typography variant="body2" sx={{color: "white"}}>
              &copy; Mantis 2024, All Rights Reserved
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}
