import React from "react";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import "./styles/dataTreatment.scss";

export default function Mandato() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div
      style={{marginTop: 30, width: isMobile ? "85%" : "50%", margin: "auto"}}
    >
      <img
        src={mantisLogo}
        alt="Mantis Logo"
        height={isMobile ? 40 : 70}
        style={{marginTop: 20}}
      />
      <br />
      <br />
      <h1 className="doc-title">CONTRATO DE MANDATO MANTIS APP SAS.</h1>
      <h2 className="subtitle">I. Partes</h2>
      <p>
        Por un lado, ________________________, identificado con
        __________________, representada legalmente por ______________________,
        mayor de edad, identificado con _____________ número
        _____________________, actuando en calidad de <strong>MANDANTE</strong>;
        y
      </p>
      <p>
        Por otro lado, <strong>MANTIS APP S.A.S.</strong>, identificada con
        número de identificación tributaria ________________, representada
        legalmente por _______________, mayor de edad, identificado con cédula
        de ciudadanía número ___________________ de_______________, actuando en
        calidad de <strong>MANDATARIO</strong>;
      </p>
      <p>
        Conjuntamente las <strong>“Partes”</strong>, acuerdan celebrar el
        presente Contrato de Mandato (el <strong>“Contrato”</strong>), previa a
        las siguientes:
      </p>
      <h2 className="subtitle">II. Definiciones</h2>
      <p>
        <strong>Entidad Aliada:</strong> Se entiende como el mandante que
        faculta a Mantis App S.A.S. para que, en su nombre y representación, a
        través de la aplicación Mantis, realice el envío de documentos a sus
        clientes, empleados y/o proveedores, conforme a su instrucción.
      </p>
      <p>
        <strong>Mantis:</strong> Aplicación móvil de Mantis App S.A.S., en la
        cual, la entidad aliada utiliza el servicio de canal de envío de
        documentos.
      </p>
      <p>
        <strong>Usuario:</strong> Empleado, cliente y/o proveedor de la Entidad
        Aliada que crea una cuenta en la aplicación Mantis para recibir los
        documentos emitidos por la Entidad Aliada y para usar los demás
        servicios de Mantis.
      </p>
      <h2 className="subtitle">III. Consideraciones</h2>
      <ol>
        <li>
          <p>
            Que la Entidad Aliada solicitara a los Usuarios que se vinculen la
            aplicación de Mantis.
          </p>
        </li>
        <li>
          <p>
            Que los Usuarios recibirán distintos documentos a través de su
            cuenta en la aplicación de Mantis.
          </p>
        </li>
        <li>
          <p>
            Que dichos documentos serán vinculantes y se entenderán notificados
            en debida forma.
          </p>
        </li>
        <li>
          <p>
            Que los Usuarios podrán pronunciarse sobre el contenido de los
            documentos emitidos por la Entidad Aliada y dados a conocer a través
            de Mantis, de forma directa con la Entidad Aliada.
          </p>
        </li>
      </ol>
      <h2 className="subtitle">IV. Cláusulas</h2>
      <p>
        <strong>1. Del mandato</strong>
      </p>
      <p>
        <strong>1.1. Objeto</strong>
      </p>
      <p>
        El Mandante confiere en el presente mandato la instrucción a Mantis de
        que lleve a cabo el envío de documentos a los Usuarios, cuando
        corresponda.
      </p>
      <p>
        <strong>1.2. Duración del contrato</strong>
      </p>
      <p>
        El presente Contrato tendrá vigencia de un (1) año contado a partir de
        la firma de este.
      </p>
      <p>
        <strong>1.3. Remuneración</strong>
      </p>
      <p>
        El Mandatario acuerda pagar a Mantis un valor de _______________________
        por el servicio del canal de envío de documentos.
      </p>
      <p>
        <strong>1.4. Obligaciones del Mandante</strong>
      </p>
      <p>El Mandante tendrá las siguientes obligaciones:</p>
      <p>
        1.4.1. Garantizar la vinculación efectiva de los Usuarios a la
        plataforma de Mantis.
      </p>
      <p>
        1.4.2. Proveer lo necesario a Mantis para la realización del presente
        mandato.
      </p>
      <p>1.4.3. Pagar la remuneración pactada.</p>
      <p>1.4.4. Las demás que se desprendan del presente documento.</p>
      <p>
        <strong>1.5. Obligaciones del Mandatario</strong>
      </p>
      <p>El Mandatario tendrá las siguientes obligaciones:</p>
      <p>
        1.5.1. Llevar a cabo el envío de los documentos diligentemente,
        respetando los intereses de la Entidad Aliada y actuando conforme a las
        leyes y regulaciones aplicables.
      </p>
      <p>1.5.2. Las demás que se desprendan del presente documento.</p>
      <p>
        <strong>2. Otras disposiciones</strong>
      </p>
      <p>
        <strong>2.1. Declaraciones de las partes</strong>
      </p>
      <p>Las partes declaran que:</p>
      <p>
        2.1.1. A la fecha de la celebración del contrato, no presenta conflictos
        de intereses de ninguna naturaleza.
      </p>
      <p>
        2.1.2. No existe ninguna orden, medida o investigación notificada por
        una autoridad, ni conoce procesos o litigios en su contra o en los
        cuales pueda verse involucrado y que tengan como consecuencia medidas,
        sanciones o condenas que prohíban, limiten, condicionen o retrasen la
        ejecución del Contrato.
      </p>
      <p>
        2.1.3. Toda la información proporcionada es completa, veraz, correcta y
        precisa.
      </p>
      <p>
        2.1.4. No están siendo investigadas, ni han sido declaradas responsables
        y/o condenadas y/o sancionadas, ni judicial ni administrativamente, por
        ninguna autoridad nacional o extranjera, por hechos ocurridos en
        Colombia o en el exterior, por haber incurrido o participado a cualquier
        título o en cualquier calidad, directa o indirectamente, en la comisión
        de (i) algún delito contra la administración pública, o (ii) alguno de
        los delitos o faltas contempladas por la Ley 1474 de 2011 o cualquiera
        de sus normas modificatorias, o (iii) cualquiera de las conductas
        delictivas contempladas por las convenciones o tratados de lucha contra
        la corrupción suscritos y ratificados por Colombia o (iv) alguno de los
        delitos relacionados con los Actos Relevantes, o (v) alguno de los
        delitos de lesa humanidad o narcotráfico.
      </p>
      <p>
        2.1.5. No han sido incluido en ninguna lista nacional ni extranjera, por
        hechos ocurridos en Colombia o en el exterior, por haber incurrido o
        participado a cualquier título o en cualquier calidad, directa o
        indirectamente, en la comisión de alguno de los Actos Relevantes.
      </p>
      <p>
        2.1.6. No han incurrido ni participado, a ningún título y en ninguna
        calidad, en prácticas, actos, omisiones o delitos, relacionados directa
        o indirectamente, con ninguno de los Actos Relevantes.
      </p>
      <p>
        2.1.7. Han tomado todas las medidas necesarias para evitar incurrir y/o
        participar, a cualquier título y en cualquier calidad, en prácticas,
        actos, omisiones o delitos relacionados directa o indirectamente con
        alguno de los Actos Relevantes.
      </p>
      <p>
        <strong>2.2. Terminación</strong>
      </p>
      <p>
        El presente contrato terminará por la finalización de la vigencia; o por
        la manifestación unilateral de cualquiera de las partes con un periodo
        de un mes de antelación. Cabe resaltar que no habrá devoluciones de
        dinero en caso de la terminación anticipada por parte de la Entidad
        Aliada.
      </p>
      <p>
        <strong>2.3. Incumplimiento del contrato</strong>
      </p>
      <p>
        Las Partes incurrirán en un evento de incumplimiento, en cualquiera de
        las siguientes hipótesis (los{" "}
        <strong>“eventos de incumplimiento”</strong>):
      </p>
      <p>
        2.3.1. Si no cumpliese en tiempo y forma con cualquiera de las
        obligaciones que le corresponde según lo establecido en el Contrato.
      </p>
      <p>
        2.3.2. Si cualquiera de las declaraciones y manifestaciones fuera
        incorrecta, incompleta o pudieran conducir a engaño o error a la otra
        parte.{" "}
      </p>
      <p>
        <strong>2.4. Notificaciones</strong>
      </p>
      <p>
        Toda comunicación o notificación remitida por una de las Partes con
        destino a la otra Parte, deberá ser remitida por escrito de manera
        física o a través de correo electrónico en las direcciones que se
        especifican a continuación:
      </p>
      <p>En cuanto al MANDANTE</p>
      <ul>
        <li>
          <p>Correo electrónico:__________________</p>
        </li>
        <li>
          <p>Dirección física:_______________________</p>
        </li>
      </ul>
      <p>En cuanto al MANDANTE</p>
      <ul>
        <li>
          <p>Correo electrónico:__________________</p>
        </li>
        <li>
          <p>Dirección física:_______________________</p>
        </li>
      </ul>
      <p>
        <strong>2.5. Nulidad</strong>
      </p>
      <p>
        En el evento en que se determine que una estipulación contenida en el
        presente Contrato es nula, inválida o ineficaz, las demás estipulaciones
        continuarán vigentes. De ocurrir lo anterior, las Partes se obligan a
        reemplazar de buena fe dicha disposición de mutuo acuerdo -de manera
        que- se mantenga el sentido de la disposición inicial.
      </p>
      <p>
        <strong>2.6. Confidencialidad</strong>
      </p>
      <p>
        Por “Información Confidencial”, se entenderá toda aquella información
        que Mantis App S.A.S. o sus colaboradores en relación con los servicios,
        le comunique a la Entidad Aliada. Lo anterior incluye, pero no se limita
        a cualquier información de carácter técnico, científico, financiero,
        legal, fiscal y comercial, modelos y estrategias de negocio, know how,
        información relacionada con clientes y socios, proyectos y operaciones
        de Mantis App S.A.S., así como los análisis y documentos de trabajo,
        recopilaciones, comparaciones, estudios y en general, toda la
        información que Mantis App S.A.S. transmita/transfiera o haya
        transmitido/transferido con anterioridad o posterioridad al uso de los
        Servicios.
      </p>
      <p>
        Cualquier divulgación y uso no autorizado de la Información Confidencial
        conlleva la obligación de responder ante Mantis App S.A.S. y terceros
        por los perjuicios que, como consecuencia de ello, se les hubieren
        causado. De conformidad con lo anterior, Mantis App SAS tendrá derecho a
        reclamar judicialmente y a obtener una indemnización por los daños y
        perjuicios que tal divulgación y uso no autorizado le hayan generado.
      </p>
      <p>
        <strong>2.7. Propiedad intelectual</strong>
      </p>
      <p>
        Con la firma del presente contrato, las partes se obligan, a observar y
        respetar todos los derechos y títulos de propiedad intelectual, bien
        sean Derechos de Autor o de Propiedad Industrial que legalmente ostenten
        las sociedades de los contratantes. Así las cosas, se entiende que las
        partes no ceden sus derechos, ni autorizan el uso de sus marcas, signos
        distintivos o demás objetos de protección por propiedad intelectual;
        igualmente, los titulares de los Derechos de Autor y Propiedad
        Industrial mantendrán el control exclusivo de las obras, signos
        distintivos y demás objetos de protección de los que sean titulares.
      </p>
      <p>
        <strong>2.8. Ley aplicable</strong>
      </p>
      <p>
        La celebración, otorgamiento, interpretación, ejecución y demás aspectos
        relacionados con el presente Contrato, se regirán por la ley de la
        República de Colombia.
      </p>
      <p>
        <strong>2.9. Modificación del Contrato</strong>
      </p>
      <p>
        El presente Contrato solo podrá ser modificado por mutuo acuerdo escrito
        de las Partes.
      </p>
      <p>
        <strong>2.10. Solución de controversias</strong>
      </p>
      <p>
        Cualquier controversia que surja entre las partes se resolverá mediante
        arreglo directo, de manera que la parte inconforme remita a la otra
        parte un comunicado por escrito; la parte receptora del comunicado
        responderá en un término máximo de diez (10) días hábiles contados a
        partir del día siguiente de la recepción del mensaje.
      </p>
      <p>
        En caso de que el arreglo directo no sea un mecanismo idóneo, las partes
        podrán acudir a la jurisdicción ordinaria colombiana, teniendo como
        domicilio contractual a Bogotá, Colombia.
      </p>
      <p>
        <strong>2.11. Firma electrónica</strong>
      </p>
      <p>
        Las partes aceptan y reconocen que el presente Contrato se firmará a
        través del mecanismo de firma electrónica establecido, el cual cumple
        con los criterios de confiabilidad y apropiabilidad y, por medio del
        cual se garantizan los atributos de autenticidad e integridad necesarios
        de conformidad con la Ley 527 de 1999 y el Decreto 2364 de 2012,
        compilado en Decreto 1074 de 2015.
      </p>
      <p>
        <strong>2.12. Constancia y Fecha de Celebración</strong>
      </p>
      <p>
        En constancia de todo lo anterior, el presente Contrato se firma a los
        _____________ (___) días del mes de _______________ del año _________.
      </p>

      {/* <p>
        <p style={{fontWeight: "bold"}}>Usuario</p>
        <p>Nombre del usuario: ____________________</p>
        <p>Identificación del usuario: ____________________</p>
      </p>
      <p>
        <p style={{fontWeight: "bold"}}>Mantis</p>
        <p>Jairo Andres Pitta Paredes</p>
        <p>CC. 1020809232</p>
        <p>Representante legal Mantis App SAS.</p>
      </p> */}
    </div>
  );
}
