const LOGIN = "mantis/auth/LOGIN";
const LOGOUT = "mantis/auth/LOGOUT";
const SET_USER = "mantis/auth/SET_USER";
const SET_IS_MENTOR = "mantis/auth/SET_IS_MENTOR";
const SET_IS_SHERPA = "mantis/auth/SET_IS_SHERPA";
const SET_IS_ADMIN = "mantis/auth/SET_IS_ADMIN";
const SET_USER_ID = "mantis/auth/SET_USER_ID";
const SET_TOKEN = "mantis/auth/SET_TOKEN";
const SET_IS_BUSINESS = "mantis/auth/SET_IS_BUSINESS";
const SET_MANTIS_BUSINESS = "mantis/auth/SET_MANTIS_BUSINESS";

const initialState = {
  authenticated: false,
  user: {},
  userId: "",
  isMentor: false,
  isSherpa: false,
  isAdmin: false,
  isBusiness: false,
  token: "",
  mantisBusiness: {},
};

export default function reducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        user: {},
        userId: "",
        isFoundation: false,
        isEnterprise: false,
        token: "",
        isBusiness: false,
        mantisBusiness: {},
      };
    case SET_USER:
      return {
        ...state,
        user: payload.user,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: payload.userId,
      };
    case SET_IS_MENTOR:
      return {
        ...state,
        isFoundation: payload.isFoundation,
      };
    case SET_IS_SHERPA:
      return {
        ...state,
        isEnterprise: payload.isEnterprise,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isEnterprise: payload.isEnterprise,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case SET_IS_BUSINESS:
      return {
        ...state,
        isBusiness: payload.isBusiness,
      };
    case SET_MANTIS_BUSINESS:
      return {
        ...state,
        mantisBusiness: payload.mantisBusiness,
      };
    default:
      return state;
  }
}

export function userLogin() {
  return { type: LOGIN };
}

export function userLogout() {
  return { type: LOGOUT };
}

export function setUser(payload) {
  return { type: SET_USER, payload };
}

export function setIsAdmin(payload) {
  return { type: SET_IS_ADMIN, payload };
}

export function setIsMentor(payload) {
  return { type: SET_IS_MENTOR, payload };
}

export function setIsSherpa(payload) {
  return { type: SET_IS_SHERPA, payload };
}

export function setUserId(payload) {
  return { type: SET_USER_ID, payload };
}

export function setToken(payload) {
  return { type: SET_TOKEN, payload };
}

export function setIsBusiness(payload) {
  return { type: SET_IS_BUSINESS, payload };
}

export function setMantisBusiness(payload) {
  return { type: SET_MANTIS_BUSINESS, payload };
}
