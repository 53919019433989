import React from "react";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import "./styles/dataTreatmentAuthorization.scss";

export default function DataTreatmentAuhorization() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div style={{width: isMobile ? "85%" : "50%", margin: "auto"}}>
      <img
        src={mantisLogo}
        alt="Mantis Logo"
        height={isMobile ? 40 : 70}
        style={{marginTop: 20}}
      />
      <br />
      <br />
      <h1 className="doc-title">
        AUTORIZACIÓN DE TRATAMIENTO DE DATOS MANTIS APP S.A.S.
      </h1>
      <p>
        Autorizo en mi calidad de Titular de la Información a MANTIS APP S.A.S
        (en adelante, “MANTIS APP S.A.S.”), sociedad comercial legalmente
        constituida, identificada con el NIT 9003041689, con domicilio principal
        en la ciudad de Bogotá D.C., Colombia, o quien haga sus veces, sea su
        aliado, represente sus derechos u ostente en el futuro su posición
        contractual, a tratar mis Datos Personales conforme a los lineamientos
        de la Ley 1581 de 2012, la Ley 1266 de 2012 y las demás normas que las
        complementen, modifiquen o deroguen, conforme a las finalidades que se
        indican a continuación.
      </p>
      <p>
        Conozco que la información capturada, recolectada y tratada por MANTIS
        APP S.A.S. tendrá -entre otras- las siguientes finalidades: (i)
        recopilar y almacenar datos personales en la base de datos de MANTIS APP
        S.A.S., (ii) ordenar, catalogar y clasificar la información
        proporcionada, (iii) utilizar los datos en campañas de comunicación y
        promoción de productos y servicios, (iv) mantener registros históricos y
        contacto con los titulares de los datos, (v) verificar y validar los
        datos suministrados, (vi) estudiar y analizar la información para
        mejorar productos y servicios, (vii) compartir datos con terceros que
        gestionen el almacenamiento, (viii) transferir datos a nivel nacional e
        internacional, (ix) comunicar datos a Empresas y aliados de finanzas
        alternativas y que ofrezcan productos de cualquier índole licita, (x)
        realizar control y prevención de fraude y lavado de activos, (xi)
        gestionar propuestas de negocios y contratos, (xii) reportar a centrales
        de riesgo incumplimientos financieros, (xiii) enviar comunicaciones
        relacionadas con el objeto de MANTIS APP S.A.S., (xiv) prestar servicios
        adecuados y de calidad, (xv) crear y administrar cuentas de usuarios,
        (xvi) mantener la relación comercial entre usuarios y MANTIS APP S.A.S.,
        (xvii) proporcionar información a usuarios sobre productos de oferentes,
        (xviii) realizar procesos operativos y de administración, (xix)
        personalizar el contenido y servicio, (xx) realizar estrategias de
        mercado y comerciales con terceros aliados, (xxi) elaborar prospecciones
        comerciales, (xxii) realizar encuestas de satisfacción y ofrecer
        beneficios, (xxiii) gestionar solicitudes, quejas y reclamos, (xxiv)
        presentar informes a autoridades de control, (xxv) gestionar aspectos
        contables, económicos y administrativos, (xxvi) conservar información
        según la Ley, (xxvii) transferir datos a terceros en cumplimiento del
        objeto social y objetivos comerciales, (xxviii) entregar la información
        del titular terceros que ofrezcan servicios que puedan ser de interés
        para el Usuario, (xxix) entregar la información del titular a aliados
        que ofrezcan servicios de financiación alternativa entendiéndose a estos
        como responsables del tratamiento, (xxx) conocer los comportamientos
        según las actuaciones en la aplicación; (xxi) transferir o transmitir la
        información plasmada en las comunicaciones recibidas por el titular por
        parte de Empresas aliadas; (xxii) analizar y estudiar la información
        plasmada en las comunicaciones recibidas por el titular por parte de
        Empresas aliadas; y (xxiii) las demás finalidades expuestas en la
        Política de Privacidad y Tratamiento de Datos Personales de MANTIS APP
        S.A.S.
      </p>
      <p>
        Adicionalmente, entiendo que el tratamiento al que se someten mis datos
        es el siguiente:
      </p>

      <ol type="a">
        <li>
          <p>En cuanto a las principales fuentes de captura:</p>
          <ul>
            <li>
              <p>
                A través de los contratos que se celebren con MANTIS APP S.A.S.
              </p>
            </li>
            <li>
              <p>A través del intercambio de correos electrónicos.</p>
            </li>
            <li>
              <p>A través portafolios de servicios.</p>
            </li>
            <li>
              <p>A través de reuniones o comunicaciones telefónicas.</p>
            </li>
            <li>
              <p>
                A través del acceso a páginas Web o aplicación de MANTIS APP
                S.A.S.
              </p>
            </li>
            <li>
              <p>Mediante la transmisión o transferencia de datos.</p>
            </li>
            <li>
              <p>
                A través de la conversación realizada por medios electrónicos.
              </p>
            </li>
            <li>
              <p>A través de formularios en físico.</p>
            </li>
            <li>
              <p>Mediante la solicitud para realizar transferencias.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>En cuanto al uso: Se dará conforme a las finalidades.</p>
        </li>
        <li>
          <p>
            En cuanto al almacenamiento: Se utilizarán servidores de
            almacenamiento propios y servidores en la nube. Para estos efectos
            la información estará protegida por credenciales de acceso de
            conocimiento restringido en la compañía.
          </p>
        </li>
        <li>
          <p>
            En cuanto a la supresión o eliminación: Se dará siempre y cuando el
            titular lo solicite o la finalidad termine. Sin embargo, esto podrá
            no acaecer si existe un mandato legal en la materia.
          </p>
        </li>
        <li>
          <p>
            Transferencia y transmisión: Se dará la transferencia y transmisión
            para efectos de cumplir con las finalidades. Cabe mencionar que la
            información de los tenderos y drivers podrán ser entregados a
            terceros que pretendan ofrecer servicios de financiación alternativa
            y/o mejora en la oferta de productos y beneficios.
          </p>
        </li>
      </ol>
      <p>
        Tengo claro que los derechos que me asisten como titular de mis datos
        personales son: (i) derecho de acceso a sus datos personales, (ii)
        derecho a conocer, actualizar y rectificar sus datos personales, (iii)
        derecho a solicitar prueba de la autorización otorgada, (iv) derecho a
        revocar la autorización y/o solicitar la eliminación de los datos, (v)
        derecho a presentar quejas ante la Superintendencia de Industria y
        Comercio, (vi) en general, todos los derechos establecidos en el
        artículo 8 de la Ley 1581 de 2012.
      </p>
      <p>
        MANTIS APP S.A.S. ha habilitado varios canales para permitirme el
        ejercicio de estos derechos:
      </p>
      <ol type="i">
        <li>
          <p>
            Canal presencial: Los usuarios pueden presentar personalmente sus
            solicitudes relacionadas con el tratamiento de sus datos personales
            en la oficina de MANTIS APP S.A.S., ubicada en la Cr 16A No. 137-29
            de Bogotá.
          </p>
        </li>
        <li>
          <p>
            Canal Telefónico: Los usuarios pueden comunicarse a través de la
            línea telefónica +57 3154308744.
          </p>
        </li>
        <li>
          <p>
            Canal Virtual: Se dispone el correo electrónico jairo@mantisapp.co
          </p>
        </li>
      </ol>
      <p>
        Igualmente, soy consciente que MANTIS APP S.A.S. podrá tratar, entre
        otros, los siguientes datos: Nombre y apellido; Nacionalidad; Teléfono
        celular; Correo electrónico; Empresa para la que laboro; Puesto o cargo
        que desempeño; Domicilio de trabajo; Correo electrónico institucional;
        Teléfono institucional; Información generada durante los procesos de
        reclutamiento, selección y contratación; Calidad migratoria; Datos de
        identificación; Datos de contacto; Datos laborales; Datos académicos;
        Advertising ID; DNI; Android ID; Android Versión; IMEI; BSSID; IP
        address; MAC address; Geolocalización en vivo; la información derivada
        de las comunicaciones entregadas por las Empresas Aliadas; la
        información derivada de las solicitudes del usuario en la aplicación;
        Position latitud; y Position longitud.
      </p>
      <p>
        De manera voluntaria, previa, explícita, y con un conocimiento informado
        y claro, siendo titular de los datos personales otorgo a MANTIS APP
        S.A.S. mi consentimiento inequívoco para el tratamiento de mis datos
        personales conforme a su Política de Tratamiento de Datos Personales y
        lo acá dispuesto. Este consentimiento se otorga específicamente para los
        propósitos relacionados con su misión, y particularmente para fines
        legales, contractuales y comerciales detallados en dicha política y en
        esta autorización. La Política de Tratamiento y Protección de Datos
        Personales está disponible para mi consulta en la página web de MANTIS
        APP S.A.S.
      </p>
      <p>
        Cabe mencionar que entiendo que la respuesta a las preguntas que me sean
        hechas, cuando estas versen sobre datos sensibles o sobre los datos de
        las niñas, niños y adolescentes, son de carácter facultativo. Sin
        embargo, conozco también que en caso de no entregar estos datos cuando
        sea necesarios para la prestación del servicio, no se podrá llevar a
        cabo la prestación de este.
      </p>
    </div>
  );
}
