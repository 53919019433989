import React from "react";
import { Card } from "react-bootstrap";

function BankCard(props) {
  const bank = props.bank;
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Img variant="top" src={bank.icon_logo} />
      <Card.Body>
        <Card.Title>{bank.display_name}</Card.Title>
      </Card.Body>
    </Card>
  );
}
export default BankCard;
