import React, {useState} from "react";
import {Button, Form, Col, Row} from "react-bootstrap";
import {signup} from "../ApiCalls/authentication";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  async function submitSignUp(event) {
    event.preventDefault();
    event.stopPropagation();
    const signupRes = await signup(email, password, firstName, lastName);
    console.log(signupRes);
  }

  return (
    <>
      <h1>Resgistrate!</h1>
      <Form
        noValidate
        onSubmit={(e) => {
          submitSignUp(e);
        }}
      >
        <Row style={{width: "50%", margin: "auto"}}>
          <Form.Group as={Col}>
            <Form.Label>Nombre(s)</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder="Nombre(s)"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Apellido(s)</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Apellido(s)"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row style={{width: "50%", margin: "auto"}}>
          <Form.Group as={Col}>
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Ingresa el Correo Electronico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row style={{width: "50%", margin: "auto"}}>
          <Form.Group as={Col}>
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Ingresa la Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button type="submit">Registrarse</Button>
      </Form>
    </>
  );
}

export default Register;
