import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { getUsers } from "../ApiCalls/dashboardMentor";
import { useNavigate } from "react-router-dom";
import { token } from "morgan";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

function DashboardMentor(props) {
  const [users, setUsers] = useState([]);
  const [shownUsers, setShownUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [showingTangeloUsers, setShowingTangeloUsers] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showingTangeloUsers) {
      let usersCopy = users.filter((user) => {
        return user.referredBy === "65b7beffc05c888d469bc434";
      });
      setShownUsers(usersCopy);
    } else {
      setShownUsers(users);
    }
  }, [showingTangeloUsers]);

  useEffect(() => {
    if (searchName) {
      let usersCopy = users.filter((user) => {
        return (
          user.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchName.toLowerCase())
        );
      });
      setShownUsers(usersCopy);
    } else {
      setShownUsers(users);
    }
  }, [searchName]);

  async function getData() {
    const usersRes = await getUsers(props.userId, props.token);

    if (usersRes.success) {
      setUsers(usersRes.users);
      setShownUsers(usersRes.users);
    }
    setLoading(false);
  }

  return (
    <>
      <h1 style={{ marginTop: 10 }}>Usuarios</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col xs={4} style={{ margin: "auto" }}>
              <FormGroup>
                <Input
                  type="text"
                  name="searchName"
                  id="searchName"
                  placeholder="Buscar por nombre"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={2} style={{ margin: "auto" }}>
              <FormGroup>
                <Label>
                  <Input
                    name="generateVoucher"
                    type="checkbox"
                    onChange={() =>
                      setShowingTangeloUsers(!showingTangeloUsers)
                    }
                  />{" "}
                  Tangelo
                </Label>
              </FormGroup>
            </Col>
          </Row>
          {shownUsers.length > 0 && (
            <Row>
              <Col xs={10} style={{ margin: "auto" }}>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="f-22">#</th>
                          <th>Nombre</th>
                          <th>Apellido</th>
                          <th>Correo</th>
                          <th>Documento</th>
                          {/* <th>Código de Referido</th> */}
                          <th>Teléfono</th>
                          <th>Día de cargue</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {shownUsers.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{user.firstName}</td>
                              <td>{user.lastName}</td>
                              <td>{user.email}</td>
                              <td>{user.identification || ""}</td>
                              {/* <td>{user.referralCode}</td> */}
                              <td>{user.cellPhone}</td>
                              <td>{user.chargeDay || "-"}</td>
                              <td
                                onClick={() =>
                                  navigate(`/dashboardMentor/user/${user._id}`)
                                }
                              >
                                <i
                                  className="fas fa-eye"
                                  style={{ fontSize: "1em", cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}

export default connect(select)(DashboardMentor);
