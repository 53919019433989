import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { token } from "morgan";
import {
  getRecurringPayment,
  updateInvestment,
  createNewPayment,
  getInvestment,
} from "../ApiCalls/backoffice";
import { uploadImage, uploadFile } from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

function InvestmentDetail(props) {
  const [investment, setInvestment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [updating, setUpdating] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (investment) {
      setFormState({
        currentBalance: investment.currentBalance,
        currentProfit: investment.currentProfit,
        inputCurrency: investment.inputCurrency,
        investmentCurrency: investment.investmentCurrency,
        currentInputBalance: investment.currentInputBalance,
        type: investment.type,
        yield: investment.yield,
        yieldVariance: investment.yieldVariance,
        yieldType: investment.yieldType,
        status: investment.status,
      });
    }
  }, [investment]);

  async function getData() {
    const investmentRes = await getInvestment(props.userId, props.token, id);

    if (investmentRes.success) {
      setInvestment(investmentRes.investment);
    }
    setLoading(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    setUpdating(true);
    event.preventDefault();
    const updatedObject = {
      ...formState,
    };
    const updateRes = await updateInvestment(
      props.userId,
      props.token,
      investment._id,
      updatedObject
    );
    if (updateRes.success) {
      setAlertMessage("Inversión actualizada correctamente");
    } else {
      setAlertMessage("Error al actualizar la inversión");
    }
    setUpdating(false);
  };

  return (
    <div>
      <h1>{formState.name || formState.type}</h1>
      <br />
      {loading ? (
        <Spinner />
      ) : investment ? (
        <Row>
          <Col xs={10} style={{ margin: "auto" }}>
            {" "}
            <Form onSubmit={handleSubmit} style={{ margin: "auto" }}>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="currentBalance">Balance actual:</Label>
                    <Input
                      type="text"
                      name="currentBalance"
                      id="currentBalance"
                      value={formState.currentBalance}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="currentProfit">Ganancia actual:</Label>
                    <Input
                      type="text"
                      name="currentProfit"
                      id="currentProfit"
                      value={formState.currentProfit}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="currentInputBalance">
                      Balance de entrada actual:
                    </Label>
                    <Input
                      type="text"
                      name="currentInputBalance"
                      id="currentInputBalance"
                      value={formState.currentInputBalance}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label for="status">Estado:</Label>
                    <Input
                      id="status"
                      name="status"
                      type="select"
                      value={formState.status}
                      onChange={handleInputChange}
                    >
                      <option value={"PENDING"}>Pendiente</option>
                      <option value={"ACTIVE"}>Activo</option>
                      <option value={"ENDED"}>Finalizado</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} style={{ margin: "auto" }}>
                  <Button color="primary" type="submit">
                    {updating ? <Spinner size={"sm"} /> : "Actualizar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <h1>Inversión no encontrada :c</h1>
      )}
      {alertMessage && (
        <Row>
          <Col xs={6} style={{ margin: "auto", marginTop: 50 }}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default connect(select)(InvestmentDetail);
