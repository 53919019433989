import React, {useState, useEffect} from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup
} from "reactstrap";
import {connect} from "react-redux";
import {searchUser} from "../../ApiCalls/backoffice";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function UserSearch(props) {
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (userSearchTerm.length === 0) {
      setSearchResults([]);
    } else {
      const delayDebounceFn = setTimeout(() => {
        search();
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [userSearchTerm]);

  const search = async () => {
    try {
      if (userSearchTerm.length > 0) {
        setSearching(true);

        const userId = props.userId;
        const results = await searchUser(props.token, userId, userSearchTerm);
        if (results.success) {
          setSearchResults(results.users);
          if (results.users.length === 0) {
            setNoResults(true);
          }
        } else {
          alert("Error", "Hubo un error al buscar, intenta de nuevo");
        }
        setSearching(false);
      } else {
        setSearchResults([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <InputGroup>
        <Input
          type="text"
          name="userSearchTerm"
          id="userSearchTerm"
          placeholder="Buscar usuario..."
          value={userSearchTerm}
          onChange={(e) => setUserSearchTerm(e.target.value)}
        />

        {searching ? (
          <Spinner
            color="primary"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              borderRadius: "50%"
            }}
          />
        ) : (
          <Input
            id="user"
            name="user"
            type="select"
            value={selectedUser?._id || ""}
            onChange={(e) => {
              setSelectedUser(
                searchResults.find((user) => user._id === e.target.value)
              );
              props.selectUser(
                searchResults.find((user) => user._id === e.target.value)
              );
            }}
          >
            <option value={null}>Selecciona un usuario...</option>
            {searchResults.map((user, index) => {
              return (
                <option key={index} value={user._id}>
                  {user.firstName} {user.lastName} ({user.email})
                </option>
              );
            })}
          </Input>
        )}
      </InputGroup>
    </>
  );
}

export default connect(select)(UserSearch);
