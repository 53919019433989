import React from "react";
import { Card } from "react-bootstrap";

function TransactionCard(props) {
  const transaction = props.transaction;
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title>{transaction.description}</Card.Title>
        <Card.Text>Tipo: {transaction.type}</Card.Text>
        <Card.Text>Fecha: {transaction.value_date}</Card.Text>
        <Card.Text>Monto: {transaction.amount}</Card.Text>
      </Card.Body>
    </Card>
  );
}
export default TransactionCard;
