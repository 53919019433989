import React from "react";
import { Card } from "react-bootstrap";

function AccountCard(props) {
  const account = props.account;
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title>{account.name}</Card.Title>
        <Card.Text>Numero de la cuenta: {account.number}</Card.Text>
        <Card.Text>Institucion: {account.institution.name}</Card.Text>
        <Card.Text>Balance disponible: {account.balance.available}</Card.Text>
        <Card.Text>Balance actual: {account.balance.current}</Card.Text>
        <Card.Text>Tipo de cuenta: {account.type}</Card.Text>
      </Card.Body>
    </Card>
  );
}
export default AccountCard;
