import React, {useState, useEffect} from "react";
import {getDashboardData} from "../ApiCalls/belvoDashboard";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  Alert
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {
  getLastFinancialState,
  getUser,
  getUserMantisBalance
} from "../ApiCalls/dashboardMentor";
import {useParams} from "react-router-dom";
import {updateUser} from "../ApiCalls/backoffice";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function DashboardMentorUser(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mantisBalance, setMantisBalance] = useState(null);
  const [moneyWaitingToPay, setMoneyWaitingToPay] = useState(0);
  const [formState, setFormState] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const {id} = useParams();

  useEffect(() => {
    getData();
    getBalance();
  }, []);

  async function getBalance() {
    const res = await getUserMantisBalance(id, props.token);
    console.log(res);
    if (res.success) {
      setMantisBalance(res.balance);
      setMoneyWaitingToPay(res.moneyWaitingToPay);
    }
  }

  async function getData() {
    const res = await getUser(props.userId, props.token, id);
    console.log(res);
    if (res.success) {
      setUser(res.user);
      setFormState(res.user);
    }
    setLoading(false);
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  async function editUser(event) {
    setUpdating(true);
    event.preventDefault();
    const res = await updateUser(props.token, id, formState, props.userId);
    if (res.success) {
      setAlertMessage("Usuario actualizado");
    }
    setUpdating(false);
  }

  return (
    <>
      <h1>
        {user ? `${user.firstName} ${user.lastName}` : "Cargando usuario"}
      </h1>
      <br />
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs={10} style={{margin: "auto"}}>
            {mantisBalance && (
              <Row>
                <Col xs={12}>
                  <h3>
                    Balance: $
                    {formatMoney(
                      mantisBalance.sign === "NEGATIVE"
                        ? -mantisBalance.balance + moneyWaitingToPay
                        : mantisBalance.balance + moneyWaitingToPay
                    )}
                  </h3>
                </Col>
              </Row>
            )}
            {user && (
              <Form onSubmit={editUser}>
                <Row>
                  <Col xs={3}>
                    <FormGroup>
                      <Label for="amount">Nombre(s):</Label>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formState.firstName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label for="name">Apellido(s):</Label>
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formState.lastName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={3}>
                    <FormGroup>
                      <Label for="type">Celular:</Label>
                      <Input
                        type="text"
                        name="cellPhone"
                        id="cellPhone"
                        value={formState.cellPhone}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label for="type">Correo electrónico:</Label>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        value={formState.email}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label for="status">Tipo de documento:</Label>
                      <Input
                        id="identificationType"
                        name="identificationType"
                        type="select"
                        value={formState.identificationType}
                        onChange={handleInputChange}
                      >
                        <option value={"CC"}>CC</option>
                        <option value={"NIT"}>NIT</option>
                        <option value={"CE"}>CE</option>
                        <option value={"LIC"}>LIC</option>
                        <option value={"PPN"}>PPN</option>
                        <option value={"DNI"}>DNI</option>
                        <option value={"SSN"}>SSN</option>
                        <option value={"Otro"}>Otro</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label for="type">Identificación:</Label>
                      <Input
                        type="text"
                        name="identification"
                        id="identification"
                        value={formState.identification}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} style={{margin: "auto"}}>
                    <Button color="primary" type="submit">
                      {updating ? <Spinner size={"sm"} /> : "Actualizar"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
          {alertMessage && (
            <Row>
              <Col xs={6} style={{margin: "auto", marginTop: 50}}>
                <Alert
                  color="info"
                  isOpen={alertMessage !== ""}
                  toggle={() => {
                    setAlertMessage("");
                  }}
                >
                  {alertMessage || ""}
                </Alert>
              </Col>
            </Row>
          )}
        </Row>
      )}
    </>
  );
}

export default connect(select)(DashboardMentorUser);
