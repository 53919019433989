import React, {useEffect, useState} from "react";
import {Row, Col, FormGroup, Label, Input, Button} from "reactstrap";

import {connect} from "react-redux";
import {sendPushNotification} from "../../ApiCalls/pushNotifications";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function PushNotifications(props) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [allUsers, setAllUsers] = useState(false);
  const [tangeloUsers, setTangeloUsers] = useState(false);
  const [identification, setIdentification] = useState("");

  const handleSendPushNotification = async () => {
    const res = await sendPushNotification(
      props.userId,
      props.token,
      title,
      body,
      allUsers,
      tangeloUsers,
      identification
    );
    if (res.success) {
      alert("Mensaje enviado");
    } else {
      alert("Error enviando mensaje");
    }
  };

  return (
    <div
      style={{
        width: "80%",
        margin: "auto"
      }}
    >
      <h1>Push</h1>
      <Row
        style={{
          marginTop: "50px"
        }}
      >
        <Col xs={2}>
          <h2>Enviar a:</h2>
          <FormGroup>
            <Label>Identificación:</Label>
            <Input
              type="text"
              name="cellphone"
              id="cellphone"
              value={identification}
              onChange={(e) => setIdentification(e.target.value)}
              placeholder="ID"
            />
          </FormGroup>
          <Label>o</Label>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="allUsers"
                id="allUsers"
                checked={allUsers}
                onChange={(e) => {
                  setAllUsers(e.target.checked);
                  if (e.target.checked) {
                    setTangeloUsers(false);
                  }
                }}
              />
              Todos los usuarios
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="tangeloUsers"
                id="tangeloUsers"
                checked={tangeloUsers}
                onChange={(e) => {
                  setTangeloUsers(e.target.checked);
                  if (e.target.checked) {
                    setAllUsers(false);
                  }
                }}
              />
              Usuarios Tangelo
            </Label>
          </FormGroup>
        </Col>
        <Col xs={2}>
          <h2>Parámetros</h2>
          <p style={{textAlign: "center"}}>Nombre {"-> {{firstName}}"}</p>
          <p style={{textAlign: "center"}}>Apellido {"-> {{lastName}}"}</p>
        </Col>
        <Col xs={8}>
          <FormGroup>
            <Label>Título:</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Título"
            />
          </FormGroup>
          <FormGroup>
            <Label>Cuerpo:</Label>
            <Input
              type="text"
              name="body"
              id="body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Cuerpo"
            />
          </FormGroup>
          <Button
            onClick={() => {
              if (
                title &&
                body &&
                (identification !== "" || allUsers || tangeloUsers)
              ) {
                handleSendPushNotification();
              }
            }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(PushNotifications);
