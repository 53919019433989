import React from "react";
import SidebarMenuComponent from "./SidebarMenu";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./styles/backoffice.scss";

function BackofficeHome() {
  return <div className="home-component"></div>;
}

export default BackofficeHome;
